import { WEBSOCKET_CLOSE_USER, WEBSOCKET_FAIL, WEBSOCKET_OPEN_USER } from './Websocket.constants'

export const websocketFail = (error) => ({
    type: WEBSOCKET_FAIL,
    payload: error
})

export const websocketOpenUser = (user) => ({
    type: WEBSOCKET_OPEN_USER,
    payload: user
})

export const websocketCloseUser = (close) => ({
    type: WEBSOCKET_CLOSE_USER,
    payload: close
})
import engine from 'store/src/store-engine'

// Storage engine we need
import localStorage from 'store/storages/localStorage'

// Plugins
import defaultPlugin from 'store/plugins/defaults'
import expirePlugin from 'store/plugins/expire'

// // Create out cache store
const store = engine.createStore([localStorage], [defaultPlugin, expirePlugin])

export default store
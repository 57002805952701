import {
  RAPID_BETS_UPDATE_BET,
  RAPID_BETS_REMOVE_BET,
  RAPID_BETS_SYNC_NUMBERS,
  RAPID_BETS_UPDATE_MODAL_SELECTION,
  RAPID_BETS_UPDATE_MODAL_BONUS_SELECTION,
  RAPID_BETS_CLEAR_ALL,
} from './RapidBets.contants'

// Load helpers
import { generateRandomNumbers } from '../../helper/rng'
import { getBonusComponents } from '../../helper/lotteries'

const initialState = {
  bets: [],
  // Balls picked from modal
  modalSelections: [],
  // Existing selections loaded
  selections: [],
  // Existing bonus selections
  bonusSelections: [],
  // Bonus selections picked from modal
  modalBonusSelections: {
    bonus_1: [],
    bonus_2: [],
  },
  // Reference to ball and line cliked
  selectedBall: {},
  // Game component rules
  gameOptions: {},
  // Lottery game rules
  rules: {},

  unitValue: 500,
  value: 0,
  // bool to indicate if modal open
  isOpen: false,
  gameId: null,
}

function addBet(state, bets) {
  let activeSelections = []

  //   const bet = bets.find((bet) => bet.gameId === state.gameOptions.gameId) || {}

  //   // Cover all pale bets and used to show c
  //   if (bet.components) {
  //     bet.components.forEach((component) => {
  //       if (component.id === state.gameOptions.id) {
  //         activeSelections.push(component)
  //       }
  //     })
  //   }

  return {
    ...state,
    bets,
    selections: activeSelections,
  }
}

const removeBet = (state, { id, index }) => {
  const filteredBets = state.bets.bets.bets.filter((bet) => bet.id !== id || bet.betIndex !== index)

  return {
    ...state,
    bets: { bets: { bets: filteredBets } },
    selections: [],
  }
}

function syncBet(state, { gameId, lotteryById, activeGameComponentId, game }) {
  const copyBets = [...state.bets]

  const findBetIndex = copyBets.findIndex((bet) => bet.gameId === gameId)

  if (findBetIndex === -1) {
    return state
  }

  //const bonusRules = rules
  const bet = { ...copyBets[findBetIndex] }

  // Build the bonus rules
  let bonusRules = []
  let activeGameComponent =
    game.gameComponents.find((component) => component.id === activeGameComponentId) ||
    game.gameComponents[0]
  bonusRules = getBonusComponents({ activeGameComponent, lotteryById, game })

  function validateNumbers(numbers) {
    let copyNumbers = [...numbers]
    const numbersRequired = bonusRules.length + state.rules.minLength - copyNumbers.length

    if (numbersRequired > 0 && bonusRules.length > 0) {
      for (let i = 0; i < numbersRequired; ++i) {
        const rules = bonusRules[i]
        const bonusIndex = numbersRequired === 1 && bonusRules.length === 2 ? 2 : i + 1

        copyNumbers.push({
          number: generateRandomNumbers(rules.minBonusNumber, rules.maxBonusNumber, 1)[0],
          isBonus: true,
          isSelected: true,
          bonusIndex,
        })
      }
    }

    // Set all bonus numbers to not selected
    if (numbersRequired === -2 || bonusRules.length === 0) {
      copyNumbers = copyNumbers.map((number) => {
        if (number.isBonus) {
          return {
            ...number,
            isSelected: false,
          }
        }
        return number
      })
    }

    // Remove all but first bonus ball
    if (numbersRequired === -1) {
      copyNumbers = copyNumbers.map((number) => {
        // Set second bonus to unselected
        if (number.bonusIndex === 2) {
          return {
            ...number,
            isSelected: false,
          }
        }

        // Make sure first bonus is selected again
        if (number.bonusIndex === 1) {
          return {
            ...number,
            isSelected: true,
          }
        }
        return number
      })
    }

    // Make sure to set bonus numbers back to true
    if (numbersRequired === 0 && bonusRules.length === 2) {
      copyNumbers = copyNumbers.map((number) => {
        if (number.isBonus) {
          return {
            ...number,
            isSelected: true,
          }
        }
        return number
      })
    }

    return copyNumbers
  }

  const updateComponents = bet.components.map((component) => {
    return {
      ...component,
      numbers: validateNumbers(Object.values(component.numbers)),
    }
  })

  bet.components = updateComponents
  copyBets[findBetIndex] = bet

  return {
    ...state,
    bets: copyBets,
    selections: updateComponents,
  }
}

const rapidBets = (state = initialState, action = {}) => {
  switch (action.type) {
    case RAPID_BETS_UPDATE_BET:
      return addBet(state, action.payload)
    case RAPID_BETS_REMOVE_BET:
      return removeBet(state, action.payload)
    case RAPID_BETS_SYNC_NUMBERS:
      return syncBet(state, action.payload)
    case RAPID_BETS_UPDATE_MODAL_SELECTION:
      return {
        ...state,
        modalSelections: action.payload,
      }
    case RAPID_BETS_UPDATE_MODAL_BONUS_SELECTION: {
      return {
        ...state,
        modalBonusSelections: {
          ...state.modalBonusSelections,
          [action.payload.key]: action.payload.ball,
        },
      }
    }
    case RAPID_BETS_CLEAR_ALL:
      return initialState
    default:
      return state
  }
}

export default rapidBets

import { theme } from "@chakra-ui/react"

/**
 * Defaut sizes for
 * typograpy
 * borderRadius
 *
 * This can be overriden by partner theme do not change here
 */
export const fontSizes = {
  ...theme.fontSizes,
  xxs: "10px",
  xs: "12px",
  sm: "14px",
  md: "16px",
  lg: "18px",
  xl: "26px",
  "2xl": "38px",
}

export const radii = {
  ...theme.radii,
  tableBorderRadius: theme.radii["3xl"],
  buttonRadii: theme.radii["3xl"],
  inputRadii: theme.radii["3xl"],
  cardRadii: theme.radii["2xl"],
  playCardRadii: theme.radii["xl"],
}

import React from 'react'
import { NavLink } from 'react-router-dom'

// Chakra elements
import { Button, Box } from '@chakra-ui/react'
import Text from '../UI/Text/Text'
import Icon from 'component/UI/Icon/Icon'


const RouteError = () => {
    return (
        <Box maxWidth="400px" marginX="auto" layerStyle="box" marginY={8}>
            <Box
                paddingX={6}
                paddingY={4}
                alignItems="center"
                backgroundColor="box.title.background"
            >
                <Box display="flex" alignItems="center">
                    <Box as="span" color="red.600">
                        <Icon icon="warning" />
                    </Box>{' '}
                    <Text
                        marginLeft={2}
                        tag="sentence.404NotFound"
                        text="404 - Page Not Found"
                        as="h4"
                        textStyle="h4"
                    />
                </Box>
            </Box>
            <Box borderTop="1px" borderColor="box.border" padding={6}>
                <Text marginBottom={4} tag="sentence.weCouldNotFindPage" text="We could not find the page you were looking for." />
                <NavLink to="/play">
                    <Button colorScheme="buttonSecondary" width="100%" size="md">
                        <Text tag="link.playLotteries" color="inherit" text="Play Lotteries" />
                    </Button>
                </NavLink>
            </Box>
        </Box>
    )
}

export default RouteError

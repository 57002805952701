import { connect } from "react-redux"
import * as yup from "yup"

import { Input, FormControl, FormLabel, FormErrorMessage, Button, Stack, Box } from "@chakra-ui/react"
import { Formik, Form, Field } from "formik"

//Load hooks
import { usePlayerMutate } from "../../../hooks/queries/usePlayer"
import { useState } from "react"
import InlineError from "../../Errors/InlineError/InlineError"
import { useLanguageContext } from "component/Translations/Translations"

const schema = yup.object().shape({
  password: yup.string().required({ code: "ERR22", error: "Password required" }),
  newPassword: yup
    .string()
    .matches(/^.*(?=.{8,})((?=.*[!?@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/, {code: "global.paragraph.passwordRequirements", error: "Password must contain at least 8 characters, one uppercase, one number and one special case character"})
    .required({ code: "ERR22", error: "Password required" }),
})

const UpdatePassword = ({ closeModal, handleSuccessNotification }) => {
  const mutatePlayer = usePlayerMutate()
  const [error, setError] = useState(null)
  const { getText } = useLanguageContext()

  return (
    <Box p={1}>
      <InlineError error={error} />
      <Formik
        enableReinitialize
        initialValues={{
          password: "",
          newPassword: "",
          reason: "player changed",
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          return mutatePlayer.mutate(
            { values, type: "password" },
            {
              onSuccess: async () => {
                handleSuccessNotification({ message: getText('account.notification.passwordUpdated', 'Password Updated')})

                return closeModal()
              },
              onError: async (error) => {
                return setError(error)
              },
              onSettled: () => {
                actions.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(props) => {
          return (
            <Form>
              <Stack spacing={2}>
                <Field name="password" type="password">
                  {({ field, form }) => {
                    const code = form.errors.password?.code
                    const error = form.errors.password?.error
                    return (
                      <FormControl id="password" isInvalid={form.errors.password && form.touched.password}>
                        <FormLabel>{getText("account.formLabel.currentPassword", "Current Password")}</FormLabel>
                        <Input {...field} type="password" name="password" id="password" placeholder={getText(`account.placeholder.enterPassword`, "Enter password")} />
                        <FormErrorMessage>{getText(`Error.${code}`, error)}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>

                <Field name="newPassword" type="newPassword">
                  {({ field, form }) => {
                    const code = form.errors.newPassword?.code
                    const error = form.errors.newPassword?.error
                   
                    return (
                      <FormControl id="password" isInvalid={form.errors.newPassword && form.touched.newPassword}>
                        <FormLabel>{getText("account.formLabel.newPassword", "New Password")}</FormLabel>
                        <Input {...field} type="password" name="newPassword" id="newPassword" placeholder={getText(`account.placeholder.newPassword`, "Enter new password")} />
                        <FormErrorMessage>{typeof code === 'undefined' ? getText("global.paragraph.passwordRequirements", "Password must contain at least 8 characters, one uppercase, one number and one special case character") : getText(`Error.${code}`, error)}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>

                <Box layerStyle="modalActionButtonPlacement">
                  <Button id="updatePassword" size="lg" borderRadius="0" layerStyle="modalActionButton" type="submit" isLoading={props.isSubmitting}>
                    {getText(`account.button.updatePassword`, `Update Password`)}
                  </Button>
                </Box>
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

export default connect(mapStateToProps)(UpdatePassword)

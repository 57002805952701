import useAxios from "../useAxios"
import { useQuery, useMutation, useQueryClient } from "react-query"

const exclusionKeys = {
  all: [{ scope: "exclusion" }],
  details: () => [{ ...exclusionKeys.all[0], entity: "details" }],
}

const fetchExclusion = async ({ axios }) => {
  const response = await axios.get(`player/me/exclusion`)
  return response.data
}

const useExclusionQuery = () => {
  const axios = useAxios()

  return useQuery(exclusionKeys.details(), () => fetchExclusion({ axios }))
}

const mutateExclusion = async ({ axios, method, values }) => {
  const body = {
    ...values,
    customerServiceLocked: false,
    reason: "Player Requested",
  }

  const response = await axios[method](`player/me/exclusion`, body)
  return response.data
}

const useExclusionMutation = () => {
  const axios = useAxios()
  const queryClient = useQueryClient()

  return useMutation(({ values, method }) => mutateExclusion({ axios, values, method }), {
    onSuccess: () => {
      queryClient.invalidateQueries(exclusionKeys.all[0])
    },
  })
}

export { exclusionKeys, useExclusionQuery, useExclusionMutation }

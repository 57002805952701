import { all, call, throttle, put, select, takeLeading } from "redux-saga/effects"

// Custom helpers
import { axiosInstance as axios } from "../../helper/axios"
import { waitFor } from "../../helper/saga"

// Load selectors
import * as selectors from "../selectors.saga"

// Redux user flow
import { requestLogout, requestUserFail, requestUserSuccess, setBalanceValue } from "./User.actions"
import { REQUEST_USER_START, USER_BALANCE_UPDATE_START } from "./User.constants"

// Token validation
import { validateTokenSaga } from "../Token/Token.saga"

export function* userAsync() {
  try {
    // Validate our JWT token
    yield call(validateTokenSaga)

    // Make sure we have a valid token
    yield call(waitFor, selectors.token)

    // Store the latest refresh token from store
    const token = yield select(selectors.token)

    const client = yield axios.get("player/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const response = yield client.data

    // Handle errors
    if (response.error) {
      throw Error(response.error)
    }

    yield put(requestUserSuccess(response))
  } catch (error) {
    // We have a standard error to display
    yield put(requestUserFail(error))

    // Reset login state to start process agin
    yield put(requestLogout())
  }
}

export function* requestBalanceAsync() {
  try {
    // Validate our JWT token
    yield call(validateTokenSaga)

    // Make sure we have a valid token
    yield call(waitFor, selectors.token)

    // Store the latest refresh token from store
    const token = yield select(selectors.token)

    const client = yield axios.get("player/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    const response = yield client.data

    if (response.error) {
      throw Error(response.error)
    }

    yield put(setBalanceValue({ balance: response.userWallet.amount, winBalance: response.userWallet.protectedAmount }))
  } catch (error) {
    // We have a standard error to display
    yield put(requestUserFail(error))

    // Reset login state to start process agin
    yield put(requestLogout())
  }
}

// Listner
export function* requestUserStart() {
  yield takeLeading(REQUEST_USER_START, userAsync)
}

// Debounce our balance call
export function* requestBalanceUpdate() {
  yield throttle(2000, USER_BALANCE_UPDATE_START, requestBalanceAsync)
}

// Export our sagas
export function* userSaga() {
  yield all([call(requestUserStart), call(requestBalanceUpdate)])
}

import React from 'react'

//Load components
import { Box } from '@chakra-ui/react'
import MainSubMenu from './MainSubMenu'
import MobileSubMenu from './MobileSubMenu'

export const AccountSubMenu = () => {
    return (
        <>
            <Box display={{ base: 'block', lg: 'none' }}>
                <MobileSubMenu />
            </Box>
            <Box display={{ base: 'none', lg: 'block' }} width="100%">
                <MainSubMenu />
            </Box>
        </>
    )
}

export default AccountSubMenu

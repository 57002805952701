
import useAxios from '../useAxios'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { witnessId } from 'helper/envs'
import { useDispatch } from 'react-redux'
import { requestUserStart } from 'redux/User/User.actions'

const kycKeys = {
  all: [{ scope: 'kyc' }],
  details: () => [{ ...kycKeys.all[0], entity: 'details' }],
  documents: ({ claimId }) => [
    { ...kycKeys.all[0], entity: 'documentList', claimId }
  ],
}

const fetchPlayerKyc = async ({ axios }) => {
  const response = await axios.get(`kyc/me`)
  return response.data
}

// post doc upload
const postKycUpload = async ({ axios, claimId, body }) => {

  const response = await axios.post(`kyc/me/${claimId}/upload`, body, {
    headers: { "Content-Type": "multipart/form-data" }
  })
  return response.data
}

const usePlayerKycQuery = () => {
  const axios = useAxios()

  return useQuery(kycKeys.details(), () => fetchPlayerKyc({ axios }))
}

const postKycClaim = async ({ axios }) => {
  const body = {
    witnessId,
    type: "document"
  }
  const response = await axios.post("kyc/me", body)
  return response.data
}

const useKycClaimMutate = () => {
  const axios = useAxios()
  const queryClient = useQueryClient()

  return useMutation(() => postKycClaim({ axios }), {
    onSuccess: () => {
      queryClient.invalidateQueries(kycKeys.details())
    },
  })
}

// Upload doc 
const useKycUploadMutation = () => {
  const axios = useAxios()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    ({ values, claimId }) => postKycUpload({ axios, body: values, claimId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          kycKeys.details()
        )
        dispatch(requestUserStart())
      }
    }
  )
}



export { kycKeys, usePlayerKycQuery, useKycClaimMutate, useKycUploadMutation }

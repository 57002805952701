import React from 'react'
import { Text } from '@chakra-ui/react'
// import { useSelector } from 'react-redux'

// Load fe library
import { useLanguageContext } from 'component/Translations/Translations'

// Load components
import Helmet from '../../component/Helmet/Helmet'
import { usePlayerKycQuery } from '../../hooks/queries/useKYC'
import ManualVerification from '../../component/Verification/ManualVerification'
import ConfirmationMessage from '../../component/Verification/ConfirmationMessage'
// import AutoVerification from 'component/Verification/AutoVerification'
import RejectedMessage from 'component/Verification/RejectedMessage'

const Verification = () => {
    const { getText } = useLanguageContext()
    const { data, isLoading } = usePlayerKycQuery()

    // Using player status to grab kyc stage
    // const status = useSelector((state) => state.user.kycStatus)
    const playerKYC = data?.[0]?.status

    /**
     * @todo - Add Loading component
     */
    if (isLoading) {
        return <Text>Loading...</Text>
    }

    // Player has to trigger KYC event again? This needs to be reviewed - Nath.G
    // if (status === 'not_verified') {
    //     return (
    //         <>
    //             <AutoVerification />
    //         </>
    //     )
    // }

    // KYC being reviewed BY CS
    if (playerKYC === 'waiting_for_review') {
        return (
            <>
                <ConfirmationMessage />
            </>
        )
    }

    // KYC being reviewed BY CS
    if (playerKYC === 'rejected') {
        return (
            <>
                <RejectedMessage />
            </>
        )
    }

    // Player needs to upload documents
    return (
        <>
            <Helmet>
                <title>{getText('account.title.ageVerification', 'ID/Age Verification')}</title>
            </Helmet>
            <ManualVerification data={data} />
        </>
    )
}

export default Verification

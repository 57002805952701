import { theme } from "@chakra-ui/react"

export const radii = {
  ...theme.radii,
  tableBorderRadius: theme.radii["xl"],
  buttonRadii: theme.radii["3xl"],
  inputRadii: theme.radii["md"],
  cardRadii: theme.radii["md"],
  playCardRadii: theme.radii["lg"],
}

export const formatPrice = (value, currencyIso = 'DOP') => {
    let locale = 'en-US',
        extraParams = {},
        currency = currencyIso

    // Handle variations of the currency
    if (currencyIso === 'DOP' || currencyIso === 'DO') {
        locale = 'es-DO'
        currency = 'DOP'
        extraParams = {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0
        }
    }

    return new Intl.NumberFormat(locale, {
        ...extraParams,
        style: 'currency',
        //currencyDisplay: 'narrowSymbol',
        currency
    }).format(value || 0)
}

export const convertToPence = (amount) => {
    if (!amount) {
        return 0
    }

    return amount * 100
}

export const convertFromPence = (amount) => {
    if (!amount) {
        return 0
    }

    return amount / 100
}

/**
 * Divide by 100 to convert from pence
 * Return the amount formatted to currency standard
 * @param {Number} value
 * @param {String} currencyIso
 * @returns {String} $50.00
 */
export const formatCostFromPence = ({ value = 0, currencyIso = 'DOP' } = {}) => {
    return formatPrice(convertFromPence(value), currencyIso)
}

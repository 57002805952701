import React from 'react'
import { Box } from '@chakra-ui/react'

// Loading states for all Skeleton components
import { SkeletonTheme } from 'react-loading-skeleton'

const LayoutBase = ({ maxWidth = null, children, ...props }) => {
    return (
        <SkeletonTheme color="#CBD9EB" highlightColor="#d5e0ef">
            <Box
                height="100%"
                width="100%"
                maxWidth={maxWidth ? maxWidth : 'calc(1200px + 10rem)'}
                marginY={{ base: 8, lg: 12 }}
                marginX="auto"
                paddingX={{ base: 6, md: 10, lg: 20 }}
                {...props}
            >
                {children}
            </Box>
        </SkeletonTheme>
    )
}

export const LayoutContain = ({ paddingX = null, children, ...otherProps }) => {
    return (
        <SkeletonTheme color="#CBD9EB" highlightColor="#d5e0ef">
            <Box
                marginX="auto"
                maxWidth={'calc(1200px + 10rem)'}
                paddingX={paddingX ? paddingX : { base: 6, md: 10, lg: 20 }}
                {...otherProps}
            >
                {children}
            </Box>
        </SkeletonTheme>
    )
}

export default LayoutBase

import { REQUEST_CREATEBET_START, REQUEST_CREATEBET_SUCCESS, REQUEST_CREATEBET_FAIL, CLEAR_BET_ERRORS, CHECKOUT_ON_BET_REJECTED, CHECKOUT_ON_BET_SUCCESS, CHECKOUT_CLOSE_MODAL } from "./Checkout.constants.js"

const initialStateCheckout = {
  /**
   * Bet Accepted on API store bet item refernce until confirmed from WS
   */
  createBetSuccess: {},
  /**
   * Active Lottery on checkout
   */
  activeLottery: {},
  /**
   * Toggle modal checkout display
   */
  checkoutShow: false,
  /**
   * API error on checkout
   */
  createBetError: false,
  /**
   * Pending API
   */
  isPending: false,
  /**
   * WS response to confirm items placed from API
   */
  onBetSuccess: [],
  /**
   * WS reponse to confirm items rejected after placing on API
   */
  onBetRejected: [],
}

const createBetStart = (state) => {
  return {
    ...state,
    isPending: true,
    createBetSuccess: {},
    createBetError: false,
    onBetRejected: [],
    onBetSuccess: [],
  }
}

const createBetSuccess = (state, action) => {
  return {
    ...state,
    isPending: false,
    checkoutShow: true,
    createBetSuccess: action.payload,
  }
}

const createBetFail = (state, action) => {
  return {
    ...state,
    isPending: false,
    createBetError: action.payload,
  }
}

const clearBets = () => initialStateCheckout

/**
 * For success response add the value to key
 * Replace for each incoming new value
 *
 * SuccessModal will update as props change and update the tally
 * We can check for complete when onBetsuccess.length === Lines.lenght
 */
const onBetSuccess = (state, data) => {
  const tally = [...state.onBetSuccess]
  const createBetSuccess = state.createBetSuccess

  // Check we match the bet ticket payload with the incoming ticketLineId so we can create our tally
  if (createBetSuccess.ticketLineIdsProcessing && createBetSuccess.ticketLineIdsProcessing.includes(data.ticketLineId)) {
    tally.push(data.ticketLineId)
  }

  return {
    ...state,
    onBetSuccess: tally,
  }
}

/**
 * We currently can only display the number of errors and the error message
 * we can't link it to the line/ticket places as we don't have a way to match up against the payload
 */
const onBetRejected = (state, data) => {
  const rejectTally = [...state.onBetRejected]
  const createBetSuccess = state.createBetSuccess

  // Check we match the bet ticket payload with the incoming ticketLineId so we can create our tally
  if (createBetSuccess.ticketLineIdsProcessing && createBetSuccess.ticketLineIdsProcessing.includes(data.ticketLineId)) {
    rejectTally.push(data.error)
  }

  return {
    ...state,
    onBetRejected: rejectTally,
  }
}

const checkout = (state = initialStateCheckout, action = {}) => {
  switch (action.type) {
    case CHECKOUT_CLOSE_MODAL:
    case CLEAR_BET_ERRORS:
      return clearBets()
    case REQUEST_CREATEBET_START:
      return createBetStart(state)
    case REQUEST_CREATEBET_SUCCESS:
      return createBetSuccess(state, action)
    case REQUEST_CREATEBET_FAIL:
      return createBetFail(state, action)
    case CHECKOUT_ON_BET_SUCCESS:
      return onBetSuccess(state, action.payload)
    case CHECKOUT_ON_BET_REJECTED:
      return onBetRejected(state, action.payload)
    default:
      return state
  }
}

export default checkout

import React, { useEffect, useRef } from 'react'
import { NavLink as ReachLink, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// Chakra components
import {
    Box,
    Image,
    List,
    ListItem,
    GridItem,
    Grid,
    LinkOverlay,
    LinkBox,
    FormControl,
    Input,
    InputGroup,
    FormErrorMessage,
    Button,
    InputLeftAddon
} from '@chakra-ui/react'
import { LayoutContain } from '../../template/LayoutBase.page'
import { Formik, Field, Form } from 'formik'

// Import Components
import Text from '../UI/Text/Text'
import SideDrawer from 'component/Navigation/SideDrawer/SideDrawer'
import MainNavigation from 'component/Navigation/Main/Main'
import UserButton from 'component/UI/Buttons/UserButton/UserButton'
import Icon from 'component/UI/Icon/Icon'
import { useLanguageContext } from 'component/Translations/Translations'
import { useLogin } from 'hooks/useLogin'
import { Turnstile } from '@marsidev/react-turnstile'

// Get the right logo
import logo from '../../component/UI/Logo/Logo'
import { userLogin } from 'redux/User/User.actions'
import { loginSchema } from 'helper/schemas'
import { setFormWarnings } from 'helper/form'
//import { parseError } from 'helper/error'

const initialStatus = {
    email: {
        error: false,
        code: ''
    },
    password: {
        error: false,
        code: ''
    }
}

// /**
//  * Login Form
//  */
const Login = ({ isAuthed }) => {
    const location = useLocation()
    const { getText } = useLanguageContext()
    const dispatch = useDispatch()
    const language = useSelector((state) => state.user.language)
    //const [token, setToken] = useState('')
    //const [challengeError, setChallengeError] = useState('')
    const turnstileRef = useRef(null)
    //const turnstile = useTurnstile()

    /**
     * submission handler
     * data: token / user
     * hasError: api errors
     */
    const { hasError, data, onSubmitLogin } = useLogin('web')

    // Sync our store with user data
    useEffect(() => {
        // Check we have token
        if (data.token) {
            // Dispatch userLogin action
            dispatch(userLogin(data))
        }
    }, [data, dispatch])

    if (isAuthed) {
        return null
    }

    if (location.pathname === '/login') {
        return null
    }

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            initialStatus={initialStatus}
            validationSchema={loginSchema}
            onSubmit={async (values, actions) => {
                turnstileRef.current.render()
                turnstileRef.current.reset()
                turnstileRef.current.execute()
                const token = await turnstileRef.current.getResponsePromise()
                return onSubmitLogin({ ...values, token })
                    .then(() => {
                        if (hasError.warnings) {
                            actions.setStatus(setFormWarnings(initialStatus, hasError.warnings))
                        }
                        actions.setSubmitting(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    }).finally(() => {
                        turnstileRef.current.remove()
                    })
            }}
        >
            {(props) => {
                return (
                    <Form name="login">
                        <Box
                            display="grid"
                            gridTemplateColumns="repeat(2, minMax(100px, 175px)) max-content"
                            columnGap={2}
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Field name="email">
                                {({ form, field }) => {
                                    const code = form.errors.email?.code
                                    const error = form.errors.email?.error
                                    return (
                                        <FormControl
                                            id="email"
                                            isInvalid={form.errors.email && form.touched.email}
                                        >
                                            <InputGroup variant="solid" size="xs">
                                                <InputLeftAddon
                                                    backgroundColor="iconPrimary"
                                                    color="white"
                                                >
                                                    <Icon icon="user" />
                                                </InputLeftAddon>
                                                <Input
                                                    {...field}
                                                    type="email"
                                                    placeholder={getText(
                                                        'global.placeholder.emailAddress',
                                                        'Email Address'
                                                    )}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage color="red.600">
                                                {form.status.email.error
                                                    ? getText(
                                                        `Error.${form.status.email.code}`,
                                                        form.status.email.error
                                                    )
                                                    : getText(`Error.${code}`, error)}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )
                                }}
                            </Field>
                            <Field name="password">
                                {({ form, field }) => {
                                    const code = form.errors.password?.code
                                    const error = form.errors.password?.error
                                    return (
                                        <FormControl
                                            id="password"
                                            isInvalid={
                                                form.errors.password && form.touched.password
                                            }
                                        >
                                            <InputGroup variant="solid" size="xs">
                                                <InputLeftAddon
                                                    backgroundColor="iconPrimary"
                                                    color="white"
                                                >
                                                    <Icon icon="lock" />
                                                </InputLeftAddon>
                                                <Input
                                                    {...field}
                                                    type="password"
                                                    placeholder={getText(
                                                        'global.placeholder.password',
                                                        'Password'
                                                    )}
                                                />
                                            </InputGroup>
                                            <FormErrorMessage color="red.600">
                                                {form.status.password.error
                                                    ? getText(
                                                        `Error.${form.status.password.code}`,
                                                        form.status.password.error
                                                    )
                                                    : getText(`Error.${code}`, error)}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )
                                }}
                            </Field>
                            <Button
                                mr={{ base: 0, lg: 6 }}
                                type="submit"
                                aria-label="login"
                                isLoading={props.isSubmitting}
                                //isDisabled={token.length <= 0}
                                size="xs"
                                borderRadius="md"
                                textTransform="uppercase"
                                colorScheme="buttonSecondary"
                                state={{ from: location }}
                            >
                                {getText('global.text.login', 'LogIn')}
                            </Button>
                        </Box>
                        {hasError && (
                            <Text color="red.500" marginTop={2}>
                                {getText(`Error.${hasError.code}`, hasError.error)}
                            </Text>
                        )}

                        {/* {challengeError && <Text color="red.500" marginTop={2}>
                            {parseError(challengeError)}
                        </Text>} */}
                        <Turnstile
                            ref={turnstileRef}
                            language={language}
                            siteKey={process.env.REACT_APP_TURNSTILE_Key}
                            options={{ execution: 'execute' }}
                        />
                    </Form>
                )
            }}
        </Formik>
    )
}

/**
 * When adding url to link we need to keep routing internal import {NavLink as ReachLink} from '...'
 * <Link as={ReachLink} to="/lotto">
 */
const Header = () => {
    const isAuthed = useSelector((state) => state.user.isAuthed)
    return (
        <>
            <LayoutContain paddingX={{ base: 0, md: 8 }} layerStyle="header">
                <Grid
                    py={4}
                    display="grid"
                    gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                    columnGap={2}
                >
                    <GridItem
                        display="flex"
                        justifyContent={{ base: 'center', md: 'flex-start' }}
                        alignItems="flex-end"
                    >
                        <LinkBox>
                            <LinkOverlay as={ReachLink} to="/">
                                <Image
                                    maxW={{ base: '80px', md: '125px' }}
                                    objectFit="cover"
                                    src={logo}
                                />
                            </LinkOverlay>
                        </LinkBox>

                        <Box
                            display={{ base: 'none', md: 'inline-block' }}
                            textAlign="left"
                            ml={10}
                        >
                            <Text
                                textStyle="headingFontFamily"
                                as="h1"
                                mb="0"
                                lineHeight="1"
                                scope="global"
                                tag="title.yourOnlyLottery"
                                text="Your only Lottery"
                                fontSize="2xl"
                            />
                            <Text
                                scope="global"
                                tag="sentence.millionaireFactory"
                                text="THE MILLIONAIRE FACTORY"
                                textStyle="semiBoldFontFamily"
                            />
                        </Box>
                    </GridItem>
                    <GridItem
                        display={{ base: 'none', md: 'flex' }}
                        flexDirection="column"
                        alignContent="flex-end"
                        alignItems="flex-end"
                        justifyContent="space-between"
                    >
                        <Box display="flex" alignItems="flex-start">
                            <Login isAuthed={isAuthed} />
                            <Box display={{ base: 'none', lg: 'flex' }} alignItems="flex-start">
                                <UserButton />
                                <SideDrawer />
                            </Box>
                        </Box>

                        <List fontSize="sm" display="flex">
                            <ListItem>
                                <Text
                                    as={ReachLink}
                                    to="page/responsible-gambling"
                                    scope="global"
                                    tag="text.responsibleGambling"
                                    text="Responsible Gaming"
                                />
                            </ListItem>
                            <ListItem mx={4}>
                                <Text
                                    as={ReachLink}
                                    to="page/help"
                                    scope="global"
                                    tag="text.faqs"
                                    text="FAQs"
                                />
                            </ListItem>
                            <ListItem>
                                <Text
                                    as={ReachLink}
                                    to="contact-us"
                                    scope="global"
                                    tag="text.contactUs"
                                    text="Contact Us"
                                />
                            </ListItem>
                        </List>
                    </GridItem>
                </Grid>
            </LayoutContain>
            <MainNavigation />
        </>
    )
}

export default Header

import React from 'react'

// Load components
import { Box, Text } from '@chakra-ui/react'

// Load helper
import PageTitle from '../UI/PageTitle/PageTitle'

// Load FE lib
import { useLanguageContext } from 'component/Translations/Translations'

// Stubs
const RejectedMessage = () => {
    // Language context
    const { getText } = useLanguageContext()

    return (
        <Box>
            <PageTitle
                title={getText('account.title.accountRejected', 'Account Rejected')}
                layerStyle="pageTitleAccount"
            />
            <Text>
                {getText(
                    'account.sentence.accountRejected',
                    'We regret to inform you that we are unable to approve your account registration at this time. If you have any questions or need further assistance, please contact our customer support service team. Thank you for considering our services.'
                )}
            </Text>
        </Box>
    )
}

export default RejectedMessage

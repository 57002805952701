import {
    SET_BETTING_CLOSED,
    SET_NEXT_DRAW_ID,
    SET_NEXT_DRAW_TIMESTAMP,
    SET_PREVIOUS_DRAW,
} from "./Rapid.constants"

export const setBettingClosed = (isClosed) => ({
    type: SET_BETTING_CLOSED,
    payload: isClosed
})

export const setNextDrawId = (drawId, lotteryId) => ({
    type: SET_NEXT_DRAW_ID,
    payload: {
        drawId,
        lotteryId
    }
})

export const setNextDrawTimestamp = (nextDrawTimestamp, lotteryId) => ({
    type: SET_NEXT_DRAW_TIMESTAMP,
    payload: {
        nextDrawTimestamp,
        lotteryId
    }
})

export const setPreviousDraw = (data) => ({
    type: SET_PREVIOUS_DRAW,
    payload: data
})


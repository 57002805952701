import { accordionAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    accordionAnatomy.keys
)

const baseStyle = definePartsStyle({
    root: {},
    button: {},
    container: {
        border: '1px',
        backgroundColor: 'accordion.containerBackground',
        borderRadius: 'lg',
        textColor: 'borderPrimaryLight',
        marginBottom: '24px'
    },

    panel: {
        background: 'secondary.900',
        borderRadius: 'lg'
    }
})

export const accordionTheme = defineMultiStyleConfig({ baseStyle })

import { Component } from "react"
import { connect } from "react-redux"

// Websocket actions
import { websocketCloseUser, websocketOpenUser } from "../../redux/Websocket/Websocket.actions"
import { requestTokenStart } from "../../redux/Token/Token.actions"
import { closeNotification } from "redux/notification/notification.actions"

class EventBusSubscriber extends Component {
  /**
   * Storing a copy of id here so we can unsubsribe and close the WS channel
   * logout event reset user to initial state
   */
  state = {
    id: null,
  }

  componentDidMount() {
    const { loginEvent } = this
    const { isAuthed, userId } = this.props

    // We are ready to bind our WS?
    if (isAuthed && userId) {
      loginEvent({ userId })
    }

  }

  componentDidUpdate(prevProps) {
    const {
      props: { isAuthed, userId },
      state: { id },
      loginEvent,
      logoutEvent,
    } = this

    // Check props has changed and user isAuthed: true
    if (prevProps.isAuthed !== isAuthed && isAuthed) {
      loginEvent({ userId })
    }

    // Check props has changed and user isAuthed: false4
    if (prevProps.isAuthed !== isAuthed && !isAuthed) {
      logoutEvent({ userId: id })
    }
  }

  /**
   * Dispatch login events
   * onStartChannel: start the tote-user web socket
   */
  loginEvent = ({ userId }) => {
    const { onOpenChannel } = this.props

    // Keep id in state so we can refernce the value on logout
    this.setState({ id: userId })

    // Subscribe to user channel
    return onOpenChannel(userId)
  }

  /**
   * Dispatch logout events
   * unsubscribe from user channel
   */
  logoutEvent = ({ userId }) => {
    const { onCloseChannel, onClearNotifications } = this.props

    // Close the channel
    onCloseChannel(userId)
    onClearNotifications()

    // Reset to initial state
    this.setState({ id: null })
  }


  render() {
    return null
  }
}

const mapStateToProps = (state) => {
  return {
    balance: state.user.balance,
    userId: state.user.id,
    isAuthed: state.user.isAuthed,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onOpenChannel: (user) => dispatch(websocketOpenUser(user)),
    onCloseChannel: (close) => dispatch(websocketCloseUser(close)),
    onRefreshToken: () => dispatch(requestTokenStart()),
    onClearNotifications: () => dispatch(closeNotification())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EventBusSubscriber)

import colors from "./styles/colors"
import { radii } from "./styles//sizes"
import { fonts } from "./foundation/typography"
import global from "./styles/global"
import layerStyles from "./foundation/layerStyles"
import textStyles from "./foundation/textStyles"

/**
 * Export from here any overrides
 */
const theme = { colors, radii, fonts, layerStyles, textStyles, styles: global }

export default theme

import { useMemo } from "react"
import { useSearchParams, useParams } from "react-router-dom"

/**
 * Data filters transforms
 */
const TRANSFORMTYPES = {
  convertToPence: "convertToPence",
  convertToBool: "convertToBool",
  setEndTime: "setEndTime", // Temp work around until we fix UTC date helper
}

/**
 * @todo - Configurable by admin preferences
 */
const DEFAULTLIMIT = 10

const useSetSearchParams = (isCRM = false) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { playerId } = useParams()

  // Remove param key/value
  function remove(key) {
    searchParams.delete(key)
    setSearchParams(searchParams)
  }

  // Set param by key/value
  function set(key, value) {
    searchParams.set(key, value)
    setSearchParams(searchParams)
  }

  /**
   * Set page value.
   *
   * Typically used with Pagination component, but currently
   * only requires the page number from it.
   *
   * @example <Pagination onPageChange={setPage} />
   *
   * @param {{limit?: number, page: number}} value
   */
  function setPage(value) {
    searchParams.set("page", value.page)
    searchParams.set("limit", value.limit || DEFAULTLIMIT)
    setSearchParams(searchParams)
  }

  /**
   * Utility function for setting data filters to url
   *
   * @param {{fieldNames: string[], comparators: string[], values: any[][]}} filters
   * @param {Object.<string, string | string[]>} transforms
   */
  function setFilters(filters, transforms = TRANSFORMTYPES) {
    // Reset page + set filters
    set("page", 1)
    if (!filters) {
      return remove("dataFilters")
    }

    Object.keys(transforms).forEach((key) => {
      // Find any submitted values that are in convertToPence obj property,
      // either as string or string[]
      if (key === TRANSFORMTYPES.convertToPence) {
        // Could use Array(transforms[key]) here if it's not an array,
        // but passing a number to it will instead allocate an array of that length
        if (Array.isArray(transforms[key])) {
          const fieldIndexes = transforms[key].map((field) => filters.fieldNames.indexOf(field))
          fieldIndexes.forEach((fieldIndex) => {
            // Assign value in an array as that's how dataFilter values are expected
            filters.values[fieldIndex] = [filters.values[fieldIndex] * 100]
          })
        } else {
          const fieldIndex = filters.fieldNames.indexOf(transforms[key])
          if (fieldIndex !== -1) {
            filters.values[fieldIndex] = [filters.values[fieldIndex] * 100]
          }
        }
      }

      if (key === TRANSFORMTYPES.convertToBool) {
        const findIndex = filters.fieldNames.indexOf(transforms[key])

        if (findIndex !== -1) {
          filters.values[findIndex] = [filters.values[findIndex][0] === "true" ? true : false]
        }
      }

      if (key === TRANSFORMTYPES.setEndTime) {
        // Find the locations of any date fields with dates,
        // and if one of them has a comparator of <= (representing end date),
        // then append T23:59:59 to the value so the API covers all results during that day
        const dateIndex = filters.fieldNames.indexOf(transforms[key])

        if (dateIndex !== -1) {
          filters.values[dateIndex][0] = `${filters.values[dateIndex][0]} 23:59:59`
        }
      }

      /**
       * Some of the CRM pages use data filters instead or a url param
       * In those cases set isCRM flag to add the userId in data filters for all requests
       */
      if (isCRM) {
        filters.fieldNames.push("userId")
        filters.comparators.push("=")
        filters.values.push([playerId])
      }
    })

    set("dataFilters", JSON.stringify([filters]))
  }

  // Return key/value pair of search params
  const getSearchParams = useMemo(() => {
    // Default params
    const params = {
      page: 1,
      limit: DEFAULTLIMIT,
    }

    // Params changed
    searchParams.forEach((value, key) => (params[key] = value))

    if (!params.dataFilters && isCRM) {
      params.dataFilters = JSON.stringify([
        {
          fieldNames: ["userId"],
          comparators: ["="],
          values: [[playerId]],
        },
      ])
    }

    return params
  }, [searchParams, isCRM, playerId])
  return {
    set,
    remove,
    setPage,
    setFilters,
    getSearchParams,
  }
}

export default useSetSearchParams

// Leidsa logo
import leidsaLogo from "../../../img/leidsaLogo.png"
import rapidsLogo from "../../../img/logo-rapids.svg"
import bicheiroLogo from "../../../img/logo-bicheiro.svg"

const logos = {
  sportech: rapidsLogo,
  leidsa: leidsaLogo,
  rapids: rapidsLogo,
  bicheiro: bicheiroLogo,
}

// Look up the color palette
const themeLookUp = process.env.REACT_APP_PARTNER ? process.env.REACT_APP_PARTNER : "sportech"

// Get the logo SVG
export default logos[themeLookUp]

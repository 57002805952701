import React, { lazy, useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

// Load components
//import HomePage from "../../page/HomePage/HomePage.page"
import Loadable from './Loadable'
import RouteError from '../404/RouteError'
import PrivateRoute from './PrivateRoute'

// Load Actions
import { requestUserStart } from '../../redux/User/User.actions'
import MainTemplate from '../../template/MainLayout'
import AccountLayout from '../../template/AccountLayout'

// Helper
import lazyRetry from 'helper/lazyRetry'

// Load main routes
const HomePage = Loadable(lazy(() => lazyRetry(() => import('../../page/HomePage/HomePage.page'))))
const Login = Loadable(lazy(() => lazyRetry(() => import('../../container/Login.container'))))
const PlayLobby = Loadable(lazy(() => lazyRetry(() => import('../../page/PlayLobby/PlayLobby.page'))))
const Play = Loadable(lazy(() => lazyRetry(() => import('../../page/PlayPage/Play.page'))))
const LotteryResult = Loadable(lazy(() => lazyRetry(() => import('../../page/LotteryResult/LotteryResult.page'))))
const ResultsLobby = Loadable(lazy(() => lazyRetry(() => import('../../page/ResultsLobby/ResultsLobby.page'))))
const NewsPage = Loadable(lazy(() => lazyRetry(() => import('../../page/News/NewsPage'))))
const NewsArticle = Loadable(lazy(() => lazyRetry(() => import('../../page/News/NewsArticle'))))
const Winners = Loadable(lazy(() => lazyRetry(() => import('../../page/Winners/Winners'))))
const Procedures = Loadable(lazy(() => lazyRetry(() => import('../../page/Procedures/Procedures'))))
const Scratchcards = Loadable(lazy(() => lazyRetry(() => import('../../page/Scratchcards/Scratchcards'))))
const ContactUs = Loadable(lazy(() => lazyRetry(() => import('../../page/ContactUs/ContactUs'))))

// Load contentful routes
const HelpCategory = Loadable(lazy(() => lazyRetry(() => import('../../page/HelpCategory/HelpCategory.page'))))
const HelpArticle = Loadable(lazy(() => lazyRetry(() => import('../../page/HelpArticle/HelpArticle.page'))))
const Contentful = Loadable(lazy(() => lazyRetry(() => import('../../page/Contentful/Contentful.page'))))

// Load password and reg routes
const PasswordReset = Loadable(lazy(() => lazyRetry(() => import('../../page/PasswordReset/PasswordReset.page'))))
const PasswordChange = Loadable(lazy(() => lazyRetry(() => import('../../page/PasswordChange/PasswordChange'))))
const Register = Loadable(lazy(() => lazyRetry(() => import('../../container/Register.container'))))

// Load Account pages
const YourDetails = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/YourDetails'))))
const AccountSummary = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/AccountSummary'))))
const SearchTransactions = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/Transactions'))))
const Messages = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/Messages'))))
const ResponsibleGambling = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/ResponsibleGambling'))))
const LotteryTickets = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/LotteryTickets'))))
const LotteryLines = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/LotteryLines'))))
const Withdraw = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/Withdraw'))))
const AddFunds = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/AddFunds'))))
const LotterWinners = Loadable(lazy(() => lazyRetry(() => import('../../page/Account/LotteryWinners'))))

const AppInit = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(requestUserStart())
    }, [dispatch])

    return null
}

const Routing = () => {
    // Redirect after login
    const location = useLocation()
    const isAuthed = useSelector((state) => state.user.isAuthed)
    const from = location.state?.from || '/'

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname, title: 'Navigatgion' })
    }, [location.pathname])

    return (
        <>
            <AppInit />
            <Routes>
                <Route element={<MainTemplate />}>
                    <Route path="winners" element={<Winners />} />
                    <Route path="procedures" element={<Procedures />} />
                    <Route path="scratchcards" element={<Scratchcards />} />
                    {/** Results */}
                    <Route path="results">
                        <Route path=":lotteryId/:lotteryName/:drawId" element={<LotteryResult />} />
                        <Route path=":lotteryId/:lotteryName" element={<LotteryResult />} />
                        <Route index element={<ResultsLobby />} />
                    </Route>

                    {/** Games */}
                    <Route path="play">
                        <Route path='more' element={<PlayLobby />} />
                        <Route path=":game" element={<Play />} />
                        <Route index element={<PlayLobby />} />
                    </Route>

                    <Route path="contact-us" element={<ContactUs />} />

                    {/** Contentful */}
                    <Route path="page/:slug" element={<Contentful />} />
                    <Route path="help/:category/article/:slug" element={<HelpArticle />} />
                    <Route path="help/:category" element={<HelpCategory />} />

                    {/* News */}
                    <Route path="news">
                        <Route path=":slug" element={<NewsArticle />} />
                        <Route index element={<NewsPage />} />
                    </Route>

                    {/** Register & Login */}
                    <Route
                        path="register"
                        element={
                            <PrivateRoute
                                authentication={!isAuthed}
                                component={Register}
                                redirectTo="/"
                            />
                        }
                    />
                    <Route
                        path="reset/password"
                        element={
                            <PrivateRoute
                                authentication={!isAuthed}
                                component={PasswordReset}
                                redirectTo="/"
                            />
                        }
                    />
                    <Route
                        path="/reset/password/:token"
                        element={
                            <PrivateRoute
                                authentication={!isAuthed}
                                component={PasswordChange}
                                redirectTo="/"
                            />
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <PrivateRoute
                                authentication={!isAuthed}
                                component={Login}
                                redirectTo={from}
                            />
                        }
                    />
                    {/** Register & Login */}
                    <Route
                        path="register"
                        element={
                            <PrivateRoute
                                authentication={!isAuthed}
                                component={Register}
                                redirectTo="/"
                            />
                        }
                    />
                    <Route
                        path="reset/password"
                        element={
                            <PrivateRoute
                                authentication={!isAuthed}
                                component={PasswordReset}
                                redirectTo="/"
                            />
                        }
                    />
                    <Route
                        path="/reset/password/:token"
                        element={
                            <PrivateRoute
                                authentication={!isAuthed}
                                component={PasswordChange}
                                redirectTo="/"
                            />
                        }
                    />
                    <Route
                        path="/login"
                        element={
                            <PrivateRoute
                                authentication={!isAuthed}
                                component={Login}
                                redirectTo={from}
                            />
                        }
                    />

                    {/** @todo account routes */}
                    <Route
                        path="account"
                        element={
                            <PrivateRoute
                                authentication={isAuthed}
                                component={AccountLayout}
                            />
                        }
                    >
                        <Route path="details" element={<YourDetails />} />
                        <Route path="transactions" element={<SearchTransactions />} />
                        <Route path="messages" element={<Messages />} />
                        <Route path="responsible-gambling" element={<ResponsibleGambling />} />
                        <Route path="transactions" element={<SearchTransactions />} />
                        <Route path="lottery" element={<LotteryTickets />} />
                        <Route path="winning/lines" element={<LotterWinners />} />
                        <Route path="lottery/ticket/:ticketId" element={<LotteryLines />} />
                        <Route path="withdraw" element={<Withdraw />} />
                        <Route path="add-funds" element={<AddFunds />} />
                        <Route index element={<AccountSummary />} />
                    </Route>
                    <Route index element={<HomePage />} />
                    <Route path="*" element={<RouteError />} />
                </Route>
            </Routes>
        </>
    )
}
export default Routing

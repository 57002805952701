import { theme as baseTheme } from "@chakra-ui/react"

/**
 * Default base color theme
 * Override using partner theme do not change here
 *
 * Colour Mapping
 *
 * https://mdigi.tools/lighten-color
 * https://mdigi.tools/darken-color
 *
 * Light/Dark shades for buttons hover/active states
 * X00 : Default base color
 * X25 : Light shade of base color (20%)
 * X50 : Dark shade of base color (30%)
 */
const colourList = {
  ...baseTheme.colors,
  primary: {
    900: "#09A55E",
    925: "#0bc570",
    950: "#067342",
    800: "#4071AF",
    825: "#608dc5",
    850: "#376196",
    700: "#CBD9EB",
    725: "#d5e1ef",
    750: "#739bca",
  },
  secondary: {
    900: "#f5e50a",
    925: "#f7ea3b",
    950: "#cec008",
    800: "#FFC15F",
  },
  tertiary: {
    900: "#cecdf3",
  },
  success: {
    900: "#8EC434",
  },
  error: {
    900: "#AD1700",
    925: "#f02000",
    950: "#791000",
  },
  neutral: {
    900: "#07181C",
    800: "#808080",
    700: "#F5F5F5",
    600: "#EFF0F1",
    500: "#f0f9f6",
  },
  textBody: "#151515",
  background: "#09A55E",
}

const colors = {
  ...colourList,

  /**
   * Border colors
   */
  borderPrimary: colourList.primary[900],
  borderPrimaryLight: colourList.neutral[600],
  borderInsideBetslip: "white",

  /**
   * Icon colors
   */
  iconPrimary: colourList.primary[900],
  iconSecondary: colourList.tertiary[900],

  /**
   * Typograghy colors
   */
  primaryTextColor: colourList.textBody,
  primaryLinkColor: colourList.textBody,
  primaryLinkColorActive: colourList.primary[900],
  primaryHeaderColor: colourList.textBody,
  secondaryHeaderColor: "white",

  /**
   * Backgrounds
   */
  backgroundPrimary: colourList.background,
  headerBackground: "white",
  footerBackground: "white",
  drawCountdown: colourList.textBody,

  /**
   * Account Menu colors
   */
  accountMenu: {
    background: "white",
    headerTextColor: colourList.neutral[800],
    itemBackground: "white",
    itemTextColor: colourList.textBody,
    itemBorderColor: colourList.neutral[500],
  },

  /**
   * Game Info header
   */
  gameHeader: {
    background: colourList.neutral[700],
    color: colourList.textBody,
  },

  /**
   * Betslip colors
   */
  betslip: {
    // background color
    background: "white",
    // top half background
    backgroundTop: colourList.neutral[500],
    // Inside border colors
    border: "white",
    // general text color
    color: colourList.textBody,
    // Rapids titles
    title: "white",
    // Bet type title
    gameTitle: colourList.primary[900],
    box: {
      border: colourList.primary[800],
    },
  },
  pagination: {
    color: colourList.textBody,
    activeBackground: colourList.primary[900],
    background: "white",
    hoverBackground: colourList.primary[700],
  },

  /**
   * Stats card component styles
   */
  card: {
    background: colourList.neutral[500],
    textColor: colourList.primary[900],
    labelColor: colourList.textBody,
    borderColor: colourList.primary[700],
    iconHeaderColor: colourList.primary[900],
    iconBodyColor: colourList.primary[900],
  },

  /**
   * Content Card component styles
   */
  contentCard: {
    headerBackground: colourList.neutral[500],
    headerTextColor: colourList.textBody,
    borderColor: colourList.neutral[600],
    backgroundColor: "white",
  },

  /**
   * Input element styles
   */
  input: {
    border: colourList.primary[700],
    background: "white",
    filledBackground: colourList.neutral[500],
    text: colourList.textBody,
    filledText: "#111111",
  },

  /**
   * Table element styles
   */
  table: {
    headerBackground: "white",
    bodyBackground: "white",
    cellBackground: "white",
    headerTextColor: colourList.textBody,
    border: colourList.primary[700],
    color: colourList.textBody,
  },
  /**
   * Recent winners component
   */
  winner: {
    background: "white",
    border: colourList.neutral[500],
    color: colourList.textBody,
  },

  /**
   * Page Title component
   *
   */
  pageTitle: {
    border: colourList.primary[900],
    color: "white",
  },

  /**
   * Button Colors - Keep decision tokens same as chakra to keep consisten application of tokens
   *
   * 50 - hover backgound outline variant
   * 100 - Text color
   * 200 - background darkmode
   * 300 - Hover text color outline
   * 500 - background
   * 600 - hover backround
   * 700 - active background
   */
  buttonPrimary: {
    700: colourList.primary[950],
    600: colourList.primary[925],
    500: colourList.primary[900],
    200: colourList.primary[950],
    100: "white",
    50: colourList.primary[900],
  },
  buttonSecondary: {
    700: colourList.secondary[950],
    600: colourList.secondary[925],
    500: colourList.secondary[900],
    200: colourList.secondary[950],
    50: colourList.secondary[900],
    100: colourList.textBody,
  },
  buttonError: {
    700: colourList.error[950],
    600: colourList.error[925],
    500: colourList.error[900],
    200: colourList.error[950],
    100: colourList.error[900],
  },
  buttonNeutral: {
    700: colourList.primary[750],
    600: colourList.primary[725],
    500: colourList.primary[700],
    200: colourList.primary[750],
    100: colourList.primary[800],
    50: colourList.primary[700],
  },
  buttonAddCard: {
    700: colourList.neutral[700],
    600: colourList.neutral[700],
    500: "white",
    200: colourList.neutral[700],
    100: colourList.primary[900],
    50: "white",
  },
  buttonTextColor: colourList.textBody,

  /**
   * Play Card component
   */
  playCard: {
    cardBackground: "white",
    cardBorder: "transparent",
    /**
     * Footer styles
     */
    footerBackgroundColor: colourList.neutral[500],
    footerBorder: colourList.neutral[500],
    /**
     * Header styles
     */
    headerBackgroundColor: colourList.neutral[500],
    headerBorder: colourList.neutral[500],
    headerLink: colourList.red[500],
  },

  /**
   * LoteryBall
   *
   * 100: solid bkg
   * 200: solid border
   * 300: solid text color
   *
   * 400: outline bkg
   * 500: outline border
   * 600: outline number
   */
  lotteryBall: {
    notSelected: {
      100: `radial-gradient(circle at 50% 13%, #fff, #e3e3e3)`,
      200: colourList.primary[900],
      300: colourList.primary[900],
      400: "white",
      500: colourList.primary[900],
      600: colourList.primary[900],
    },
    selected: {
      100: `radial-gradient(circle at 33% 33%, #fff8e3, #2F2F2F)`,
      200: colourList.primary[900],
      300: "white",
      400: "white",
      500: colourList.primary[900],
      600: colourList.primary[900],
    },
    bonus2: {
      100: colourList.tertiary[900],
      200: colourList.tertiary[900],
      300: "white",
      400: "white",
      500: colourList.tertiary[900],
      600: colourList.tertiary[900],
    },
    bonus1: {
      100: colourList.secondary[800],
      200: colourList.secondary[800],
      300: "white",
      400: "white",
      500: colourList.secondary[800],
      600: colourList.secondary[800],
    },
  },
  lotteryTick: {
    backgroundColor: colourList.green[600],
    iconColor: "white",
  },
}

export default colors

import React from "react"
import { Box, Spinner } from "@chakra-ui/react"

const Loading = () => {
  return (
    <Box height="100%">
      <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
        <Spinner size="lg" color="primary.900" />
      </Box>
    </Box>
  )
}

export default Loading

import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'

// Load components
import AccountSubMenu from '../component/Navigation/Account/AccountSubMenu'
import { Box } from '@chakra-ui/react'
import Verification from '../page/Verification/Verification'

const AccountLayout = () => {
    /**
     * Check on user object if have passed KYC checks
     */
    const kyc = useSelector((state) => state.user.kycStatus !== 'verified')

    return (
        <Box
            display="flex"
            height="100%"
            flexDirection={{ base: 'column', lg: 'row' }}
            px={{ base: 4, md: 6 }}
            py={8}
            width="100%"
            mx="auto"
            maxW="1400px"
        >
            <Box
                as="nav"
                minWidth={{ base: '100%', lg: '250px' }}
                aria-label="account"
                mb={{ base: 6, lg: 0 }}
                mr={{ base: 0, lg: 6 }}
            >
                <AccountSubMenu />
            </Box>
            <Box width="100%" position="relative" pt={4}>
                {kyc ? <Verification /> : <Outlet />}
            </Box>
        </Box>
    )
}

export default AccountLayout

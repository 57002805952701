import { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import * as yup from "yup"

//Load Components
import { Select, FormControl, FormLabel, FormErrorMessage, Button, Stack, Box, Text } from "@chakra-ui/react"
import { Formik, Form, Field } from "formik"
import InlineError from "../../Errors/InlineError/InlineError"
import OTPInput from "react-otp-input"

//Load hooks
import { useLanguageContext } from "component/Translations/Translations"
import { useDepositMutate, useCardsQuery, useDepositOTP } from "../../../hooks/queries/useGateway"

//Load actions
import { openNotification } from 'redux/notification/notification.actions'

//Load constants
import notificationConstants from 'redux/notification/notification.constants'

/**
 * @todo
 * Add translations
 *
 * Error should be formatted to => { error: X, code: X}
 */
const schema = yup.object().shape({
  cardId: yup.string().required('Please select a card'),
  otp: yup.string().required('Please enter OTP code').length(6)
})


const OTPConfirm = ({ cardId, email, data, closeModal }) => {
  const [error, setError] = useState()
  const { getText } = useLanguageContext()
  const mutation = useDepositMutate()
  const dispatch = useDispatch()
  const [isDisabled, setDisabled] = useState(false)
  const mutate = useDepositOTP()

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisabled(false)
    }, 25000)

    return () => clearTimeout(timer)

  }, [isDisabled])

  return (
    <>
      <InlineError error={error} cardnet />
      <Box textAlign="center" mb={4}>
        <Text as="h2">
          {getText('account.otpVerification', 'OTP Verification')}
        </Text>
        <Text color="gray">
          {getText('account.enterOTPCode', 'Enter OTP code sent to')} {email}</Text>
      </Box>
      <Formik
        enableReinitialize
        initialValues={{
          amount: data.amount || 0,
          cardId,
          otp: ''
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          return mutation.mutate(
            { values },
            {
              onSuccess: async () => {
                const message = getText("account.notification.transactionSuccess", "Transaction Success")
                dispatch(openNotification({ message, status: notificationConstants.NOTIFICATION_SUCCESS }))
                return closeModal()
              },
              onError: async (error) => {
                return setError(error)
              },
              onSettled: () => {
                actions.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(props) => {
          return (
            <Form>
              <Stack spacing={4}>
                <Field name="otp">
                  {({ field, form }) => {
                    return (
                      <FormControl maxWidth="250px" mx="auto" id="otp" isInvalid={form.errors.otp && form.touched.otp}>
                        <OTPInput
                          value={field.value}
                          onChange={(value) => form.setFieldValue('otp', value)}
                          numInputs={6}
                          inputType="number"
                          renderSeparator={<span>-</span>}
                          inputStyle={{
                            border: '1px solid',
                            borderRadius: '5px',
                            width: "40px",
                            height: "40px"
                          }}
                          renderInput={(props) => <input {...props} />}
                        />
                        <FormErrorMessage>{getText('form.otp.error', 'Please enter verification code')}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
                <Box textAlign="center">
                  <Text color="lightgray" mb="1">
                    {getText('form.otp.notReceivedCode', "Didn't receive OTP code?")}
                  </Text>
                  <Button size="sm" colorScheme="gray" isDisabled={isDisabled} isLoading={mutate.isLoading} color="gray" onClick={() => mutate.mutate(undefined, { onSuccess: () => setDisabled(true) })}>
                    <Text as="span">
                      {getText('form.otp.resendCode', 'Resend Code')}
                    </Text>
                  </Button>
                  <Text color="red.500" fontSize="sm" fontWeight="bold" mt={4}>
                    {getText("account.alertOTPCode", "Do not close the screen unless you intend not to continue with the top up")}
                  </Text>
                </Box>
                <Box layerStyle="modalActionButtonPlacement">
                  <Button id="addFundsModalButton" type="submit" size="lg" borderRadius="0" layerStyle="modalActionButton" isLoading={props.isSubmitting}>
                    {getText(`account.button.addFunds`, `Add Funds`)}
                  </Button>
                </Box>
              </Stack>
            </Form>
          )
        }}
      </Formik >
    </>
  )
}

const SelectCard = ({ data, closeModal, email }) => {
  const { getText } = useLanguageContext()
  const { data: cards } = useCardsQuery()
  const [selectedCard, setCard] = useState('')
  const mutate = useDepositOTP()

  return (
    <>
      {!mutate.isSuccess ?
        <>
          <InlineError error={mutate.error} cardnet />
          <FormControl id="cardId">
            <FormLabel>{getText("account.formLabel.selectCard", "Please select the card you want to use.")}</FormLabel>
            <Select onChange={(e) => setCard(e.target.value)} id="cardId" name="cardId" value={selectedCard}>
              <option key="notset" value="notset">

              </option>
              {cards?.data.map((card) => {
                return (
                  <option key={card.id} value={card.id}>
                    {card.cardType + card.cardNumber}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <Box layerStyle="modalActionButtonPlacement">
            <Button id="addFundsModalButton" onClick={() => mutate.mutate()} isLoading={mutate.isLoading} isDisabled={!selectedCard.length} size="lg" borderRadius="0" layerStyle="modalActionButton">
              {getText('global.button.confirm', 'Confirm')}
            </Button>
          </Box>
        </>
        :
        <OTPConfirm cardId={selectedCard} data={data} closeModal={closeModal} email={email} />
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
  email: state.user.data?.email ?? ''
})

export default connect(mapStateToProps)(SelectCard)

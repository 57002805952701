import { useQuery, useQueryClient, useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import useAxios from '../useAxios'
import { getPreviousDraws, getPreviousDrawsByLottery } from '../../helper/lotteries'
//import { orderByDate } from '../../helper/date'
import { platformCurrencyISO } from 'helper/envs'
import useSetSearchParams from '../useSetSearchParams'

/**
 * Define keys for lotteries
 */
const lotteriesKeys = {
    all: [{ scope: 'lotteries' }],
    combined: () => [{ ...lotteriesKeys.all[0], entity: 'combined' }],
    lottery: (id) => [{ ...lotteriesKeys.all[0], entity: 'lottery', id }],
    games: () => [{ ...lotteriesKeys.all[0], entity: 'gamesList' }],
    list: ({ filters, page, limit }) => [
        { ...lotteriesKeys.all[0], entity: 'list', filters, page, limit }
    ],
    tickets: ({ filters, limit, page }) => [
        { ...lotteriesKeys.all[0], entity: 'lotteryTickets', filters, page, limit }
    ],
    ticket: ({ id }) => [{ ...lotteriesKeys.all[0], entity: 'lotteryTicket', id }],
    types: () => [{ scope: 'types', entity: 'list' }],
    lines: ({ id, page, limit }) => [
        { ...lotteriesKeys.all[0], entity: 'lotteryLines', id, page, limit }
    ],
    winners: ({ page, limit, filters }) => [
        { ...lotteriesKeys.all[0], entity: 'lotteryWinners', filters, page, limit }
    ],
    lineMeta: ({ lineId, ticketId }) => [
        { ...lotteriesKeys.all[0], entity: 'lineMeta', lineId, ticketId }
    ]
}

/**
 * Transform data to get draws
 * @param {Array} data contains array of objects for each lottery
 * @returns {Array}
 */
function transformPreviousDraws({ data }) {
    const draws = getPreviousDraws(data)
    const featured = getPreviousDrawsByLottery(data)
    return {
        all: draws,
        previousThree: draws.slice(0, 3),
        featured
    }
}

/**
 * From our list our lotteries return a lottery by its id
 * @param {Array} data
 * @param {Numer} id
 */
function transformLotteryById({ data, currencies }, id) {
    const lottery = data.find((item) => item.id === Number(id))
    const currency = lottery
        ? currencies.find((currency) => currency.id === lottery.currencyId)
        : {}
    const targetCurrency = lottery
        ? currencies.find((currency) => currency.shortName === platformCurrencyISO)
        : {}
    return {
        lottery,
        currency,
        targetCurrency
    }
}

const fetchLotteries = async (axios) => {
    const response = await axios.get('lottery')
    return response.data
}

// General games
const fetchLotteryGames = async (axios) => {
    const response = await axios.get('lottery/games')
    return response.data
}

const fetchLotteriesCombined = async (axios) => {
    const response = await axios.get('lottery/combined')
    return response.data
}

const fetchLottery = async ({ axios, id }) => {
    const response = await axios.get(`lottery/${id}`)
    return response.data
}

const fetchLotteryTickets = async ({ axios, params }) => {
    const response = await axios.get('lottery/tickets', { params })
    return response.data
}

const fetchLotteryLines = async ({ axios, ticketId, params }) => {
    const response = await axios.get(`lottery/tickets/${ticketId}/lines`, { params })
    return response.data
}

const fetchLotteryWinners = async ({ axios, params }) => {
    const response = await axios.get('lottery/tickets/lines/winners', { params })
    return response.data
}

const fetchLotteryGameTypes = async ({ axios }) => {
    const response = await axios.get('lottery/games-key-value')
    return response.data
}

// Ticket redeem / payout
const postTicketRedeem = async ({ axios, body }) => {
    const response = await axios.post('lottery/redeem', body)
    return response.data
}

const postTicketDeposit = async ({ axios, body }) => {
    const response = await axios.post('lottery/payout', body)
    return response.data
}

const fetchLinMeta = async ({ axios, ticketId, lineId }) => {
    const response = await axios.get(`lottery/tickets/${ticketId}/lines/${lineId}/redeemed`)
    return response.data
}

const useLineMetaQuery = ({ ticketId, lineId }) => {
    const axios = useAxios({ useAuth: false })
    const isReady = !!ticketId && !!lineId
    return useQuery(
        lotteriesKeys.lineMeta({ ticketId, lineId }),
        () => fetchLinMeta({ axios, lineId, ticketId }),
        { enabled: isReady }
    )
}


const postSendTicket = async ({ axios, ticketId, body }) => {
    const response = await axios.post(`lottery/tickets/${ticketId}/redeem/emailTicket`, body)
    return response.data
}

const useSendWinTicket = () => {
    const axios = useAxios()
    return useMutation(({ ticketId, ticketLineId }) => postSendTicket({ axios, ticketId, body: { ticketLineId } }))
}

/**
 * useLotteriesQeury
 */
const useLotteriesQuery = (select) => {
    const axios = useAxios({ useAuth: false })
    return useQuery(lotteriesKeys.all, () => fetchLotteries(axios), { select })
}
/**
 * Request all Lottery Games
 */
const useLotteryGamesQuery = () => {
    const axios = useAxios()
    return useQuery(lotteriesKeys.games(), () => fetchLotteryGames(axios))
}
const useLotteriesCombinedQuery = (select) => {
    const axios = useAxios({ useAuth: false })
    return useQuery(lotteriesKeys.combined(), () => fetchLotteriesCombined(axios), { select })
}
const useLotteryQuery = (id) => {
    const axios = useAxios({ useAuth: false })
    return useQuery(lotteriesKeys.lottery(id), () => fetchLottery({ axios, id }), { enabled: !!id })
}
const useLotteryGameTypes = () => {
    const axios = useAxios({ useAuth: false })
    return useQuery(lotteriesKeys.types(), () => fetchLotteryGameTypes({ axios }), {
        staleTime: Infinity
    })
}

const useLotteryLinesById = () => {
    const axios = useAxios()
    const { getSearchParams } = useSetSearchParams(false)
    const { ticketId } = useParams()

    const ticketIdkey = lotteriesKeys.lines({
        page: getSearchParams.page || '1',
        limit: getSearchParams.limit,
        id: ticketId
    })

    return useQuery(
        ticketIdkey,
        () => fetchLotteryLines({ axios, ticketId, params: getSearchParams }),
        {
            keepPreviousData: true,
            enabled: !!ticketId
        }
    )
}

/**
 * Get user lottery bets
 */
const useLotteryTicketsQuery = () => {
    const axios = useAxios()
    const { getSearchParams } = useSetSearchParams()

    // Page config
    const ticketkeys = lotteriesKeys.tickets({
        filters: getSearchParams.dataFilters || false,
        page: getSearchParams.page || '1',
        limit: getSearchParams.limit
    })

    return useQuery(ticketkeys, () => fetchLotteryTickets({ axios, params: getSearchParams }), {
        keepPreviousData: true
    })
}

const useLotteryWinners = () => {
    const axios = useAxios()
    const { getSearchParams } = useSetSearchParams()

    // Page config
    const winnerskey = lotteriesKeys.winners({
        filters: getSearchParams.dataFilters || false,
        page: getSearchParams.page || '1',
        limit: getSearchParams.limit
    })

    return useQuery(winnerskey, () => fetchLotteryWinners({ axios, params: getSearchParams }), {
        keepPreviousData: true
    })
}

const useLotteryTicketQuery = () => {
    const axios = useAxios()
    const { ticketId } = useParams()

    const dataFilters = JSON.stringify([
        {
            fieldNames: ['id'],
            comparators: ['='],
            values: [[ticketId]]
        }
    ])

    const ticketIdkey = lotteriesKeys.ticket({
        id: ticketId
    })

    return useQuery(ticketIdkey, () => fetchLotteryTickets({ axios, params: { dataFilters } }), {
        keepPreviousData: true
    })
}

// Lottery line redeem / payout
const useRedeemMutation = () => {
    const axios = useAxios()
    const queryClient = useQueryClient()
    const { getSearchParams } = useSetSearchParams()
    const page = getSearchParams.page || 1
    const limit = getSearchParams.limit
    const filters = getSearchParams.dataFilters || false
    const { ticketId } = useParams()
    const id = ticketId

    return useMutation(({ values }) => postTicketRedeem({ id, body: values, axios }), {
        onSuccess: async () => {
            queryClient.invalidateQueries(lotteriesKeys.ticket({ id }))
            queryClient.invalidateQueries(lotteriesKeys.lines({ id, limit, page }))
            queryClient.invalidateQueries(lotteriesKeys.winners({ page, limit, filters }))


        }
    })
}

const useDepositMutation = () => {
    const axios = useAxios()
    const queryClient = useQueryClient()
    const { getSearchParams } = useSetSearchParams()
    const page = getSearchParams.page || 1
    const limit = getSearchParams.limit
    const filters = getSearchParams.dataFilters || false
    const { ticketId } = useParams()
    const id = ticketId

    return useMutation(({ values }) => postTicketDeposit({ id, body: values, axios }), {
        onSuccess: () => {
            queryClient.invalidateQueries(lotteriesKeys.ticket({ id }))
            queryClient.invalidateQueries(lotteriesKeys.lines({ id, limit, page }))
            queryClient.invalidateQueries(lotteriesKeys.winners({ page, limit, filters }))
        }
    })
}

/**
 * Subscribe to draws data returned
 */
const useLotteriesPreviousDraws = () => useLotteriesQuery(transformPreviousDraws)
const useLotteriesCombinedPreviousDraws = () => useLotteriesCombinedQuery(transformPreviousDraws)

/**
 * Subscribe to a single lottery
 * @param {Number} id
 * @returns
 */
const useLotteriesById = (id) => useLotteriesQuery((data) => transformLotteryById(data, id))
const useLotteriesCombinedById = (id) =>
    useLotteriesCombinedQuery((data) => transformLotteryById(data, id))

/**
 * Export our hooks
 */
export {
    useDepositMutation,
    useRedeemMutation,
    useLotteriesQuery,
    useLotteryGamesQuery,
    useLotteriesPreviousDraws,
    useLotteryTicketQuery,
    useLotteryLinesById,
    useLotteryGameTypes,
    useLotteriesById,
    useLotteriesCombinedPreviousDraws,
    useLotteryQuery,
    useLotteriesCombinedById,
    useLotteryTicketsQuery,
    useLineMetaQuery,
    useLotteryWinners,
    lotteriesKeys,
    useSendWinTicket
}

/**
 * For information on use select and subscribing to parts of state
 * https://tkdodo.eu/blog/react-query-data-transformations
 */

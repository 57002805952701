import { connect } from "react-redux"
import { Box, Text, Button } from "@chakra-ui/react"
import Icon from "component/UI/Icon/Icon"

//Load helpers
import { useLanguageContext } from "component/Translations/Translations"

const MaxLinesHelp = ({ data, closeModal }) => {
    const { getText } = useLanguageContext()
    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems={{ base: "flex-start", md: "center" }}>
                <Box>
                    <Icon icon="info" />
                </Box>
                <Text fontSize="lg" ml={2} display="inline-block" fontWeight="bold">
                    {getText('global.sentence.max10Lines', 'Maximum number of 10 lines selected')}
                </Text>
            </Box>
            <Box layerStyle="modalActionButtonPlacement">
                <Button id="updatePassword" size="lg" borderRadius="0" layerStyle="modalActionButton" onClick={closeModal}>
                    {getText("global.button.close", "Close")}
                </Button>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state) => ({
    data: state.modal.data,
})

export default connect(mapStateToProps)(MaxLinesHelp)
export const dateHelper = (date) => {

  const months = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "Aug",
    "09": "Sept",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
  }
  if (date) {
    let month = date.slice(5, 7)
    month = months[month]
    let day = date.slice(8, 10)
    let time = date.slice(11, 16)
    return {
      month,
      day,
      time
    }
  }
}

/**
 * Base on selected language return the iso code
 * Allow to change date/time from browser default
 * to user preference
 * 
 * If language choosen not supported set undefined allowing
 * browser to set locale
 * @param {String} language 
 * @returns {String} 
 */
export const getLanguageISO = (language) => {
  switch (language) {
    case 'en':
      return 'en-US'
    case 'es':
      return 'es-DO'
    default:
      return undefined
  }
}

export const currentDate = () => {

  // Available months
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"]

  let currentDate = new Date(),
    currentDay = currentDate.getDate(),
    currentMonth = monthNames[currentDate.getMonth()],
    currentYear = currentDate.getFullYear()



  return `${currentDay} ${currentMonth} ${currentYear}`
}

export const orderByDate = (dates) => {
  return dates.sort(function (a, b) {
    return new Date(b.gmtDrawTime) - new Date(a.gmtDrawTime);
  })
}

/**
 * JS date format to user locale
 * 
 *
 * @param {Date | string | number} dateSrc
 * @return {date: string, time: string}
 */

const ds = 'short'
const ts = 'short'
export const formatDateTime = ({ dateSrc, dateStyle = ds, timeStyle = ts, timeOptions, dateOptions, timeZone = undefined, hideDisplay = false } = {}) => {
  const date = new Date(dateSrc)
  const inValid = typeof date !== 'object' || isNaN(date.getTime())

  const time = timeOptions ? timeOptions
    : { timeStyle }

  const dateSet = dateOptions ? dateOptions
    : { dateStyle }

  if (inValid) {
    return {
      date: null,
      time: null
    }
  }

  if (hideDisplay && date.getFullYear() <= '2000') {
    return {
      date: null,
      time: null
    }
  }

  const dateFormat = new Intl.DateTimeFormat(timeZone, {
    ...dateSet,
  })
  const timeFormat = new Intl.DateTimeFormat(timeZone, {
    ...time
  })

  return { date: dateFormat.format(date), time: timeFormat.format(date) }
}


/**
 * Used for standardised date and time concat
 * 
 * Expects date and time to be values as given from formatDateTime
 * 
 * If desired, could for example change/add to (date, time, {showSec = true})
 * to give the option of disabling seconds
 * 
 * Could add check for valid Date string/object
 * 
 * @example concatDateTime(dateTimeFormatWrapper("2022-01-13T14:34:56"))
 * // 13/01/2022, 14:34:56
 * 
 * @param {{date: string, time: string}} dateTime
 * @return {*} 
 */
export const concatDateTime = (dateTime) => {
  if (!dateTime?.date || !dateTime?.time) {
    return null
  }
  return `${dateTime.date}, ${dateTime.time}`
}
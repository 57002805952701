/**
 * Global styles setup
 */
const styles = {
  global: {
    "html, body": {
      color: "primaryTextColor",
      background: "backgroundPrimary",
      overflowX: "hidden",
    },
    "#root": {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    h1: {
      fontSize: "xl",
      fontWeight: "semibold",
      color: "primaryHeaderColor",
      mb: 4,
    },
    h2: {
      fontSize: "md",
      fontWeight: "bold",
      color: "primaryHeaderColor",
    },
    h3: {
      fontWeight: "bold",
    },
    p: {
      fontSize: "sm",
      lineHeight: "base",
      fontWeight: "medium",
      color: "primaryTextColor",
    },
    a: {
      _hover: {
        textDecoration: "none !important",
      },
    },
    "::-ms-reveal": {
      display: "none",
    },
  },
}

export default styles

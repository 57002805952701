import React from 'react'
import { useNavigate, useLocation, NavLink as ReachLink } from 'react-router-dom'

// Load components
import { Select, Box, Link } from '@chakra-ui/react'
import { useLanguageContext } from 'component/Translations/Translations'

const options = [
    {
        label: { key: 'account.subMenuTitle.addFunds', value: 'Add Funds' },
        value: '/account/add-funds'
    },
    {
        label: { key: 'account.subMenuTitle.withdraw', value: 'Withdraw Funds' },
        value: '/account/withdraw'
    },
    { label: { key: 'account.subMenuTitle.summary', value: 'Account Summary' }, value: '/account' },
    {
        label: { key: 'account.subMenuTitle.messages', value: 'Messages' },
        value: '/account/messages'
    },
    {
        label: { key: 'account.subMenuTitle.details', value: 'Your Details' },
        value: '/account/details'
    },
    {
        label: { key: 'account.subMenuTitle.responsibleGambling', value: 'Responsible Gambling' },
        value: '/account/responsible-gambling'
    },
    {
        label: { key: 'account.subMenuTitle.tickets', value: 'Lottery Tickets' },
        value: '/account/lottery'
    },
    {
        label: { key: 'account.subMenuTitle.winningTickets', value: 'Winning Tickets' },
        value: '/account/winning/lines'
    },
    {
        label: { key: 'account.subMenuTitle.transactions', value: 'Transactions' },
        value: '/account/transactions'
    }
]

export const MobileSubMenu = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { getText } = useLanguageContext()

    /**
     * Dirty solution to handle mobile page that is deep linked and not navigatable
     */
    const hideDropdown = location.pathname.startsWith('/account/lottery/ticket')

    return hideDropdown ? (
        <Box
            width="100%"
            textAlign="center"
            background="white"
            py={1}
            px={2}
            borderRadius="3xl"
            border="1px solid"
            borderColor="primary.900"
        >
            <Link
                as={ReachLink}
                to={'/account/lottery'}
                cursor="pointer"
                color="primary.900"
                display="flex"
                alignItems="center"
                fontSize="md"
                textAlign="center"
                justifyContent="center"
                textStyle="semiBoldFontFamily"
                p={2}
                _hover={{ backgroundColor: 'primary.700' }}
                _focus={{ outline: 'none' }}
            >
                {getText('account.button.viewTicket', 'View Tickets')}
            </Link>
        </Box>
    ) : (
        <Select
            value={location.pathname}
            id="mobileNav"
            name="mobileNav"
            borderColor="primary.900"
            color="primary.900"
            backgroundColor="white"
            textStyle="semiBoldFontFamily"
            onChange={({ target: { value } }) => navigate(value)}
        >
            {options.map((option, index) => {
                return (
                    <option key={index} index={index} value={option.value}>
                        {getText(option.label.key, option.label.value)}
                    </option>
                )
            })}
        </Select>
    )
}

export default MobileSubMenu

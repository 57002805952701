import axios from "axios"

// Load helpers
import { getRefresh } from "./jwt"

// Default instance without auth
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  params: {
    key: process.env.REACT_APP_API_KEY,
  },
})

function handleError(error) {
  // The request was made and the server responded with a status code
  // that falls out of the range of 2xx
  if (error.response) {
    // The data form the response to bubble up
    const res = {
      status: error.response.status,
      ...error.response.data,
    }
    return Promise.reject(res)
  }

  // Do something with response error
  return Promise.reject(error)
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  handleError
)

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  handleError
)

// Intercept and refresh expired tokens for multiple requests (same implementation but with some abstractions)
//
// HOW TO USE:
// import useAxios from 'hooks/useAxios';
// import axios from 'axios';
// ...
// tokenInterceptorClient(axios); // register the interceptor with all axios instance
// ...
// - With custom options:
// applyAppTokenRefreshInterceptor(apiClient, {
//      shouldIntercept: (error) => {
//          return error.response.data.errorCode === 'EXPIRED_ACCESS_TOKEN';
//      }
// );

/**
 * Intercept when we get back token expired
 * Internal code : ERR20038
 * status code: 401
 * @param {Object} error
 * @returns {Bool}
 */
const shouldIntercept = (error) => {
  try {
    // Expired token code
    if (error.response.data.code === "ERR20038" || error.response.data.code === "ERR20003") {
      return true
    }

    return error.response.status === 401
  } catch (e) {
    return false
  }
}

const shouldLogout = (error) => {
  try {
    // Expired token code
    if (error.response.data.code === "PART.ERR71004") {
      return true
    }

    return error.response.data.errror === "Missing or invalid API key provided"
  } catch (e) {
    return false
  }
}

// Handle attempt to refresh and return token
const handleTokenRefresh = (token) => {
  const refreshToken = getRefresh(token)
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("user/auth/token", { refreshToken })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

// Attach token to request header
const attachTokenToRequest = (request, token) => {
  request.headers["Authorization"] = `Bearer ${token}`

  // If there is an edge case where access token is also set in request query,
  // this is also a nice place to add it
  // Example: /orders?token=xyz-old-token
  // if (/\/orders/.test(request.url)) {
  //     request.params.token = token
  // }
}

const tokenInterceptorClient = (axiosClient, customOptions = {}) => {
  let isRefreshing = false
  let failedQueue = []

  const options = {
    attachTokenToRequest,
    handleTokenRefresh,
    shouldIntercept,
    shouldLogout,
    ...customOptions,
  }

  const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error)
      } else {
        prom.resolve(token)
      }
    })

    failedQueue = []
  }

  const interceptor = (error) => {
    /**
     * Catch missing API ket
     */
    if (options.shouldLogout(error)) {
      return options.tokenExpired()
    }
    if (!options.shouldIntercept(error)) {
      return handleError(error)
    }

    if (error.config._retry || error.config._queued) {
      return handleError(error)
    }

    const originalRequest = error.config
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      })
        .then((token) => {
          originalRequest._queued = true
          options.attachTokenToRequest(originalRequest, token)
          return axiosClient.request(originalRequest)
        })
        .catch(() => {
          return handleError(error) // Ignore refresh token request's "err" and return actual "error" for the original request
        })
    }

    originalRequest._retry = true
    isRefreshing = true
    return new Promise((resolve, reject) => {
      options.handleTokenRefresh
        .call(options.handleTokenRefresh, options.token)
        .then((tokenData) => {
          options.setTokenData(tokenData)
          options.attachTokenToRequest(originalRequest, tokenData.token)
          processQueue(null, tokenData.token)
          resolve(axiosClient.request(originalRequest))
        })
        .catch((err) => {
          processQueue(err, null)
          reject(err)

          // Refresh has failed then trigger logout
          options.tokenExpired()
        })
        .finally(() => {
          isRefreshing = false
        })
    })
  }

  axiosClient.interceptors.response.use(undefined, interceptor)
}

export { tokenInterceptorClient, axiosInstance }

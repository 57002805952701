const pageMainTitle = {
  color: "white",
}

const withdrawLegalText = {
  color: "white",
}

const textStyles = {
  pageMainTitle,
  withdrawLegalText,
}

export default textStyles

import React from 'react'

// FE Library
import { useLanguageContext } from 'component/Translations/Translations'
// Import Chakra
import { Text } from '@chakra-ui/react'

const CustomText = ({ tag, text = null, data = {}, children, scope = 'global', ...otherProps }) => {
    const { getText } = useLanguageContext()

    const content = tag ? getText(`${scope}.${tag}`, `${text}`, data) : text

    return (
        <Text {...otherProps}>
            {content}
            {children}
        </Text>
    )
}

export default CustomText

import React, { Component, createContext, useContext } from "react"

// Load helper
import cache from 'helper/cache'

/**
 * ## Environment Variables
 *
 * - REACT_APP_API_KEY
 * - REACT_APP_API_URL
 * - REACT_APP_LANGUAGE
 * ## Language Provider
 *
 * Must be used to provide context to any consuming components
 *
 * ## withLanguageContext
 *
 * HOC which provides components with the getText method
 *
 * ## useLanguageContext
 *
 * Hook which provides components with the getText method
 */
const apiKey = process.env.REACT_APP_API_KEY
const apiBase = process.env.REACT_APP_API_URL
const defaultLanguage = process.env.REACT_APP_LANGUAGE ? process.env.REACT_APP_LANGUAGE : "en"
const version = '1.0.0'

// Context to provide to consumers
const LanguageContext = createContext()
const LanguageGetTextContext = createContext()

let urlParams = {}

try {
  urlParams = new URLSearchParams(document.location.search)
} catch (error) {
  urlParams.get = () => false
}

// store translations localstorage
function getLocalItem(scope, language) {
  try {

    // Add previouse version if need to force clear old trans
    const previousVersion = cache.get(`translations-${scope}-${language}`)

    // Check if we have a previous version - NOTE - Add version variable one passed 1.0.0
    if (previousVersion) {
      // clear that version
      cache.remove(`translations-${scope}-${language}`)
    }

    const translationData = cache.get(`translations-${scope}-${language}-${version}`)
    const translationJson = translationData
    const error = { error: "No translation data" }

    // Check we could retrieve some data
    if (!translationJson) {
      return error
    }

    // Return the translations we have
    return translationJson
  } catch (error) {
    console.log("Unable to use localStorage:translations: " + error)
  }
}

// retrieve translations form local storage
function setLocalItem(scope, data, language) {
  const now = new Date()

  // For development, we can have a tiny TTL because the API is heavily cached anyway
  let ttl = 60 * 5 * 1000

  // Set expiry for 7 days in prod
  // Once all translations are in, this can be raised in production
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    ttl = 7 * 24 * 60 * 60 * 1000 // 7 days
  }


  const translations = { translations: data, expiry: now.getTime() + ttl, language }

  try {
    cache.set(`translations-${scope}-${language}-${version}`, translations, translations.expiry)
  } catch (error) {
    console.log("Unable to use localStorage:translations: " + error)
  }
}

// Context Provider
class LanguageProvider extends Component {
  constructor(props) {
    super(props)

    // See if we have preloaded state
    const translationsJson = getLocalItem(this.props.scope, this.props.language || defaultLanguage)
    this.state = {
      language: this.props.language || defaultLanguage,
      translations: translationsJson?.translations ?? {},
      isLoading: false,
    }
  }

  componentDidMount() {
    // Get local translations
    const translationsJson = getLocalItem(this.props.scope, this.state.language)

    // Check we can load some translations from local storage
    if (translationsJson?.translations) {
      const now = new Date()

      // Check if its time refresh our stored translations
      if (now.getTime() > translationsJson.expiry) {
        return this._requestTranslations(this.state.language)
      }

      // We can load the translations we have
      return this.setState({ translations: translationsJson.translations })
    }

    // We needs to fetch initial transalations
    return this._requestTranslations(this.state.language)
  }

  componentDidUpdate(prevProps) {
    const { language } = this.props

    // Language has changed from previous
    if (prevProps.language !== language) {
      // Run our change language method
      return this._changeLanguage(language)
    }
  }

  // Exposed via context to consumers for text translations
  _getText = (key, text, parameters = {}) => {
    const { translations, isLoading } = this.state

    if (urlParams.get("showKeys")) {
      return key
    }

    if (typeof text === "undefined") {
      return key
    }

    if (typeof text !== "string") {
      return key
    }

    // Early return the text value until attempted to load translation from API
    if (isLoading) {
      return this._parseParams(text, parameters)
    }

    let translationKeys = {}

    if (translations) {
      translationKeys = translations
    }

    // Do we have any new keys
    if (typeof translationKeys[key] === "undefined") {
      // We have a new key to add
      //this._addKey({ key, text })

      // Return the key itself
      if (text) {
        return this._parseParams(text, parameters)
      }

      return key
    }

    return this._parseParams(translationKeys[key], parameters)
  }

  _parseParams = (cleanText, parameters = {}) => {
    // Did we get some parameters back?
    if (Object.keys(parameters).length > 0) {
      Object.keys(parameters).forEach((key) => {
        cleanText = cleanText.replace(`{${key}}`, parameters[key])  //cleanText.replaceAll("{" + key + "}", parameters[key])
      })
    }

    return cleanText
  }

  _changeLanguage = (language) => {
    const translationsJson = getLocalItem(this.props.scope, language)

    // We have the language stored load it up
    if (translationsJson.translations) {
      const now = new Date()

      // Check if its time refresh our keys
      if (now.getTime() > translationsJson.expiry) {
        // Begin a refresh
        return this._requestTranslations(language)
      }

      // We can update state with our local keys
      return this.setState({ translations: translationsJson.translations, language })
    }

    // Let start a request for the chosen language
    return this._requestTranslations(language)
  }

  // We are adding a new text key to translations
  // _addKey = async (translation) => {
  //   // Destructure our data
  //   const {
  //     state: { language, translations },
  //     props: { scope },
  //   } = this

  //   if (Object.keys(translations).length <= 1) {
  //     return console.log("First Init skip posting keys as getText runs before loading trans")
  //   }

  //   try {
  //     // Add our key
  //     const request = await fetch(`${apiBase}/translations/key?key=${apiKey}&language=${language}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(translation),
  //     })

  //     let newTranslation = {}

  //     // If we are 201, we were created so we don't get a payload back
  //     if (request.status === 201) {
  //       newTranslation[translation.key] = translation.text
  //     } else {
  //       // Otherwise we can parse the JSON response
  //       const response = await request.json()

  //       // We got an ID back, handle it
  //       if (typeof response.id !== "undefined") {
  //         newTranslation[response.key] = response.text
  //       }
  //     }

  //     // Check we added the key successfully
  //     if (Object.keys(newTranslation).length < 1) {
  //       return console.log(`Did not successfully add key: ${translation.key}`)
  //     }

  //     const updatedTranslations = { ...this.state.translations }
  //     updatedTranslations[translation.key] = translation.text

  //     // Store the translations + language
  //     this.setState({ translations: updatedTranslations, language })

  //     // Update local storage with new keys from state + new added key
  //     setLocalItem(scope, updatedTranslations, language)
  //   } catch (error) {
  //     console.log(`Post-Key-${language}: ${translation.key}  Error:${error}`)
  //   }
  // }

  // Makes a fetch request for translations
  _requestTranslations = async (language) => {
    const { scope } = this.props
    this.setState({ isLoading: true })
    try {
      if (scope === undefined) {
        return console.log("Error: Scope missing!")
      }

      // Make the request to our desired scope
      const request = await fetch(`${apiBase}/translations/list?key=${apiKey}&scope=${scope}&language=${language}`)
      const response = await request.json()

      // Throw an error
      if (response.error) {
        return console.log("Error: fetching translations" + response.error)
      }

      let translations = {}

      // We have some data back
      if (response.data.length > 0) {
        // Loop over our keys we got back
        response.data.forEach((data) => {
          translations[data.key] = data.text
        })

        //console.log("Setting translations:", Object.keys(translations).length, language)

        // Store the translations + language
        this.setState({ translations, language })

        // Update local storage with our translations response
        setLocalItem(scope, translations, language)
        return
      }
    } catch (error) {
      console.log(error)
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render() {
    const {
      props: { children },
      state: { language, isLoading },
      _getText,
    } = this
    return (
      <LanguageContext.Provider value={language}>
        <LanguageGetTextContext.Provider value={{ getText: _getText, isLoading }}>{children}</LanguageGetTextContext.Provider>
      </LanguageContext.Provider>
    )
  }
}

// HOC to provide translarions. Will re-render on language change.
const withLanguageContext = (WrappedComponent) => {
  const LanguageConsumer = ({ ...otherProps }) => {
    // We always have context defined so we do not need to account for undefined
    const language = useContext(LanguageContext)
    const { getText, isLoading } = useContext(LanguageGetTextContext)

    if (getText === undefined) {
      throw new Error("withLanguageContext must be used within LanguageProvider")
    }
    return <WrappedComponent getText={getText} translationLoading={isLoading} language={language} {...otherProps} />
  }
  return LanguageConsumer
}

// Hook to provide translations: Alternate to hoc
function useLanguageContext() {
  // We always have context defined so we do not need to account for undefined
  const language = useContext(LanguageContext)
  let { getText, isLoading } = useContext(LanguageGetTextContext)

  // Return the key and ignore translating
  if (getText === undefined) {
    // Mock getText functionality
    getText = (key, text, parameters = {}) => {
      // Did we get some parameters back?
      if (Object.keys(parameters).length > 0) {
        Object.keys(parameters).forEach((key) => {
          text = text.replace(`{${key}}`, parameters[key])
        })
      }
      return text
    }
  }

  return { language, getText, isLoading }
}

// export consumer functions
export { withLanguageContext, useLanguageContext, LanguageProvider }
import { useState } from 'react'

// Load helper
import useAxios from './useAxios'
import { useDispatch } from 'react-redux'
import { openModal } from 'redux/modal/Modal.actions'
import modalConstants from 'redux/modal/Modal.constants'
//import { useTurnstile } from 'react-turnstile'

/**
 * Handle onSubmit event for login 
 * @param {String} loginType 
 */
export const useLogin = (loginType = 'web') => {
    const [hasError, setError] = useState(false)
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const axios = useAxios({ useAuth: false })
    //const turnstile = useTurnstile()

    /**
     * Loging Submission Handler
     * 
     * Form Body 
     * username // email
     * password
     * @param {Object} form
     */
    async function onSubmitLogin(form) {

        const { token, ...rest } = form

        // Reset any api errors we had
        setError(false)

        try {
            const login = await axios.post('user/auth',
                {
                    ...rest,
                    loginType
                },
                {
                    params: {
                        'captcha': token
                    }
                }
            )

            if (login.data.userVerificationRequired) {
                return dispatch(openModal(
                    modalConstants.MODAL_LOGIN_OTP,
                    {
                        ...login.data,
                        email: form.email
                    },
                    {
                        closeOnEsc: false,
                        closeOnOverlayClick: false
                    }
                ))
            }

            // Did we get an error back on login?
            if (login.data.error) {

                // Set Error in state
                return setError(login)
            }

            // Using the token request the player
            const user = await axios.get('player/me', {
                headers: { "Authorization": `Bearer ${login.data.token}` }
            })

            // Check if we got an error back?
            if (user.data.error) {
                // Set the error
                return setError(user.data)
            }

            // Reurn the token and user
            return setData({
                token: login.data.token,
                user: user.data
            })

        } catch (error) {
            //turnstile.reset()
            // set api error
            setError(error)
        }

    }

    async function onSubmitLoginMFA(form) {

        if (isLoading) {
            return
        }

        // Reset any api errors we had
        setError(false)
        setIsLoading(true)

        try {
            const login = await axios.post('user/auth/otp/verify',
                {
                    ...form
                })

            // Did we get an error back on login?
            if (login.data.error) {

                // Set Error in state
                return setError(login)
            }

            // Using the token request the player
            const user = await axios.get('player/me', {
                headers: { "Authorization": `Bearer ${login.data.token}` }
            })

            // Check if we got an error back?
            if (user.data.error) {
                // Set the error
                return setError(user.data)
            }

            // Reurn the token and user
            return setData({
                token: login.data.token,
                user: user.data
            })

        } catch (error) {
            // set api error
            setError(error)
        } finally {
            setIsLoading(false)
        }

    }

    return {
        onSubmitLogin,
        onSubmitLoginMFA,
        hasError,
        isLoading,
        data
    }
}
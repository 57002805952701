import * as yup from 'yup'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { axiosInstance as axios } from '../helper/axios'

/**
 * Age validation helper
 */
export const getAge = (birthdayString) => {
    const today = new Date()
    const birthday = new Date(birthdayString)
    let age = today.getFullYear() - birthday.getFullYear()
    const month = today.getMonth() - birthday.getMonth()

    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
        age--
    }
    return age
}

export async function validateField(field = 'email', value) {
    try {
        const response = await axios.get('user', {
            params: {
                [field]: value
            }
        })

        return response.data.available
    } catch (error) {
        return error
    }
}

async function validateDocNumber(value) {
    try {
        const body = {
            documentNumber: value
        }
        const response = await axios.post('user/document/validation', body)

        return response.data.status
    } catch (err) {
        return err
    }
}

export const loginOTPSchema = yup.object().shape({
    otp: yup.string().required('Please enter OTP code').length(6)
})

export const loginSchema = yup.object().shape({
    email: yup
        .string()
        .email({
            code: 'ERR20036',
            error: 'Invalid Email Address'
        })
        .required({
            code: 'ERR20',
            error: 'Email is required'
        }),
    password: yup.string().required({
        code: 'ERR20029',
        error: 'Password is required'
    })
})

export const resetPassword = yup.object().shape({
    email: yup
        .string()
        .email({
            code: 'ERR20036',
            error: 'Invalid Email Address'
        })
        .required({
            code: 'ERR20',
            error: 'Email is required'
        })
})

export const passwordChange = yup.object().shape({
    password: yup
        .string()
        .matches(
            /^.*(?=.{8,})((?=.*[!?@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least 8 characters, one uppercase, one number and one special case character'
        )
        .required({
            code: 'ERR22',
            error: 'Password is required'
        }),
    confirmPassword: yup
        .string()
        .test('passwords-match', 'Passwords do not match', function (value) {
            return this.parent.password === value
        })
})

export const emailPasswordSchemaMemoized = () => {
    let previousEmail = ''
    let emailStatus = false
    return yup.object().shape({
        // Only validates if the new value differs
        email: yup
            .string()
            .email({
                code: 'ERR20036',
                error: 'Invalid Email Address'
            })
            .required({
                code: 'ERR20',
                error: 'Email is required'
            })
            .test(
                'email',
                {
                    error: 'Duplicate email',
                    code: 'ERR20010'
                },
                async (value, context) => {
                    if (value === previousEmail) {
                        return emailStatus
                    }
                    previousEmail = value
                    const email = await validateField('email', value)
                    if (email.error) {
                        emailStatus = context.createError({
                            message: email
                        })
                        return emailStatus
                    }
                    emailStatus = email
                    return emailStatus
                }
            ),
        password: yup
            .string()
            .matches(
                /^.*(?=.{8,})((?=.*[!?@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                'Password must contain at least 8 characters, one uppercase, one number and one special case character'
            )
            .required({
                code: 'ERR22',
                error: 'Password is required'
            }),
        confirmPassword: yup
            .string()
            .test('passwords-match', 'Passwords do not match', function (value) {
                return this.parent.password === value
            })
    })
}

export const validateDocNo = () => {
    let previousDocNo = ''
    let status = false

    return yup.object().shape({
        documentNo: yup
            .string()
            .required({
                code: 'Error.ERR10036',
                error: 'Missing Document Number'
            })
            .test(
                'documentNo',
                {
                    error: 'Invalid document number', // This error is return when the below returns false
                    code: 'Error.ERR20062'
                },
                async (value, context) => {
                    // Value has not changes return current status
                    if (value === previousDocNo) {
                        return status
                    }

                    // Assign new value and validate
                    previousDocNo = value
                    const docNo = await validateDocNumber(value)

                    if (docNo.error) {
                        status = false
                        return status
                    }

                    // Return updated status
                    status = docNo
                    return status
                }
            )
    })
}

export const personalDetailsSchema = yup.object().shape({
    firstName: yup
        .string()
        .matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Invalid First Name")
        .required('Missing First Name'),
    lastName: yup
        .string()
        .matches(/^[^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/, "Invalid Last Name")
        .required('Missing Last Name'),
    year: yup.string().required('Year required'),
    month: yup.string().required('Month required'),
    day: yup.string().required('Day required'),
    dob: yup.string().test('min-age', 'Min age 18', function () {
        const age = getAge(`${this.parent.year}-${this.parent.month}-${this.parent.day}`)
        return age >= 18
    }),
    phoneNumberCode: yup.string(),
    phoneNumber: yup
        .string()
        .required({
            code: 'REG.ERR07',
            error: 'Phone Required'
        })
        .test(
            'validate-phonenumber',
            {
                code: 'REG.ERR08',
                error: 'Invalid phone number'
            },
            (value, context) => {
                const isValid = value ? isPossiblePhoneNumber(value) : false
                if (!isValid) {
                    return context.createError({
                        message: {
                            code: 'REG.ERR08',
                            error: 'Invalid phone number'
                        }
                    })
                }
                return isValid
            }
        )
})

export const addressSchema = yup.object().shape({
    line1: yup.string().required({ code: 'ERR10013', error: 'Line 1 required' }),
    line2: yup.string(),
    city: yup.string().required('City required'),
    postcode: yup.string().required('Postcode required'),
    countryIso: yup.string().required('Country required'),
    terms: yup.bool().isTrue().required('Must accept Terms to register')
})

export const transactionsSchema = yup.object().shape({
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
    status: yup.string().oneOf(['Authorised', 'Pending', 'Cancelled', 'notset']),
    typeId: yup.string().oneOf(['1', '2', '3', '4', '5', '6', '7', '8', 'notset'])
})

export const lotteriesSchema = yup.object().shape({
    drawStartDate: yup.date().nullable(),
    drawEndDate: yup.date().nullable(),
    status: yup.string().oneOf(['win', 'lost', 'pending', 'rejected', 'notset']),
    gameId: yup
        .string()
})

export const winnersSchema = yup.object().shape({
    status: yup.string().oneOf(['redeemed', 'paid', 'unclaimed', 'notset'])
})

export const exclusionSuspensionSchema = yup.object().shape({
    duration: yup.string(),
    suspended: yup.bool(),
    excluded: yup.bool(),
    password: yup.string().required({ code: 'ERR22', error: 'Password is required' })
})

import { REQUEST_USER_FAIL, REQUEST_USER_START, REQUEST_USER_SUCCESS, USER_BALANCE_SET, USER_BALANCE_UPDATE_START, USER_LANGUAGE_SET, USER_LOGIN, USER_LOGOUT, USER_TOKEN_SET } from "./User.constants"

export const requestUserStart = () => ({
  type: REQUEST_USER_START,
})

export const requestUserSuccess = (userProfile) => ({
  type: REQUEST_USER_SUCCESS,
  payload: userProfile,
})

export const requestUserFail = (error) => ({
  type: REQUEST_USER_FAIL,
  payload: error,
})

/**
 * {token: '', user: {}}
 * @param {Object} data
 */
export const userLogin = (data) => {
  return {
    type: USER_LOGIN,
    payload: data,
  }
}

/**
 * Set choosen language
 * @param {String} language
 * @returns
 */
export const setLanguage = (language) => {
  return {
    type: USER_LANGUAGE_SET,
    payload: language,
  }
}

export const requestLogout = () => ({
  type: USER_LOGOUT,
})

/**
 * Request the player/me for the balance update
 * @returns {Object}
 */
export const requestBalanceUpdate = () => ({
  type: USER_BALANCE_UPDATE_START,
})

/**
 * Given the balance, update our payload value
 * @param balance
 * @returns {Object}}
 */
export const setBalanceValue = ({ balance, winBalance }) => ({
  type: USER_BALANCE_SET,
  payload: {
    balance,
    winBalance,
  },
})

export const setUserToken = (token) => {
  return {
    type: USER_TOKEN_SET,
    payload: {
      token,
    },
  }
}

import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
    'stage',
    'ball',
    'tick',
    'number'
])

/**
 * @todo - Variants:
 * Outline
 * Dashed
 */
const baseStyle = definePartsStyle({
    stage: {
        perspective: '1200px',
        perspectiveOrigin: '50% 50%'
    },
    ball: {
        position: 'relative',
        borderRadius: '50%',
        overflow: 'hidden',
        p: {
            height: '100%'
        }
    },
    number: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontWeight: 'bold'
    },
    tick: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'lotteryTick.backgroundColor',
        borderRadius: '50%',
        fontSize: '10px',
        color: 'lotteryTick.iconColor'
    }
})

const variantOutline = definePartsStyle(() => {
    return {
        ball: {
            p: {
                "&[data-ball='regular']": {
                    color: 'lotteryBall.regular.600',
                    background: 'lotteryBall.regular.400',
                    borderColor: 'lotteryBall.regular.500'
                },
                "&[data-ball='bonus1']": {
                    color: 'lotteryBall.bonus1.600',
                    background: 'lotteryBall.bonus1.400',
                    borderColor: 'lotteryBall.bonus1.500'
                },
                "&[data-ball='bonus2']": {
                    color: 'lotteryBall.bonus2.600',
                    background: 'lotteryBall.bonus2.400',
                    borderColor: 'lotteryBall.bonus2.500'
                }
            }
        }
    }
})

const variantDashed = definePartsStyle(() => {
    return {
        ball: {
            p: {
                border: '1px dotted',
                background: 'transparent',
                "&[data-ball='regular']": {
                    color: 'lotteryBall.regular.300',
                    borderColor: 'lotteryBall.regular.200'
                },
                "&[data-ball='bonus1']": {
                    color: 'lotteryBall.bonus1.300',
                    borderColor: 'lotteryBall.bonus1.200'
                },
                "&[data-ball='bonus2']": {
                    color: 'lotteryBall.bonus2.300',
                    borderColor: 'lotteryBall.bonus2.200'
                }
            }
        }
    }
})

const variantSolid = definePartsStyle(() => {
    return {
        ball: {
            p: {
                "&[data-ball='not-selected']": {
                    background: 'lotteryBall.notSelected.100',
                    '& span': {
                        color: 'lotteryBall.notSelected.300'
                    }
                },
                "&[data-ball='selected']": {
                    background: 'lotteryBall.selected.100',
                    '& span': {
                        color: 'lotteryBall.selected.300'
                    }
                },
                "&[data-ball='selected-alt']": {
                    background: 'lotteryBall.selectedAlt.100',
                    '& span': {
                        color: 'lotteryBall.selectedAlt.300'
                    }
                },
                "&[data-ball='bonus1']": {
                    color: 'lotteryBall.bonus1.300',
                    background: 'lotteryBall.bonus1.100',
                    borderColor: 'lotteryBall.bonus1.200'
                },
                "&[data-ball='bonus2']": {
                    color: 'lotteryBall.bonus2.300',
                    background: 'lotteryBall.bonus2.100',
                    borderColor: 'lotteryBall.bonus2.200'
                }
            }
        }
    }
})

const variants = {
    outline: variantOutline,
    solid: variantSolid,
    dashed: variantDashed
}

const size = {
    lg: defineStyle({
        fontSize: 'md',
        h: '44px',
        w: '44px'
    }),
    md: defineStyle({
        fontSize: 'sm',
        h: '35px',
        w: '35px'
    }),
    sm: defineStyle({
        fontSize: 'xs',
        h: '25px',
        w: '25px'
    })
}

const sizes = {
    lg: definePartsStyle({
        ball: size.lg,
        tick: {
            top: '-2px',
            left: '30px',
            width: '18px',
            height: '18px'
        },
        number: {
            fontSize: size.lg.fontSize
        }
    }),
    md: definePartsStyle({
        ball: size.md,
        tick: {
            top: '-3px',
            left: '23px',
            width: '16px',
            height: '16px'
        },
        number: {
            fontSize: size.md.fontSize
        }
    }),
    sm: definePartsStyle({
        ball: size.sm,
        tick: {
            top: '-3px',
            left: '16px',
            width: '12px',
            height: '12px'
        },
        number: {
            fontSize: size.sm.fontSize
        }
    })
}

export const lotteryBallTheme = defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        variant: 'solid',
        size: 'md'
    }
})

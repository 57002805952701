import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { NavLink as ReachLink } from 'react-router-dom'

//Load components
import { LayoutContain } from 'template/LayoutBase.page'
import {
    Box,
    Alert,
    AlertIcon,
    AlertDescription,
    CloseButton,
    Collapse,
    Link
} from '@chakra-ui/react'
import { closeNotification } from '../../redux/notification/notification.actions'
import notificationConstants from '../../redux/notification/notification.constants'
import Icon from 'component/UI/Icon/Icon'

/**
 * Global notification component. Connected to redux and will display message sent from
 * `onOpenNotification` action
 *
 * Success message will auto clean up after 10 seconds, all other message require user to dismiss notifcation
 *
 * Status must be of the following
 *
 * - success
 * - warning
 * - error
 *
 */
const Notifcation = () => {
    const dispatch = useDispatch()
    const { message, status, navigate, allowDismiss } = useSelector((state) => state.notificationReducer)

    /**
     * Run timeout once when we receive a new message
     * Only run if it was successful
     * Let user dismiss manually if it was an error
     */
    useEffect(() => {
        //checkKyc({ kyc, dispatch, getText })

        if (message && status === notificationConstants.NOTIFICATION_SUCCESS) {
            const timer = setTimeout(() => {
                dispatch(closeNotification())
            }, 10000)

            // Remove timer when we unmount
            return () => clearTimeout(timer)
        }
    }, [dispatch, message, status])

    return (
        <Collapse in={message} unmountOnExit>
            <LayoutContain paddingX={{ base: 0 }} layerStyle="header">
                <Alert status={status || 'info'}>
                    <Box display="flex" width="100%" justifyContent="center">
                        <AlertIcon />
                        <AlertDescription>
                            <Box display="flex">
                                {message}

                                {navigate && (
                                    <Link ml={2} as={ReachLink} to={navigate} border="none">
                                        <Icon icon="link" />
                                    </Link>
                                )}
                            </Box>
                        </AlertDescription>
                    </Box>
                    {allowDismiss && <CloseButton onClick={() => dispatch(closeNotification())} />}
                </Alert>
            </LayoutContain>
        </Collapse>
    )
}

export default Notifcation

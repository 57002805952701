import { combineReducers } from "redux"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

// Load reducers
import help from "./Help/Help.reducer"
import rapid from "./Rapid/Rapid.reducer"
import user from "./User/User.reducer"
import bets from './Bets/Bets.reducer'
import rapidBets from './RapidBets/RapidBets.reducer'
import checkout from './Checkout/Checkout.reducer'
import modal from './modal/Modal.reducer'
import notificationReducer from './notification/notification.reducer'

// Read the version number
const version = process.env.REACT_APP_VERSION || "2.0.1",
    versionWithoutMinor = version.split(".").slice(0, -1).join(".")

const userConfig = {
    key: "user",
    storage,
    // Do not persists these keys - onMount if token valid we will reload and reauth
    blacklist: ["isAuthed", "id", "username"]
}

// Stores we should persist
const persistConfig = {
    storage,
    key: "lottery-v" + versionWithoutMinor,
    whitelist: ["user"],
}

const rootReducer = combineReducers({
    notificationReducer,
    modal,
    bet: bets,
    rapidBets,
    help,
    rapid,
    checkout,
    user: persistReducer(userConfig, user),
})

export default persistReducer(persistConfig, rootReducer)
// export default Button
import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system'
import { mode, transparentize } from '@chakra-ui/theme-tools'

const baseStyle = defineStyle({
    lineHeight: '1.2',
    borderRadius: 'buttonRadii',
    fontFamily: 'Heading Bold',
    textTransform: 'upperCase',
    transitionProperty: 'common',
    transitionDuration: 'normal',
    _focusVisible: {
        boxShadow: 'outline'
    },
    _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
        boxShadow: 'none'
    },
    _hover: {
        _disabled: {
            bg: 'initial'
        }
    }
})

const variantGhost = defineStyle((props) => {
    const { colorScheme: c, theme } = props

    if (c === 'gray') {
        return {
            color: mode(`inherit`, `whiteAlpha.900`)(props),
            _hover: {
                bg: mode(`gray.100`, `whiteAlpha.200`)(props)
            },
            _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) }
        }
    }

    const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme)
    //const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme)

    return {
        color: `${c}.500`,
        bg: 'transparent',
        _hover: {
            bg: mode(`${c}.50`, darkHoverBg)(props),
            color: `${c}.100`
        },
        _active: {
            bg: `${c}.700` //mode(`${c}.100`, darkActiveBg)(props)
        }
    }
})

const variantOutline = defineStyle((props) => {
    const { colorScheme: c } = props
    const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props)
    return {
        border: '1px solid',
        borderColor: c === 'gray' ? borderColor : `${c}.500`,
        '.chakra-button__group[data-attached] > &:not(:last-of-type)': {
            marginEnd: '-1px'
        },
        ...variantGhost(props)
    }
})

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap = {
    yellow: {
        bg: 'yellow.400',
        color: 'black',
        hoverBg: 'yellow.500',
        activeBg: 'yellow.600'
    },
    cyan: {
        bg: 'cyan.400',
        color: 'black',
        hoverBg: 'cyan.500',
        activeBg: 'cyan.600'
    },
    buttonPrimary: {
        color: 'buttonPrimary.100'
    },
    buttonNeutral: {
        color: 'buttonNeutral.100'
    }
}

const variantSolid = defineStyle((props) => {
    const { colorScheme: c } = props

    if (c === 'gray') {
        const bg = mode(`gray.100`, `whiteAlpha.200`)(props)

        return {
            bg,
            _hover: {
                bg: mode(`gray.200`, `whiteAlpha.300`)(props),
                _disabled: {
                    bg
                }
            },
            _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) }
        }
    }

    const {
        bg = `${c}.500`,
        color = `${c}.100`,
        hoverBg = `${c}.600`,
        activeBg = `${c}.700`
    } = accessibleColorMap[c] ?? {}

    const background = mode(bg, `${c}.200`)(props)

    return {
        bg: background,
        color: mode(color, `gray.800`)(props),
        _hover: {
            bg: mode(hoverBg, `${c}.300`)(props),
            _disabled: {
                bg: background
            }
        },
        _active: { bg: mode(activeBg, `${c}.400`)(props) }
    }
})

const variantLink = defineStyle((props) => {
    const { colorScheme: c } = props
    return {
        padding: 0,
        height: 'auto',
        lineHeight: 'normal',
        verticalAlign: 'baseline',
        color: mode(`${c}.500`, `${c}.200`)(props),
        _hover: {
            textDecoration: 'underline',
            _disabled: {
                textDecoration: 'none'
            }
        },
        _active: {
            color: mode(`${c}.700`, `${c}.500`)(props)
        }
    }
})

const variantUnstyled = defineStyle({
    bg: 'none',
    color: 'inherit',
    display: 'inline',
    lineHeight: 'inherit',
    m: '0',
    p: '0'
})

const variants = {
    ghost: variantGhost,
    outline: variantOutline,
    solid: variantSolid,
    link: variantLink,
    unstyled: variantUnstyled
}

const sizes = {
    lg: defineStyle({
        h: '12',
        minW: '12',
        fontSize: 'lg',
        px: '10'
    }),
    md: defineStyle({
        h: '10',
        minW: '10',
        fontSize: 'sm',
        px: '8'
    }),
    sm: defineStyle({
        h: '8',
        minW: '8',
        fontSize: 'xs',
        px: '6'
    }),
    xs: defineStyle({
        h: '7',
        minW: '6',
        fontSize: 'xxs',
        px: '4'
    })
}

export const buttonTheme = defineStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        variant: 'solid',
        size: 'md',
        colorScheme: 'buttonPrimary'
    }
})

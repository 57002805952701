import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

//Load hooks
import { openNotification } from '../../redux/notification/notification.actions'
import { usePlayerKycQuery } from 'hooks/queries/useKYC'
import { useLanguageContext } from 'component/Translations/Translations'

const checkKyc = ({ kycStatus, dispatch, getText }) => {
    let message = null
    let status = 'warning'
    let navigate = null

    if (kycStatus === 'rejected') {
        message = getText(
            'account.sentence.accountRejected',
            'We regret to inform you that we are unable to approve your account registration at this time. If you have any questions or need further assistance, please contact our customer support service team. Thank you for considering our services.'
        )
    }

    if (kycStatus === 'waiting_for_data') {
        message = getText(
            'account.sentence.weNeedToVerify',
            'We need to verify your account. Click here to verify your account.'
        )
        navigate = '/account'
    }

    if (kycStatus === 'waiting_for_review') {
        message = getText(
            'account.sentence.accountPendingVerification',
            'Your account is currently being verification processed by our customer account team.'
        )
    }

    // Stop if no status set
    if (message === null) {
        return
    }

    dispatch(
        openNotification({
            message,
            status,
            navigate,
            allowDismiss: false
        })
    )
}

/**
 * Global Login notification component. Connected to redux and will display message sent from
 */
const GlobalLoginNotifcation = () => {
    const dispatch = useDispatch()
    const { data, isError } = usePlayerKycQuery()
    const { getText, language } = useLanguageContext()

    const kycStatus = data?.[0]?.status ?? 'unavailable'

    /**
     * Sync with KYC state changes
     */
    useEffect(() => {
        if (!isError && kycStatus !== 'unavailable') {
            checkKyc({ kycStatus, dispatch, getText })
        }
    }, [kycStatus, dispatch, getText, isError, language])

    return null
}

export default GlobalLoginNotifcation

const modalConstants = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',

  // Types
  MODAL_VIEW_MESSAGE: 'MODAL_VIEW_MESSAGE',
  MODAL_UPDATE_EMAIL: 'MODAL_UPDATE_EMAIL',
  MODAL_UPDATE_CONTACT: 'MODAL_UPDATE_CONTACT',
  MODAL_UPDATE_PASSWORD: 'MODAL_UPDATE_PASSWORD',
  MODAL_EXCLUSION: 'MODAL_EXCLUSION',
  MODAL_SUSPENSION: 'MODAL_SUSPENSION',
  MODAL_SELECT_CARDS: 'MODAL_SELECT_CARDS',
  MODAL_DELETE_CARDS: 'MODAL_DELETE_CARDS',
  MODAL_REDEEM: 'MODAL_REDEEM,',
  MODAL_DEPOSIT: 'MODAL_DEPOSIT',
  MODAL_VIEW_TICKET: 'MODAL_VIEW_TICKET',
  MODAL_ACTIVATE_CARD: 'MODAL_ACTIVATE_CARD',
  MODAL_HELP_MAX_LINES: 'MODAL_HELP_MAX_LINES',
  MODAL_LOGIN_OTP: 'MODAL_LOGIN_OTP'
}

export default modalConstants

import { theme as baseTheme } from '@chakra-ui/react'

/**
 * Default base color theme
 * Override using partner theme do not change here
 *
 * Colour Mapping
 *
 * https://mdigi.tools/lighten-color
 * https://mdigi.tools/darken-color
 *
 * Light/Dark shades for buttons hover/active states
 * X00 : Default base color
 * X25 : Light shade of base color (20%)
 * X50 : Dark shade of base color (30%)
 */
const colourList = {
    ...baseTheme.colors,
    primary: {
        900: '#09A55E',
        925: '#0bc570',
        950: '#067342',
        800: '#4071AF',
        825: '#608dc5',
        850: '#376196',
        700: '#CBD9EB',
        725: '#d5e1ef',
        750: '#739bca'
    },
    secondary: {
        900: '#f5e50a',
        925: '#f7ea3b',
        950: '#cec008',
        800: '#FFC15F'
    },
    tertiary: {
        900: '#cecdf3'
    },
    success: {
        900: '#8EC434'
    },
    error: {
        900: '#AD1700',
        925: '#f02000',
        950: '#791000'
    },
    neutral: {
        900: '#07181C',
        800: '#808080',
        700: '#F5F5F5',
        600: '#EFF0F1',
        500: '#f0f9f6'
    },
    textBody: '#151515',
    background: '#09A55E'
}

const colors = {
    ...colourList,

    /**
     * Border colors
     */
    borderPrimary: colourList.primary[900],
    borderPrimaryLight: colourList.primary[700],
    borderInsideBetslip: 'white',

    /**
     * Icon colors
     */
    iconPrimary: colourList.primary[900],

    /**
     * Typograghy colors
     */
    primaryTextColor: colourList.textBody,
    primaryLinkColor: colourList.primary[900],
    primaryHeaderColor: colourList.textBody,
    backgroundPrimary: colourList.background,
    drawCountdown: colourList.red[500],

    /**
     * Account Menu colors
     */
    accountMenu: {
        headerBackground: colourList.primary[900],
        headerTextColor: 'white',
        itemBackground: 'white',
        itemTextColor: colourList.textBody,
        itemBorderColor: colourList.primary[700]
    },

    /**
     * Betslip colors
     */
    betslip: {
        // background color
        background: colourList.primary[800],
        // Inside border colors
        border: 'white',
        // general text color
        color: 'white',
        // Rapids titles
        title: 'white',
        // Bet type title
        gameTitle: colourList.secondary[900],
        box: {
            border: colourList.primary[800]
        }
    },
    pagination: {
        color: colourList.textBody,
        activeBackground: colourList.primary[900],
        background: 'white',
        hoverBackground: colourList.primary[700]
    },

    /**
     * Stats card component styles
     */
    card: {
        background: colourList.neutral[500],
        textColor: colourList.primary[900],
        labelColor: colourList.textBody,
        borderColor: colourList.primary[700],
        iconHeaderColor: colourList.primary[900],
        iconBodyColor: colourList.primary[900]
    },

    /**
     * Content Card component styles
     */
    contentCard: {
        headerBackground: colourList.neutral[500],
        headerTextColor: colourList.textBody,
        borderColor: colourList.primary[700],
        backgroundColor: 'white'
    },

    /**
     * Input element styles
     */
    input: {
        border: colourList.primary[700],
        background: colourList.primary[700],
        text: colourList.textBody,
        filledText: '#111111',
        readOnlyColor: colourList.neutral[800],
        readOnlybackroundColor: colourList.neutral[400]
    },

    /**
     * Table element styles
     */
    table: {
        headerBackground: 'white',
        bodyBackground: 'white',
        cellBackground: 'white',
        headerTextColor: colourList.textBody,
        border: colourList.primary[700],
        color: colourList.textBody
    },
    /**
     * Recent winners component
     */
    winner: {
        background: colourList.secondary[900],
        border: colourList.secondary[800],
        color: colourList.textBody
    },

    /**
     * Button Colors - Keep decision tokens same as chakra to keep consisten application of tokens
     *
     * 50 - hover backgound outline variant
     * 100 - Text color
     * 200 - background darkmode
     * 500 - background
     * 600 - hover backround
     * 700 - active background
     */
    /**
     * Button Colors - Keep decision tokens same as chakra to keep consisten application of tokens
     *
     * 50 - hover backgound outline variant
     * 100 - Text color
     * 200 - background darkmode
     * 300 - Hover text color outline
     * 500 - background
     * 600 - hover backround
     * 700 - active background
     */
    buttonPrimary: {
        700: colourList.primary[950],
        600: colourList.primary[925],
        500: colourList.primary[900],
        200: colourList.primary[950],
        100: 'white',
        50: colourList.primary[900]
    },
    buttonSecondary: {
        700: colourList.secondary[950],
        600: colourList.secondary[925],
        500: colourList.secondary[900],
        200: colourList.secondary[950],
        50: colourList.secondary[900],
        100: colourList.textBody
    },
    buttonError: {
        700: colourList.error[950],
        600: colourList.error[925],
        500: colourList.error[900],
        200: colourList.error[950],
        100: colourList.error[900]
    },
    buttonNeutral: {
        700: colourList.primary[750],
        600: colourList.primary[725],
        500: colourList.primary[700],
        200: colourList.primary[750],
        100: colourList.primary[800],
        50: colourList.primary[700]
    },
    buttonAddCard: {
        700: colourList.neutral[700],
        600: colourList.neutral[700],
        500: 'white',
        200: colourList.neutral[700],
        100: colourList.primary[900],
        50: 'white'
    },
    buttonCheckout: {
        700: colourList.primary[850],
        600: colourList.primary[825],
        500: colourList.primary[800],
        200: colourList.primary[850],
        100: 'white',
        50: colourList.primary[800]
    },
    buttonTextColor: colourList.textBody,

    /**
     * Play Card component
     */
    playCard: {
        cardBackground: 'white',
        cardBorder: colourList.primary[700],

        /**
         * Footer styles
         */
        footerBackgroundColor: colourList.background,
        footerBorder: colourList.primary[700],
        /**
         * Header styles
         */
        headerBackgroundColor: colourList.background,
        headerBorder: colourList.primary[700],
        headerLink: colourList.red[500]
    },

    /**
     * LoteryBall
     *
     * 100: solid bkg
     * 200: solid border
     * 300: solid text color
     *
     * 400: outline bkg
     * 500: outline border
     * 600: outline number
     */
    lotteryBall: {
        notSelected: {
            100: `radial-gradient(circle at 50% 13%, #fff, #e3e3e3)`,
            200: colourList.primary[900],
            300: colourList.primary[900],
            400: 'white',
            500: colourList.primary[900],
            600: colourList.primary[900]
        },
        selected: {
            100: `radial-gradient(circle at 33% 33%, #fff8e3, #2F2F2F)`,
            200: colourList.primary[900],
            300: 'white',
            400: 'white',
            500: colourList.primary[900],
            600: colourList.primary[900]
        },
        selectedAlt: {
            100: `radial-gradient(circle at 33% 33%, #fff8e3, #2F2F2F)`,
            200: colourList.primary[900],
            300: 'white',
            400: 'white',
            500: colourList.primary[900],
            600: colourList.primary[900]
        },
        bonus2: {
            100: colourList.tertiary[900],
            200: colourList.tertiary[900],
            300: 'white',
            400: 'white',
            500: colourList.tertiary[900],
            600: colourList.tertiary[900]
        },
        bonus1: {
            100: colourList.secondary[900],
            200: colourList.secondary[900],
            300: 'white',
            400: 'white',
            500: colourList.secondary[800],
            600: colourList.secondary[900]
        }
    },
    lotteryTick: {
        backgroundColor: colourList.green[600],
        iconColor: 'white'
    },

    /**
     * Lottery lines board
     */
    lotteryLinesBoard: {
        linesBackground: colourList.neutral[500]
    }
}

export default colors

import { SET_BETTING_CLOSED, SET_NEXT_DRAW_ID, SET_NEXT_DRAW_TIMESTAMP, SET_PREVIOUS_DRAW } from "./Rapid.constants"

const initialState = {
  bettingClosed: true,
  nextDrawId: null,
  nextDrawTimestamp: 0,
  previousDraw: null,
}

const setBettingClosed = (state, { payload: bettingClosed }) => ({
  ...state,
  bettingClosed,
})

const setNextDrawId = (state, { payload: { drawId } }) => ({
  ...state,
  bettingClosed: false,
  nextDrawId: drawId,
})

const setNextDrawTimestamp = (state, { payload: { nextDrawTimestamp } }) => ({
  ...state,
  nextDrawTimestamp,
})

const setPreviousDraw = (state, { payload }) => {
  return {
    ...state,
    previousDraw: {
      id: payload.id,
      numbers: payload.numbers,
      gmtDrawTime: payload.gmtDrawTime,
      markets: payload.numbers ? calculateMarkets(payload.numbers) : "NA",
    },
  }
}

const calculateMarkets = (numbers) => {
  return {
    sum: numbers.main.reduce((a, b) => a + b, 0),
  }
}

const rapidReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_NEXT_DRAW_TIMESTAMP:
      return setNextDrawTimestamp(state, action)
    case SET_BETTING_CLOSED:
      return setBettingClosed(state, action)
    case SET_NEXT_DRAW_ID:
      return setNextDrawId(state, action)
    case SET_PREVIOUS_DRAW:
      return setPreviousDraw(state, action)
    default:
      return state
  }
}

export default rapidReducer

export const REQUEST_USER_START = 'REQUEST_USER_START'
export const REQUEST_USER_SUCCESS = 'REQUEST_USER_SUCCESS'
export const REQUEST_USER_FAIL = 'REQUEST_USER_FAIL'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_BALANCE_UPDATE_START = 'USER_BALANCE_UPDATE_START'
export const USER_BALANCE_SET = 'USER_BALANCE_SET'
export const USER_LANGUAGE_SET = 'USER_LANGUAGE_SET'
export const USER_TOKEN_SET = 'USER_TOKEN_SET'
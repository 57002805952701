export function parseError(error) {
    if (!error) {
        return null
    }

    // Instance of error
    if (error instanceof Error) {
        return error.message
    }

    // Passed in the error string
    if (typeof error === 'string') {
        return error
    }

    // We have been passed in the API error object { code: '', error: '' }
    if (typeof error === 'object') {
        return error.error
    }

    return error
}
/**
 * Global styles setup
 */
const styles = {
    global: {
        'html, body': {
            color: 'primaryTextColor',
            background: 'backgroundPrimary',
            overflowX: 'hidden'
        },
        '#root': {
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column'
        },
        h1: {
            fontSize: 'xl',
            fontFamily: 'Body SemiBold',
            color: 'primaryHeaderColor',
            mb: 4
        },
        h2: {
            fontSize: 'lg',
            fontFamily: 'Body SemiBold',
            color: 'primaryHeaderColor'
        },
        h3: {
            fontFamily: 'Body SemiBold'
        },
        h4: {
            fontFamily: 'Body SemiBold'
        },
        p: {
            fontSize: 'md',
            fontWeight: '400',
            //fontFamily: 'Body SemiBold',
            lineHeight: 'shorter',
            color: 'primaryTextColor'
        },
        a: {
            _hover: {
                textDecoration: 'none !important'
            }
        },
        '.map-container': {
            borderRadius: 'lg'
        },
        '::-ms-reveal': {
            display: 'none'
        }
    }
}

export default styles

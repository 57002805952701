import { BETS_UPDATE_BET, BETS_REMOVE_BET, BETS_SYNC_NUMBERS, BETS_OPEN_MODAL, BETS_LOAD_SELELECTIONS, BETS_CHANGE_STAKE, BETS_CLOSE_MODAL, BETS_UPDATE_MODAL_SELECTION, BETS_UPDATE_MODAL_BONUS_SELECTION, BETS_CLEAR_ALL } from './Bets.contants'

// Load helpers
import { generateRandomNumbers } from "../../helper/rng"
import { getBonusComponents } from '../../helper/lotteries'

const initialState = {
    bets: [],
    // Balls picked from modal
    modalSelections: [],
    // Existing selections loaded
    selections: [],
    // Existing bonus selections
    bonusSelections: [],
    // Bonus selections picked from modal
    modalBonusSelections: {
        bonus_1: [],
        bonus_2: []
    },
    // Reference to ball and line cliked
    selectedBall: {},
    // Game component rules
    gameOptions: {},
    // Lottery game rules
    rules: {},

    unitValue: 500,
    value: 0,
    // bool to indicate if modal open
    isOpen: false,
    gameId: null
}

function openModal(state, selectedBall) {

    const newState = {
        ...state,
        isOpen: true,
        selectedBall,
        modalSelections: [],
        modalBonusSelections: {
            bonus_1: [],
            bonus_2: []
        }
    } 

    // All selections for line clicked
    const selections = [...state.selections][selectedBall.line]?.numbers
  
    // Check if we can load some balls onto the board
    if (!selections) {
        return newState
    }

    // Make a copy to update
    const newSelections = [...state.modalSelections]
    const newBonusSelections = {
        bonus_1: [],
        bonus_2: []
    }
    const singlePick = state.rules.drumCount > 1

    // We can add the enitre row
    if (!singlePick) {
        Object.keys(selections).forEach((key) => {

            // Standard number
            if (!selections[key].isBonus) {
                newSelections.push(selections[key].number)
            }

            // Bonus ball add
            if (selections[key].isBonus) {
                newBonusSelections[`bonus_${selections[key].bonusIndex}`].push(selections[key].number)
            }
       })
    }

    // We only want to add the ball matched by referemce
    if (singlePick && selections[selectedBall.ball]) {
        newSelections.push(selections[selectedBall.ball].number)
    }

   return {
       ...newState,
       modalSelections: newSelections,
       modalBonusSelections: newBonusSelections
   }
} 

// Close modal reset state
function closeModal(state) {
    return {
        ...state,
        selectedBall: {},
        modalSelections: [],
        modalBonusSelections: {
            bonus_1: [],
            bonus_2: []
        },
        isOpen: false
    }
}

function changeStake(state, { stake, lotteryId, componentId }) {
    let selections = []
    let copyBets = [...state.bets]

    /**
     * Indicates we can do a stake per Line
     * Only update the unitStake in pre for the next line added
     */
    if (!state.rules.variableLength) {
        return {
            ...state, 
            unitValue: stake
        }
    }
   
    if (state.selections.length) {
        selections = state.selections.map((component) => {
            return {
                ...component,
                unitValue: stake
            }
        })

        /**
         * Check if we have any bets to update
         */
        const findBetIndex = state.bets.findIndex((bet) => bet.gameId === lotteryId)
      
        if (findBetIndex !== -1) {
            let copyBet = {...copyBets[findBetIndex]}
            copyBet.components = copyBet.components.map((component) => {
                // Only change the stake for active component
                if (component.id === componentId) {
                    return {
                        ...component,
                        unitValue: stake
                    }
                }

                return component
            })

    
            copyBets[findBetIndex] = copyBet
        }
    }

    return {
        ...state,
        unitValue: stake,
        bets: copyBets,
        selections
    }
}


function addDefaultStake({ value, stake }) {
    // If we dont have a stake or we have a value set already
    if (!stake || value) {
        return value
    }

    if (!value) {
        return stake.minBetAmount
    }
}

/**
 * P2D_STRAIGHT - Pale
 * P2LN_ORDINARY - Super Pale
 * P6D_ORDINARY - Tripleta
 */
const combinationBets = ["P2LN_ORDINARY", "P2D_STRAIGHT", "P6D_ORDINARY"]

function loadSelections(state, { rules, gameOptions }) {
    let activeSelections = []
    const bet = state.bets.find((bet) => bet.gameId === gameOptions.gameId) || {}
    let unitValue = rules?.stake?.minBetAmount ?? 500

    // Cover all pale bets and used to show combinations on bet slip
    const hasCombinations = combinationBets.includes(gameOptions?.betType?.typeObject) //=== 'P2D_STRAIGHT'
    const allowedStakePerBall = gameOptions?.betType?.typeObject === 'P1D_STRAIGHT'

    // Allows to check if we are combination bet
    rules.hasCombinations = hasCombinations

    // Allows to calcualte stake per ball instead of line
    rules.allowedStakePerBall = allowedStakePerBall

    if (bet.components) {
        bet.components.forEach((component) => {
            if (component.id === gameOptions.id) {
                unitValue = component.unitValue
                activeSelections.push({
                    ...component,
                    unitValue: addDefaultStake({ value: component.value, stake: gameOptions.stake })
                })
            }
        })
    }

    return {
        ...state,
        rules,
        gameOptions,
        unitValue,
        selections: activeSelections
    }
}

function addBet(state, bets) {
    let activeSelections = []
    const bet = bets.find((bet) => bet.gameId === state.gameOptions.gameId) || {}
   
    // Cover all pale bets and used to show c
    if (bet.components) {
        bet.components.forEach((component) => {
            if (component.id === state.gameOptions.id) {
                activeSelections.push(component)
            }
        })
    }

    return {
        ...state,
        bets,
        selections: activeSelections
    }
}

function syncBet(state, { gameId, lotteryById, activeGameComponentId, game }) {
    const copyBets = [...state.bets]

    const findBetIndex = copyBets.findIndex((bet) => bet.gameId === gameId)

    if (findBetIndex === -1) {
        return state
    }

    //const bonusRules = rules
    const bet = {...copyBets[findBetIndex]}

    // Build the bonus rules
    let bonusRules =  []
    let activeGameComponent = game.gameComponents.find((component) => component.id === activeGameComponentId) || game.gameComponents[0]
    bonusRules = getBonusComponents({ activeGameComponent, lotteryById, game})
    
    function validateNumbers(numbers) {
        let copyNumbers = [...numbers]
        const numbersRequired = (bonusRules.length + state.rules.minLength) - copyNumbers.length
        
        if (numbersRequired > 0 && bonusRules.length > 0) {
            for (let i = 0; i < numbersRequired; ++i) {
                const rules = bonusRules[i]
                const bonusIndex = (numbersRequired === 1 && bonusRules.length === 2) ? 2 : i + 1
    
                copyNumbers.push({
                    number: generateRandomNumbers(rules.minBonusNumber, rules.maxBonusNumber, 1)[0],
                    isBonus: true,
                    isSelected: true,
                    bonusIndex
                })
            }
        }

        // Set all bonus numbers to not selected
        if (numbersRequired === -2 || bonusRules.length === 0) {
            copyNumbers = copyNumbers.map((number) => {
                if (number.isBonus) {
                    return {
                        ...number,
                        isSelected: false
                    }
                }
                return number
            })
        }

        // Remove all but first bonus ball
        if (numbersRequired === -1) {
            copyNumbers = copyNumbers.map((number) => {

                // Set second bonus to unselected
                if (number.bonusIndex === 2) {
                    return {
                        ...number,
                        isSelected: false
                    }
                }

                // Make sure first bonus is selected again
                if (number.bonusIndex === 1) {
                    return {
                        ...number,
                        isSelected: true
                    }
                }
                return number
            })
        }

        // Make sure to set bonus numbers back to true
        if (numbersRequired === 0 && bonusRules.length === 2) {
            copyNumbers = copyNumbers.map((number) => {
                if (number.isBonus) {
                    return {
                        ...number,
                        isSelected: true
                    }
                }
                return number
            })
        }

        return copyNumbers
    }

    const updateComponents = bet.components.map((component) => {
            return {
                ...component,
                numbers: validateNumbers(Object.values(component.numbers))
            }
    })

    bet.components = updateComponents
    copyBets[findBetIndex] = bet

    return {
        ...state,
        bets: copyBets,
        selections: updateComponents
    }
}

const bets = (state = initialState, action = {}) => {
    switch(action.type) {
        case BETS_UPDATE_BET:
            return addBet(state, action.payload)
        case BETS_REMOVE_BET: 
            return {
                ...state,
                bets: state.bets.filter(bet => bet.gameId !== action.payload),
                selections: []
            }
        case BETS_SYNC_NUMBERS:
            return syncBet(state, action.payload)
        case BETS_OPEN_MODAL:
            return openModal(state, action.payload)
        case BETS_LOAD_SELELECTIONS: 
            return loadSelections(state, action.payload)
        case BETS_CHANGE_STAKE:
            return changeStake(state, action.payload)
        case BETS_CLOSE_MODAL:
            return closeModal(state)
            case BETS_UPDATE_MODAL_SELECTION:
                return {
                    ...state,
                    modalSelections: action.payload
                }
            case BETS_UPDATE_MODAL_BONUS_SELECTION: {
                return {
                    ...state,
                    modalBonusSelections: {
                        ...state.modalBonusSelections,
                        [action.payload.key]: action.payload.ball
                    } 
                }
            }
        case BETS_CLEAR_ALL:
            return initialState
        default:
            return state
    }
}

export default bets
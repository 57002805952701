import React from 'react'
import { NavLink as ReachLink } from 'react-router-dom'

// Load components
import { Box, Text, Link, List, ListItem, Stack } from '@chakra-ui/react'
import Icon from '../../UI/Icon/Icon'

// Load FE lib
import { useLanguageContext } from 'component/Translations/Translations'

export const MainSubMenu = () => {
    const { getText } = useLanguageContext()

    return (
        <Stack
            borderRadius="lg"
            boxShadow="2xl"
            px={6}
            py={8}
            background="accountMenu.background"
            spacing={3}
        >
            <Box fontSize="sm">
                <Text as="h3" layerStyle="subMenuHeader">
                    {getText('account.subMenuHeader.wallet', 'Wallet')}
                </Text>
                <List
                    color="accountMenu.itemTextColor"
                    mb={4}
                    borderBottomRadius="8px"
                    fontSize="14px"
                    fontWeight="bold"
                >
                    <ListItem>
                        <Link as={ReachLink} to={`/account/add-funds`} layerStyle="submenuLink">
                            <Box layerStyle="menuIcon">
                                <Icon icon="creditCard" />
                            </Box>
                            {getText('account.subMenuTitle.addFunds', 'Add Funds')}
                        </Link>
                    </ListItem>
                    <ListItem lineHeight={5}>
                        <Link
                            as={ReachLink}
                            to={`/account/withdraw`}
                            layerStyle="submenuLink"
                            border="none"
                        >
                            <Box layerStyle="menuIcon">
                                <Icon icon="moneyEnvelope" />
                            </Box>
                            {getText('account.subMenuTitle.withdraw', 'Withdraw Funds')}
                        </Link>
                    </ListItem>
                </List>
            </Box>
            <Box fontSize="sm">
                <Text as="h3" layerStyle="subMenuHeader">
                    {getText('account.subMenuHeader.account', 'Account')}
                </Text>
                <List
                    color="accountMenu.itemTextColor"
                    mb={4}
                    borderBottomRadius="8px"
                    fontSize="14px"
                    fontWeight="bold"
                >
                    <ListItem>
                        <Link as={ReachLink} end to={`/account`} layerStyle="submenuLink">
                            <Box layerStyle="menuIcon">
                                <Icon icon="user" />
                            </Box>
                            {getText('account.subMenuTitle.summary', 'Account Summary')}
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link as={ReachLink} to={`/account/messages`} layerStyle="submenuLink">
                            <Box layerStyle="menuIcon">
                                <Icon icon="commentLines" />
                            </Box>
                            {getText('account.subMenuTitle.messages', 'Messages')}
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link as={ReachLink} to={`/account/details`} layerStyle="submenuLink">
                            <Box layerStyle="menuIcon">
                                <Icon icon="fileUser" />
                            </Box>
                            {getText('account.subMenuTitle.details', 'Your Details')}
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link
                            as={ReachLink}
                            to={`/account/responsible-gambling`}
                            layerStyle="submenuLink"
                            border="none"
                        >
                            <Box layerStyle="menuIcon">
                                <Icon icon="list" />
                            </Box>
                            {getText(
                                'account.subMenuTitle.responsibleGambling',
                                'Responsible Gambling'
                            )}
                        </Link>
                    </ListItem>
                </List>
            </Box>
            <Box fontSize="sm">
                <Text as="h3" layerStyle="subMenuHeader">
                    {getText('account.subMenuHeader.history', 'Account History')}
                </Text>
                <List
                    color="accountMenu.itemTextColor"
                    borderBottomRadius="8px"
                    fontSize="14px"
                    fontWeight="bold"
                >
                    <ListItem>
                        <Link as={ReachLink} to={`/account/lottery`} layerStyle="submenuLink">
                            <Box layerStyle="menuIcon">
                                <Icon icon="list" />
                            </Box>
                            {getText('account.subMenuTitle.tickets', 'Lottery Tickets')}
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link as={ReachLink} to="/account/winning/lines" layerStyle="submenuLink">
                            <Box layerStyle="menuIcon">
                                <Icon icon="trophy" />
                            </Box>
                            {getText('account.subMenuTitle.winningTickets', 'Winning Tickets')}
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link
                            as={ReachLink}
                            to={`/account/transactions`}
                            layerStyle="submenuLink"
                            border="none"
                            mb={0}
                        >
                            <Box layerStyle="menuIcon">
                                <Icon icon="dollarInvoice" />
                            </Box>
                            {getText('account.subMenuTitle.transactions', 'Transactions')}
                        </Link>
                    </ListItem>
                </List>
            </Box>
        </Stack>
    )
}

export default MainSubMenu

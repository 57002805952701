import React from "react"
import PropTypes from "prop-types"
import { Box, createStylesContext, useMultiStyleConfig } from "@chakra-ui/react"
import Icon from "../UI/Icon/Icon"

const [StylesProvider, useStyles] = createStylesContext()

function padZero(value, shouldPad = false) {
  if (typeof value === 'undefined' || value === null || !shouldPad) {
    return value
  }

  return ('0' + value).slice(-2)
}

/**
 * UI component to render the lottery balls.
 *
 * Utilise the variant type to switch between active and inactive lottery balls
 */
const LotteryBall = ({ number, variant, ballType = "not-selected", size, children, padLeading, ...rest }) => {
  const styles = useMultiStyleConfig("LotteryBall", { variant, size })

  return (
    <Box style={{ perspective: "1200px", perspectiveOrigin: "50% 50%" }} {...rest}>
      <StylesProvider value={styles}>
        <Box as="figure" {...styles.ball}>
          <Box as="p" data-ball={ballType}>
            <Box as="span" {...styles.number}>
              {padZero(number, padLeading)}
            </Box>
          </Box>
        </Box>
        {children}
      </StylesProvider>
    </Box>
  )
}

export const LotteryTick = ({ isComplete }) => {
  const styles = useStyles()

  if (!isComplete) {
    return null
  }
  return (
    <Box {...styles.tick}>
      <Icon icon="tick" />
    </Box>
  )
}

LotteryBall.propTypes = {
  /**
   * Number to display
   */
  number: PropTypes.number,
  /**
   * Style of ball display
   */
  variant: PropTypes.oneOf(["solid", "outline", "dashed"]),
  /**
   * Color scheme of the ball to load
   *
   * Differentiates between reg and bonus balls
   */
  ballType: PropTypes.oneOf(["selected", "not-selected", "bonus1", "bonus2"]),
  /**
   * The size of the ball
   */
  size: PropTypes.oneOf(["sm", "md", "lg"]),
}

LotteryTick.propTypes = {
  /**
   * Conditionally show the tick
   */
  isComplete: PropTypes.bool,
}

LotteryTick.defaultProps = {
  isComplete: false,
}

export default LotteryBall

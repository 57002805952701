import React from 'react'
import PropTypes from 'prop-types'
import { Box, Text } from '@chakra-ui/react'

const ContentCard = ({ title, children, ...props }) => {
    return (
        <Box
            boxShadow="md"
            layerStyle="roundBoxBorderContainer"
            borderColor="contentCard.borderColor"
            background="contentCard.backgroundColor"
        >
            <Box
                px={6}
                py={3}
                borderBottom="1px solid"
                borderColor="contentCard.borderColor"
                background="contentCard.headerBackground"
                borderTopRadius="lg"
            >
                <Text as="h2">{title}</Text>
            </Box>
            <Box
                height="calc(100% - 52px)"
                p={6}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                {...props}
            >
                {children}
            </Box>
        </Box>
    )
}

ContentCard.propTypes = {
    title: PropTypes.string.isRequired
}

export default ContentCard

import modalConstants from './Modal.constants'

export const openModal = (modal, data = {}, modalConfig = {}) => {
  return {
    type: modalConstants.OPEN_MODAL,
    modal,
    data,
    modalConfig
  }
}

export const closeModal = () => {
  return {
    type: modalConstants.CLOSE_MODAL,
  }
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { NavLink as ReachLink, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Chakra components
import {
    Button,
    Box,
    Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    useDisclosure,
    Link,
    List,
    ListItem,
    IconButton,
    Image
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import Icon from '../../UI/Icon/Icon'
import Text from '../../UI/Text/Text'
import LanguageDisplay from '../../LanguageDisplay/LanguageDisplay'

// Load actions
import { requestLogout } from '../../../redux/User/User.actions'

// Load hooks
import { useGamesQuery } from '../../../hooks/queries/useGames'

// Load helpers
import { formatPrice } from '../../../helper/currency'
//import logo from "../../UI/Logo/Logo"
import logo from '../../../img/logo.svg'
import { useQueryClient } from 'react-query'

const SideDrawer = ({ buttonProps = {} }) => {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()
    const isAuthed = useSelector((state) => state.user.isAuthed)
    const user = useSelector((state) => state.user.data)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { data: games } = useGamesQuery()
    const location = useLocation()

    const _onLogOut = () => {
        dispatch(requestLogout())
        queryClient.clear()
        onClose()
    }

    return (
        <>
            <IconButton
                variant="outline"
                aria-label="menu"
                borderRadius="md"
                size="xs"
                fontSize="sm"
                {...buttonProps}
                onClick={onOpen}
                icon={<HamburgerIcon />}
            />
            <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                <DrawerOverlay />
                <DrawerContent maxWidth={{ base: 'calc(100% - 108px)', md: '360px' }}>
                    {/* <DrawerCloseButton /> */}
                    <DrawerHeader>
                        <Box mb={10} display="flex" justifyContent="center">
                            <Image src={logo} />
                        </Box>
                        {/* Logged in  */}
                        {isAuthed && (
                            <>
                                <Box marginBottom="10px">
                                    <Text
                                        scope="global"
                                        tag="headerTitle.welcomeName"
                                        text={'Welcome, {firstName}!'}
                                        data={{ firstName: user.firstName }}
                                        fontSize="lg"
                                        textStyle="semiBoldFontFamily"
                                    />
                                </Box>
                                <Box
                                    mb={4}
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        fontSize="xs"
                                        position="relative"
                                        mb={2}
                                    >
                                        <Icon icon="coins" />
                                        <Text
                                            color="inherit"
                                            fontSize="sm"
                                            textStyle="semiBoldFontFamily"
                                            marginLeft={1}
                                        >
                                            {formatPrice(
                                                user.userWallet.amount / 100,
                                                user.currency.shortName
                                            )}
                                        </Text>
                                    </Box>
                                    <Box display="flex" fontSize="xs" alignItems="center">
                                        <Icon icon="trophy" />
                                        <Text
                                            fontSize="sm"
                                            textStyle="semiBoldFontFamily"
                                            color="inherit"
                                            ml={1}
                                        >
                                            {formatPrice(
                                                user.userWallet.protectedAmount / 100,
                                                user.currency.shortName
                                            )}
                                        </Text>
                                    </Box>
                                </Box>
                                <Link
                                    as={ReachLink}
                                    to="/account"
                                    _focus={{ outline: 'none' }}
                                    _hover={{ textDecoration: 'none' }}
                                >
                                    <Button
                                        leftIcon={<Icon icon="user" />}
                                        aria-label="view account"
                                        colorScheme="buttonSecondary"
                                        marginBottom="10px"
                                        minWidth="100%"
                                        onClick={onClose}
                                    >
                                        <Text
                                            scope="global"
                                            color="inherit"
                                            fontWeight="semibold"
                                            tag="button.myAccount"
                                            text="My Account"
                                            fontSize={{ base: 'sm', md: 'md' }}
                                        />
                                    </Button>
                                </Link>
                                <Button
                                    leftIcon={<Icon icon="signOut" />}
                                    marginBottom="10px"
                                    variant="outline"
                                    minWidth="100%"
                                    onClick={_onLogOut}
                                >
                                    <Text
                                        scope="global"
                                        color="inherit"
                                        fontWeight="semibold"
                                        tag="button.logout"
                                        text="Log Out"
                                        fontSize={{ base: 'sm', md: 'md' }}
                                    />
                                </Button>
                            </>
                        )}
                        {/* Not Logged In */}
                        {!isAuthed && (
                            <>
                                <Link
                                    as={ReachLink}
                                    to="/register"
                                    _focus={{ outline: 'none' }}
                                    _hover={{ textDecoration: 'none' }}
                                >
                                    <Button
                                        leftIcon={<Icon icon="userEdit" />}
                                        aria-label="register"
                                        marginBottom="10px"
                                        minWidth="100%"
                                        whiteSpace="pre-wrap"
                                        onClick={onClose}
                                    >
                                        <Text
                                            as="span"
                                            scope="global"
                                            tag="button.registerNow"
                                            text="Register Now"
                                        />
                                    </Button>
                                </Link>
                                <Link
                                    as={ReachLink}
                                    to="/login"
                                    state={location.pathname}
                                    _focus={{ outline: 'none' }}
                                    _hover={{ textDecoration: 'none' }}
                                >
                                    <Button
                                        leftIcon={<Icon icon="signIn" />}
                                        aria-label="sign in"
                                        colorScheme="buttonSecondary"
                                        marginBottom="10px"
                                        width="100%"
                                        onClick={onClose}
                                    >
                                        <Text
                                            as="span"
                                            scope="global"
                                            tag="text.login"
                                            text="Log In"
                                        />
                                    </Button>
                                </Link>
                            </>
                        )}
                    </DrawerHeader>
                    <DrawerBody>
                        {isOpen &&
                            <Box fontWeight="bold" paddingBottom={8}>
                                <Text
                                    scope="global"
                                    tag="menuTitle.mostPopular"
                                    text="Most Popular"
                                    as="h2"
                                    marginBottom={2}
                                />

                                <List
                                    spacing={3}
                                    aria-label="most popular"
                                    textStyle="sideDrawerLinks"
                                    marginBottom={8}
                                >
                                    {games?.all &&
                                        games.all
                                            .filter((game) => !!game.settings.mostPopular)
                                            .map((game) => {
                                                return (
                                                    <ListItem
                                                        key={`headerList-${game.id}`}
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        onClick={onClose}
                                                    >
                                                        <Link
                                                            as={ReachLink}
                                                            to={`/play/${game.url}`}
                                                            flexGrow="1"
                                                            alignSelf="left"
                                                            layerStyle="textExclipse"
                                                        >
                                                            {game.name}
                                                        </Link>
                                                        <Box
                                                            as="span"
                                                            alignSelf="right"
                                                            layerStyle="jackpotHighlight"
                                                            fontSize={{ base: 'xs', md: 'sm' }}
                                                        >
                                                            {formatPrice(game.jackpot / 100)}
                                                        </Box>
                                                    </ListItem>
                                                )
                                            })}
                                    <ListItem>
                                        <Text
                                            as={ReachLink}
                                            to="/play"
                                            onClick={onClose}
                                            scope="global"
                                            tag="link.playLotteries"
                                            text="Play Lotteries"
                                        />
                                    </ListItem>
                                    <ListItem textStyle="sideDrawerLinks">
                                        <Text
                                            as={ReachLink}
                                            to="/results"
                                            onClick={onClose}
                                            scope="global"
                                            tag="link.results"
                                            text="Results"
                                        />
                                    </ListItem>
                                    <ListItem textStyle="sideDrawerLinks">
                                        <Text
                                            as={ReachLink}
                                            to="/help/how-to-play"
                                            scope="global"
                                            tag="link.howToPlay"
                                            text="How to play?"
                                            onClick={onClose}
                                        />
                                    </ListItem>
                                    <ListItem textStyle="sideDrawerLinks">
                                        <Text
                                            as={ReachLink}
                                            to="/winners"
                                            scope="global"
                                            tag="link.winners"
                                            text="Winners"
                                            onClick={onClose}
                                        />
                                    </ListItem>
                                    <ListItem textStyle="sideDrawerLinks">
                                        <Text
                                            as={ReachLink}
                                            to="/news"
                                            scope="global"
                                            tag="link.news"
                                            text="News"
                                            onClick={onClose}
                                        />
                                    </ListItem>
                                </List>

                                <Text
                                    scope="global"
                                    tag="menuTitle.helpfulLinks"
                                    text="Helpful Links"
                                    as="h2"
                                    mb={2}
                                />
                                <List
                                    spacing={3}
                                    aria-label="Enclaces Útiles"
                                    textStyle="sideDrawerLinks"
                                    marginBottom={8}
                                >
                                    <ListItem>
                                        <Text
                                            as={ReachLink}
                                            to="page/responsible-gambling"
                                            scope="global"
                                            tag="text.responsibleGambling"
                                            text="Responsible Gaming"
                                        />
                                    </ListItem>
                                    <ListItem>

                                        <Text
                                            as={ReachLink}
                                            to="/page/about-leidsa"
                                            onClick={onClose}
                                            scope="global"
                                            tag="link.about"
                                            text="About LEIDSA"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <Text
                                            as={ReachLink}
                                            to="/page/help"
                                            onClick={onClose}
                                            scope="global"
                                            tag="link.help"
                                            text="Help & Support"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <Text
                                            as={ReachLink}
                                            to="/page/terms"
                                            scope="global"
                                            tag="link.terms"
                                            text="Terms"
                                            onClick={onClose}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <Text
                                            as={ReachLink}
                                            to="/page/privacy"
                                            scope="global"
                                            tag="link.privacy"
                                            text="Privacy"
                                            onClick={onClose}
                                        />
                                    </ListItem>
                                </List>
                                <Text scope="global" tag="menuTitle.language" text="Language" as="h2" />
                                <LanguageDisplay
                                    color="neutral.800"
                                    fontSize="md"
                                    display="inline-block"
                                />
                            </Box>
                        }
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default SideDrawer

/**
 * Font families
 */
export const fonts = {
    body: 'Asap, sans-serif, -apple-system, BlinkMacSystemFont, Helvetica, Arial',
    heading: 'Asap, sans-serif',
    mono: 'Menlo, monospace'
}

/**
 * Text styles
 */
export const footerLegalText = {
    fontSize: 'sm',
    color: 'neutral.800'
}

/**
 * Sidedraw link
 */
export const sideDrawerLinks = {
    fontSize: 'sm',
    lineHeight: 6,
    color: 'neutral.800',
    '& a': {
        _hover: {
            opacity: '0.8'
        }
    }
}

export const heroText = {
    fontSize: 'xl',
    lineHeight: 'normal'
}

export const cmsParagraph = {
    fontSize: 'md'
}

export const tableCellHighlightColor = {
    color: 'primary.900',
    fontFamily: 'Body Semibold'
}

/**
 * Homepage left banner text
 * Lotter total info
 */
export const lotomasTotalText = {
    fontSize: 'lg',
    fontFamily: 'Heading Bold',
    lineHeight: '15px'
}

// title text homepage
export const titleTextAlt = {
    fontSize: 'xl',
    color: 'primary.800',
    textTransform: 'upperCase'
}

// Reference custom heading font family
export const headingFontFamily = {
    fontFamily: 'Heading Bold'
}

// Refernce semibold body font family
export const semiBoldFontFamily = {
    fontFamily: 'Body Semibold'
}

export const bannerSpanText = {
    fontSize: 'xs',
    maxWidth: '100px',
    textAlign: 'center',
    lineHeight: 'shorter',
    fontFamily: 'Heading Bold'
}

export const winCardLeftColText = {
    color: 'inherit',
    fontFamily: 'Body Semibold',
    textAlign: 'right',
    fontSize: 'sm'
}

const pageMainTitle = {
    color: 'primary.900',
    fontFamily: 'Body Semibold'
}

const withdrawLegalText = {
    color: 'primary.900'
}

export const tableCellHighlightColor = {
    color: 'primary.800',
    fontFamily: 'Body Semibold'
}

const textStyles = {
    pageMainTitle,
    withdrawLegalText,
    tableCellHighlightColor
}

export default textStyles

import React from 'react'

// Pro icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faUsdSquare,
    faShoppingBasket,
    faMoneyBillWave,
    faTools,
    faBallPile,
    faTicketAlt,
    faQuestionCircle,
    faCalendarAlt,
    faDice,
    faLongArrowRight,
    faLongArrowLeft,
    faShoppingCart,
    faUser,
    faSignOutAlt,
    faSignInAlt,
    faCoins,
    faPencilAlt,
    faTrashAlt,
    faPlus,
    faMinus,
    faCheckCircle,
    faSpinnerThird,
    faExclamationCircle,
    faTrophy,
    faInbox,
    faListAlt,
    faFileInvoiceDollar,
    faFileUser,
    faCommentLines,
    faCreditCard,
    faIdCard,
    faUserCircle,
    faLock,
    faLink
} from '@fortawesome/pro-light-svg-icons'
import {
    faFacebookF,
    faTwitter,
    faYoutube,
    faInstagram,
    faLinkedin,
    faTiktok
} from '@fortawesome/free-brands-svg-icons'
import {
    faExclamationTriangle,
    faEnvelopeOpenDollar,
    faEnvelope,
    faMoneyCheckEdit,
    faUserTag,
    faUserEdit,
    faUserSecret,
    faSearch,
    faClipboardList,
    faTimes,
    faCheck,
    faInfoCircle
} from '@fortawesome/pro-solid-svg-icons'

const Icon = ({ icon, ...props }) => {
    switch (icon) {
        case 'info':
            return <FontAwesomeIcon icon={faInfoCircle} {...props} />
        case 'lock':
            return <FontAwesomeIcon icon={faLock} {...props} />
        case 'facebook':
            return <FontAwesomeIcon icon={faFacebookF} {...props} />
        case 'twitter':
            return <FontAwesomeIcon icon={faTwitter} {...props} />
        case 'youtube':
            return <FontAwesomeIcon icon={faYoutube} {...props} />
        case 'instagram':
            return <FontAwesomeIcon icon={faInstagram} {...props} />
        case 'linkedin':
            return <FontAwesomeIcon icon={faLinkedin} {...props} />
        case 'tiktok':
            return <FontAwesomeIcon icon={faTiktok} {...props} />
        case 'tools':
            return <FontAwesomeIcon icon={faTools} {...props} />
        case 'clipboardList':
            return <FontAwesomeIcon icon={faClipboardList} />
        case 'calendar':
            return <FontAwesomeIcon icon={faCalendarAlt} width="200px" {...props} />
        case 'longArrowRight':
            return <FontAwesomeIcon icon={faLongArrowRight} {...props} />
        case 'longArrowLeft':
            return <FontAwesomeIcon icon={faLongArrowLeft} {...props} />
        case 'basket':
            return <FontAwesomeIcon icon={faShoppingCart} {...props} />
        case 'user':
            return <FontAwesomeIcon icon={faUser} {...props} />
        case 'signOut':
            return <FontAwesomeIcon icon={faSignOutAlt} {...props} />
        case 'signIn':
            return <FontAwesomeIcon icon={faSignInAlt} {...props} />
        case 'userEdit':
            return <FontAwesomeIcon icon={faUserEdit} {...props} />
        case 'coins':
            return <FontAwesomeIcon icon={faCoins} {...props} />
        case 'pencil':
            return <FontAwesomeIcon icon={faPencilAlt} {...props} />
        case 'trash':
            return <FontAwesomeIcon icon={faTrashAlt} {...props} />
        case 'dice':
            return <FontAwesomeIcon icon={faDice} {...props} />
        case 'plus':
            return <FontAwesomeIcon icon={faPlus} {...props} />
        case 'minus':
            return <FontAwesomeIcon icon={faMinus} {...props} />
        case 'tick':
            return <FontAwesomeIcon icon={faCheckCircle} {...props} />
        case 'check':
            return <FontAwesomeIcon icon={faCheck} {...props} />
        case 'warningTriangle':
            return <FontAwesomeIcon icon={faExclamationTriangle} {...props} />
        case 'spinner':
            return <FontAwesomeIcon icon={faSpinnerThird} spin {...props} />
        case 'warning':
            return <FontAwesomeIcon icon={faExclamationCircle} {...props} />
        case 'help':
            return <FontAwesomeIcon icon={faQuestionCircle} {...props} />
        case 'mail':
            return <FontAwesomeIcon icon={faEnvelope} {...props} />
        case 'trophy':
            return <FontAwesomeIcon icon={faTrophy} {...props} />
        case 'moneyBillWave':
            return <FontAwesomeIcon icon={faMoneyBillWave} />
        case 'shoppingBasket':
            return <FontAwesomeIcon icon={faShoppingBasket} />
        case 'usdSquare':
            return <FontAwesomeIcon icon={faUsdSquare} />
        case 'cheque':
            return <FontAwesomeIcon icon={faMoneyCheckEdit} />
        case 'userTag':
            return <FontAwesomeIcon icon={faUserTag} {...props} />
        case 'userSecret':
            return <FontAwesomeIcon icon={faUserSecret} />
        case 'moneyEnvelope':
            return <FontAwesomeIcon icon={faEnvelopeOpenDollar} />
        case 'search':
            return <FontAwesomeIcon icon={faSearch} />
        case 'inbox':
            return <FontAwesomeIcon icon={faInbox} />
        case 'ticket':
            return <FontAwesomeIcon icon={faTicketAlt} />
        case 'balls':
            return <FontAwesomeIcon icon={faBallPile} />
        case 'creditCard':
            return <FontAwesomeIcon icon={faCreditCard} {...props} />
        case 'times':
            return <FontAwesomeIcon icon={faTimes} {...props} />
        case 'commentLines':
            return <FontAwesomeIcon icon={faCommentLines} />
        case 'fileUser':
            return <FontAwesomeIcon icon={faFileUser} />
        case 'list':
            return <FontAwesomeIcon icon={faListAlt} />
        case 'dollarInvoice':
            return <FontAwesomeIcon icon={faFileInvoiceDollar} />
        case 'idCard':
            return <FontAwesomeIcon icon={faIdCard} />
        case 'userCircle':
            return <FontAwesomeIcon icon={faUserCircle} />
        case 'link':
            return <FontAwesomeIcon icon={faLink} />
        default:
            return <i>Icon not found</i>
    }
}

export default Icon

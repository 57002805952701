import { put, select, race, call, take, actionChannel, takeLeading, all } from "redux-saga/effects"

// Load helper
import { isValidJwt, getRefresh } from "../../helper/jwt"
import { axiosInstance as axios } from "../../helper/axios"

// Load selectors
import * as selectors from "../selectors.saga"

// Redux constants / actions
import { CHECK_TOKEN_VALID, REQUEST_TOKEN_FAILED, REQUEST_TOKEN_PENDING, REQUEST_TOKEN_SUCCESS } from "./Token.constants"
import { checkTokenValid, requestTokenFailed, requestTokenStart, requestTokenSuccess } from "./Token.actions"
import { requestLogout, setUserToken } from "../User/User.actions"

// Waiting for token validation requests
export function* watchTokenValid() {
  // Create a channel for request actions => creates a buffer and processes them serially and not concurrently
  const requestChan = yield actionChannel(CHECK_TOKEN_VALID)

  while (true) {
    // take any payload we might have from the channel
    const { payload } = yield take(requestChan)

    // Blocking call for each request => process sequentially
    yield call(checkToken, payload)
  }
}

// Check the token is invalid before refreshing
export function* checkToken() {
  const token = yield select(selectors.token)

  // No token than logout
  if (!token) {
    return yield put(requestLogout())
  }

  // Decode and check token exp time
  const isValid = yield isValidJwt(token)

  // Token expired or user is not authed refresh
  if (!isValid && token) {
    return yield put(requestTokenStart())
  }

  // Token is valid => settle our race condition so our sagas can continue
  return yield put(requestTokenSuccess())
}

// Check Token Valid saga
export function* validateTokenSaga() {
  // Start our race proccess to validate token
  yield put(checkTokenValid())

  const { success, failed } = yield race({
    success: take(REQUEST_TOKEN_SUCCESS),
    failed: take(REQUEST_TOKEN_FAILED),
  })

  // We could not refresh so logout user and end session
  if (failed) {
    yield put(requestLogout())
  }

  return {
    failed,
    success,
  }
}

export function* refreshTokenAsync() {
  try {
    // Store the latest refresh token from store
    const token = yield select(selectors.token)

    if (!token) {
      throw new Error("Invalid token")
    }

    // Read the JWT data
    const refreshToken = yield getRefresh(token)
    const client = yield axios.post(`user/auth/token`, { refreshToken })

    const response = client.data

    if (response.error) {
      throw Error(response.error)
    }

    // Settle our race conditions
    yield put(requestTokenSuccess())
    yield put(setUserToken(response.token))

  } catch (error) {
    // Failed refreshing
    yield put(requestTokenFailed())

    // We cannot refresh start logout
    yield put(requestLogout())
  }
}

// Listner: Blokcing => Will only process the leading request and block any future untill finished
export function* requestRefreshStart() {
  yield takeLeading(REQUEST_TOKEN_PENDING, refreshTokenAsync)
}

export function* tokenSaga() {
  yield all([call(watchTokenValid), call(requestRefreshStart)])
}

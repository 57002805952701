import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'

const PrivateRoute = ({ component: Component, authentication = false, redirectTo = '/login', ...props }) => {
    const location = useLocation()
    return authentication ?
                <Component {...props} />
            :
                <Navigate to={redirectTo} state={{ from: location }} replace />
}

PrivateRoute.propTypes = {
    component: PropTypes.any,
    authentication: PropTypes.bool
}

export default PrivateRoute

import jwt_decode from "jwt-decode"

function parseJwt(token) {
  const decode = jwt_decode(token.toString())
  const expiry = decode.exp
  const refresh = decode.refreshToken

  return {
    expiry,
    refresh,
  }
}

export const getRefresh = (token) => {
  if (!token) {
    return false
  }

  const jwtData = parseJwt(token)
  return jwtData.refresh
}

export const isValidJwt = (token) => {
  if (!token) {
    return false
  }
  // Read our JWT
  const jwtData = parseJwt(token),
    nowTime = Math.floor(new Date().getTime() / 1000)

  // Has the JWT expired?
  return jwtData.expiry > nowTime
}

import React from "react"
import { useSelector } from "react-redux"

// Load components
import { Box, Text, Image, Grid, GridItem, Button } from "@chakra-ui/react"
import { getCdnUrl } from "../../../../helper/cdn"
import { useLanguageContext } from "component/Translations/Translations"
import LotteryBall from "component/Lottery/LotteryBall"
import InlineError from "component/Errors/InlineError"
// Load helper
import { formatDateTime, getLanguageISO } from "../../../../helper/date"
import { formatPrice } from "../../../../helper/currency"

// Load hooks
import { useSendWinTicket } from "hooks/queries/useLotteries"


const depositThreshold = parseInt(process.env.REACT_APP_LOTTERY_WIN_THRESHOLD)

const outputPrice = (number) => {
  if (!number) {
    return 0
  }
  return (number / 100)
}

const timeOptions = {
  hour: '2-digit',
  minute: '2-digit',
}

const dateOptions = {
  month: 'short',
  weekday: 'short',
  day: '2-digit'
}

const ViewTicket = ({ closeModal }) => {
  const { getText } = useLanguageContext()
  const data = useSelector((state) => state.modal.data)
  const language = useSelector((state) => state.user.language)
  const email = useSelector((state) => state.user.data?.email)
  const { line } = data ?? {}

  const winning = line?.winning
  const lineId = line.id
  const ticketId = line.ticketId
  const returns = winning?.amount ?? 0
  const gameSettings = line.gameComponent.lottery?.logo //ticket?.booking?.game?.gameSettings[0]?.fileName
  //const { data: meta, error, isLoading } = useLineMetaQuery({ ticketId, lineId })
  const mutate = useSendWinTicket()
  //const lineMeta = meta?.lineMeta ?? {}
  //const { } = useRedeemMutation()

  const getDate = (drawTime) => formatDateTime({ dateSrc: drawTime, dateOptions, timeOptions, timeZone: getLanguageISO(language) })
  //const drawTime = getDate(line?.draw?.gmtDrawTime)
  const createdDate = getDate(line?.winning?.createdDate)
  const hasLeadZero = line?.gameComponent?.lottery?.hasLeadZero

  // Lottert bonus numbers
  const extraNumbers = line?.extraNumbers?.bonus ?? []

  const headOfficeOnly = returns >= depositThreshold
  let matchedNumbers = {}

  // What numbers were matched
  if (line.winning && line?.winning?.matchedNumbers && (line?.gameComponent?.ruleType?.group === "number_normal" || line?.gameComponent?.ruleType?.group === "complex" || line?.gameComponent?.gameGroup?.name === "Number Match")) {
    if (typeof line?.winning?.matchedNumbers === "string") {
      matchedNumbers = JSON.parse(line?.winning?.matchedNumbers)
      matchedNumbers.main = Object.values(matchedNumbers.numbers)
    }

    if (typeof line.winning.matchedNumbers === "object") {
      matchedNumbers = line.winning.matchedNumbers
      matchedNumbers.main = Object.values(matchedNumbers.numbers)
    }
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <InlineError error={mutate.error} />
      {gameSettings && <Box mb={4}>{<Image maxWidth="160px" alt={line.gameComponent.lottery?.displayName} src={getCdnUrl(gameSettings)} />}</Box>}
      {/* {lineMeta.serial && <Text>{lineMeta.serial}</Text>} */}

      {/* {error ? <InlineError error={error} /> :
        <Grid alignItems="center" gridTemplateColumns="repeat(2, 1fr)" mb={4} gridGap={4}>
          <GridItem>
            <Text fontWeight="bold" lineHeight="5">
              {line?.gameComponent?.lottery?.displayName}
            </Text>
          </GridItem>
          <GridItem textAlign="right">
            <Text fontWeight="bold" lineHeight="5">
              W: {lineMeta?.window || "NA"}
            </Text>
            <Text fontWeight="bold" lineHeight="5">
              {getText("account.text.timestamp", `Timestamp: `)} {drawTime.date} {drawTime.time}
            </Text>
            <Text fontWeight="Bold" lineHeight="5">
              {getText("account.lotteryLine.drawId", "Draw Id:")} {line?.drawId}
            </Text>
            <Text fontWeight="bold">TSN: {lineMeta?.tsn}</Text>
          </GridItem> */}
      <Grid alignItems="center" gridTemplateColumns="repeat(2, 1fr)" mb={4} gridGap={4}>
        {matchedNumbers.numbers && (
          <GridItem display="grid" gridGap={2} gridTemplateColumns="repeat(auto-fill, minmax(30px, 1fr))" borderTop="1px solid" borderBottom="1px solid" borderColor="black" py={4} colSpan="2">
            {matchedNumbers.main.map((number, index) => (
              <LotteryBall
                key={`line_${index}_ball_${number}`}
                number={number}
                ballType='selected'
                padLeading={hasLeadZero}
              />
            ))}
            {extraNumbers.length ? extraNumbers.map((number, i) => <LotteryBall key={`Bonus_${number}_${i}`} number={number} ballType={`bonus${i + 1}`} />) : null}
          </GridItem>
        )}
        <GridItem>
          <Text fontWeight="bold">
            {getText('global.text.returns', 'Returns')} {formatPrice(outputPrice(returns))}
          </Text>
        </GridItem>
        <GridItem>
          <Text fontWeight="bold" textAlign="right">
            {createdDate.date} {createdDate.time}
          </Text>
        </GridItem>
        {headOfficeOnly && (
          <GridItem colSpan={2}>
            <Text fontSize="md" textAlign="center" color="red.600" fontWeight="bold">
              {getText("account.sentence.claimFromHeadOffice", "Claim your winnings from Leidsa head office.")}
            </Text>
          </GridItem>
        )}
      </Grid>

      <Text marginBottom={2}>
        {getText('account.claimTicketSent', 'Your winning ticket has been emailed to')} <Text as="span" fontWeight="bold">{email}</Text>
      </Text>

      {!mutate.isSuccess ?
        <Text fontSize="xs" color="gray">
          {getText('account.claimTicketNotReceived', 'Not received your winning ticket?')}{" "}
          <Text as="span" cursor="pointer" onClick={() => mutate.mutate({ ticketId, ticketLineId: lineId })}>
            {getText('account.button.clickToSendAgain', 'Click here to send again')}
          </Text>
        </Text>
        :
        <Text fontSize="xs">
          {getText('account.claimTicketRetry', 'Please check your email for your winning ticket')}
        </Text>
      }

      <Box layerStyle="modalActionButtonPlacement">
        <Button size="lg" borderRadius="0" layerStyle="modalActionButton" onClick={closeModal}>
          {getText("global.button.close", "Close")}
        </Button>
      </Box>
    </Box>
  )
}

export default ViewTicket

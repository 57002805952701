import { connect } from "react-redux"
import { Box, Text, Button } from "@chakra-ui/react"

//Load helpers
import { concatDateTime, formatDateTime } from "../../helper/date"
import { useLanguageContext } from "component/Translations/Translations"

const ViewMessage = ({ data, closeModal }) => {
  const { getText } = useLanguageContext()
  return (
    <Box display="flex" flexDirection="column">
      <Text fontSize="lg" marginBottom={4} display="inline-block" fontWeight="bold">
        {data.subject}
      </Text>
      <Box paddingY={4}>
        <Text fontSize="lg">{data.message}</Text>
      </Box>
      <Text fontSize="sm" alignSelf="flex-end" layerStyle="titleBottomBorder" color="textBody">
        {concatDateTime(formatDateTime({ dateSrc: data.createdDate })) || "-"}
      </Text>

      <Box layerStyle="modalActionButtonPlacement">
        <Button id="updatePassword" size="lg" borderRadius="0" layerStyle="modalActionButton" onClick={closeModal}>
          {getText("global.button.close", "Close")}
        </Button>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

export default connect(mapStateToProps)(ViewMessage)

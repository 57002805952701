import { HELP_PENDING, HELP_SET_DATA } from './Help.constants'

const initialState = {
    data: [],
    isPending: false,
    hasError: false
}

const setHelpData = (state, { payload }) => {
    
    // Get the help data
    const { data } = payload
    
    let faqByCategory = {
            'uncategorised': []
        },
        categoryByKey = {
            'uncategorised': {
                name: 'Uncategorised',
                slug: 'uncategorised',
            }
        }
    
    // Do we have the data to sort?
    if (data) {
        for (const { fields: faq, sys: { id: faqId } } of data) {
            
            // Did we have any categories?
            if (faq.categories) {
                for (const category of faq.categories) {
                    
                    // Not a valid category link came back?
                    if (!category.fields) {
                        continue
                    }
                    
                    // If we don't have a category, create one
                    if (typeof categoryByKey[category.fields.slug] === 'undefined') {
                        categoryByKey[category.fields.slug] = category.fields
                    }
                    
                    // Associate the FAQ to the category
                    if (typeof faqByCategory[category.fields.slug] === 'undefined') {
                        faqByCategory[category.fields.slug] = []
                    }
                    
                    // Set a provisional slug we can use
                    if (!faq.slug) {
                        faq.slug = faqId
                    }
                    
                    faqByCategory[category.fields.slug].push(faq)
                }
                continue
            }
            
            // Put them into 'Uncategorised'
            faqByCategory['uncategorised'].push(faq)
        }
        
        // Loop over our categories and sort them A-Z
        const orderedCategories = Object.keys(categoryByKey).sort().reduce((obj, key) => {
            categoryByKey[key].tally = faqByCategory[key].length
            categoryByKey[key].articles = faqByCategory[key]
            obj[key] = categoryByKey[key]
            return obj
        }, {})
        
        // Replace so we're ordered now
        categoryByKey = orderedCategories
    }
    
    return {
        ...state,
        data: categoryByKey,
        isPending: false,
        hasError: false
    }
}

const help = (state = initialState, action = {}) => {
    switch (action.type) {
        case HELP_PENDING:
            return {
                ...state,
                isPending: action.payload.pending || true
            }
        case HELP_SET_DATA:
            return setHelpData(state, action)
        default:
            return state
    }
}

export default help

import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink as ReachLink } from 'react-router-dom'

// Get the right logo
import logo from '../../img/leidsalogoFooter.png'

// Components
import { LayoutContain } from '../../template/LayoutBase.page'
import Text from '../../component/UI/Text/Text'
import {
    Box,
    Image,
    List,
    ListItem,
    IconButton,
    LinkOverlay,
    LinkBox,
    Link,
} from '@chakra-ui/react'
import Icon from 'component/UI/Icon/Icon'
import WLALogo from './WLA_Logo_Color_CMYK_150x90mm_300dpi.png'
import CB from '../../img/CIBELAE.jpg'

// Load hooks
import useContentful from '../../hooks/useContentful'

const year = new Date()

const Footer = () => {
    const isAuthed = useSelector((state) => state.user.isAuthed)

    // Navigation anchor
    const accountLoginLink = isAuthed ? '/account' : '/login'
    //const gallery = useContentful("gallery", "component", "Footer")
    const socials = useContentful('socialMediaIcons', null, null, 'fields.order')
    const whatsApp = useContentful('component', 'name', 'whatsapp')
    const moneyLaundering = useContentful('component', 'name', 'money laundering')

    return (
        <Box layerStyle="footer" backgroundColor="white" py={8}>
            <LayoutContain>
                <Box
                    display={{ base: "flex", lg: "none" }}
                    flexDir="column"
                    alignItems="center"
                    justifyContent="center"
                    mb={{ base: 2, md: 4 }}
                >
                    <Image
                        src={logo}
                        alt="brand logo"
                        mb={2}
                    />
                    <List
                        display="flex"
                        minHeight="40px"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        fontSize="sm"
                    >
                        <ListItem
                            layerStyle="absoluteBorderRight"
                            textAlign="center"
                            position="relative"
                        >
                            <Text
                                as={ReachLink}
                                to="/play"
                                layerStyle="footerLink"
                                scope="global"
                                tag="link.playLotteries"
                                text="Play Lotteries"
                            />
                        </ListItem>
                        <ListItem layerStyle="absoluteBorderRight" textAlign="center">
                            <Text
                                as={ReachLink}
                                to={accountLoginLink}
                                layerStyle="footerLink"
                                tag="link.myAccount"
                                text="My Account"
                            />
                        </ListItem>
                        <ListItem layerStyle="absoluteBorderRight" textAlign="center">
                            <Text
                                as={ReachLink}
                                to="/help/how-to-play"
                                layerStyle="footerLink"
                                scope="global"
                                tag="link.howToPlay"
                                text="How to play?"
                            />
                        </ListItem>
                        <ListItem textAlign="center">
                            <Text
                                as={ReachLink}
                                to="/results"
                                layerStyle="footerLink"
                                scope="global"
                                tag="link.results"
                                text="Results"
                            />
                        </ListItem>
                    </List>
                </Box>

                <Box display="flex">
                    <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
                        <Box display="flex" flexDirection="row" justifyContent={{ base: "center", md: "space-between" }}>
                            <Box
                                display={{ base: "none", lg: "flex" }}
                                flexDirection={{ base: 'column', lg: 'row' }}
                                alignItems="flex-start"

                            >
                                <Image
                                    src={logo}
                                    alt="brand logo"
                                    mb={{ base: 2, lg: 0 }}
                                    mr={{ base: 0, lg: 6 }}
                                />
                                <List
                                    display="flex"
                                    minHeight="40px"
                                    alignItems="center"
                                    width="100%"
                                    fontSize="sm"
                                    flexWrap="wrap"
                                >
                                    <ListItem
                                        layerStyle="absoluteBorderRight"
                                        textAlign="center"
                                        position="relative"
                                    >
                                        <Text
                                            as={ReachLink}
                                            to="/play"
                                            layerStyle="footerLink"
                                            scope="global"
                                            tag="link.playLotteries"
                                            text="Play Lotteries"
                                        />
                                    </ListItem>
                                    <ListItem layerStyle="absoluteBorderRight" textAlign="center">
                                        <Text
                                            as={ReachLink}
                                            to={accountLoginLink}
                                            layerStyle="footerLink"
                                            tag="link.myAccount"
                                            text="My Account"
                                        />
                                    </ListItem>
                                    <ListItem layerStyle="absoluteBorderRight" textAlign="center">
                                        <Text
                                            as={ReachLink}
                                            to="/help/how-to-play"
                                            layerStyle="footerLink"
                                            scope="global"
                                            tag="link.howToPlay"
                                            text="How to play?"
                                        />
                                    </ListItem>
                                    <ListItem textAlign="center">
                                        <Text
                                            as={ReachLink}
                                            to="/results"
                                            layerStyle="footerLink"
                                            scope="global"
                                            tag="link.results"
                                            text="Results"
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                            <Box display="flex" alignItems="center">
                                {socials.data &&
                                    socials.data.map((social) => {
                                        return (
                                            <Box _notLast={{ mr: 2 }}>
                                                <LinkBox>
                                                    <LinkOverlay href={social.fields.url} isExternal>
                                                        <IconButton
                                                            size="xs"
                                                            fontSize="xs"
                                                            borderRadius="md"
                                                            icon={
                                                                <Icon
                                                                    icon={social.fields.social.toLowerCase()}
                                                                />
                                                            }
                                                        />
                                                    </LinkOverlay>
                                                </LinkBox>
                                            </Box>
                                        )
                                    })}
                                {whatsApp?.data?.fields?.active && (
                                    <Box pos="absolute" right="6" bottom="6" cursor="pointer">
                                        <Link href={whatsApp.data.fields.url} target="_blank" isExternal>
                                            <Image
                                                alt={whatsApp.data.fields.img.fields.description}
                                                src={whatsApp.data.fields.img.fields.file.url}
                                            />
                                        </Link>
                                    </Box>
                                )}

                            </Box>
                        </Box>
                        <Box display={{ base: "none", md: "flex" }} justifyContent="space-between">
                            <Text
                                scope="global"
                                fontSize="xs"
                                tag="footer.copyrightPartner"
                                text="{partner} © {year}"
                                data={{ year: year.getFullYear(), partner: 'Leidsa' }}
                                mb={{ base: 2, lg: 0 }}

                            />
                            <Text
                                scope="global"
                                fontSize="xs"
                                tag="text.poweredByProvider"
                                text="Powered by Inspired"

                            />

                        </Box>
                        <Box display={{ base: "flex", md: "none" }} flexDirection="column" mt={6} alignItems="center" justifyContent="center">
                            <Box mb={8} maxWidth="100px">
                                {moneyLaundering?.data?.fields?.active && (
                                    <Box cursor="pointer">
                                        <Link
                                            href={moneyLaundering.data.fields.url}
                                            target="_blank"
                                            isExternal
                                        >
                                            <Image
                                                objectFit="contain"
                                                width="100%"
                                                alt={moneyLaundering.data.fields.img.fields.description}
                                                src={moneyLaundering.data.fields.img.fields.file.url}
                                            />
                                        </Link>
                                    </Box>
                                )}
                                <Box my={4}>
                                    <Image
                                        w="100%"
                                        objectFit="contain"
                                        alt="cibelae"
                                        src={CB}
                                    />
                                </Box>
                                <Box>
                                    <Image
                                        w="100%"
                                        objectFit="contain"
                                        alt="World Lottery Association Logo"
                                        src={WLALogo}
                                    />
                                </Box>
                            </Box>

                            <Box display="flex" justifyContent="space-between">
                                <Text
                                    scope="global"
                                    fontSize="xs"
                                    tag="footer.copyrightPartner"
                                    text="{partner} © {year}"
                                    data={{ year: year.getFullYear(), partner: 'Leidsa' }}
                                    mb={{ base: 2, lg: 0 }}

                                />
                                <Text
                                    scope="global"
                                    fontSize="xs"
                                    tag="text.poweredByProvider"
                                    text="Powered by Inspired"

                                />

                            </Box>
                        </Box>
                    </Box>
                    <Box display={{ base: "none", md: "flex" }} alignItems="center" alignContent="center">
                        {moneyLaundering?.data?.fields?.active && (
                            <Box ml={4} cursor="pointer">
                                <Link
                                    href={moneyLaundering.data.fields.url}
                                    target="_blank"
                                    isExternal
                                >
                                    <Image
                                        objectFit="contain"
                                        width="100%"
                                        maxWidth="70px"
                                        alt={moneyLaundering.data.fields.img.fields.description}
                                        src={moneyLaundering.data.fields.img.fields.file.url}
                                    />
                                </Link>
                            </Box>
                        )}
                        <Box ml={8}>
                            <Image
                                w="100%"
                                maxWidth="100px"
                                objectFit="contain"
                                alt="cibelae"
                                src={CB}
                            />
                        </Box>
                        <Box ml={8}>
                            <Image
                                w="100%"
                                maxWidth="100px"
                                objectFit="contain"
                                alt="World Lottery Association Logo"
                                src={WLALogo}
                            />
                        </Box>
                    </Box>
                </Box>
            </LayoutContain >
        </Box >
    )
}

export default Footer

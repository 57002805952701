import { useState } from "react"
import { connect } from "react-redux"
import * as yup from "yup"

import { Input, FormControl, FormLabel, FormErrorMessage, Button, Stack, Box } from "@chakra-ui/react"
import { Formik, Form, Field } from "formik"

//Load components
import InlineError from "../../Errors/InlineError/InlineError"
import { useLanguageContext } from "component/Translations/Translations"

//Load hooks
import { usePlayerMutate } from "../../../hooks/queries/usePlayer"

const schema = yup.object().shape({
  email: yup
    .string()
    .email({
      code: "ERR20036",
      error: "Invalid Email Address",
    })
    .required({ code: "ERR10010", error: "Required Email" }),

  password: yup.string().required({ code: "ERR22", error: "Password required" }),
})

const UpdateEmail = ({ closeModal, data, handleSuccessNotification }) => {
  const mutatePlayer = usePlayerMutate()
  const [error, setError] = useState(null)
  const { getText } = useLanguageContext()

  return (
    <Box p={1}>
      <InlineError error={error} />
      <Formik
        enableReinitialize
        initialValues={{
          email: data?.email || "",
          reason: "player changed",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={(values, actions) => {
          return mutatePlayer.mutate(
            { values, type: "email" },
            {
              onSuccess: async () => {
                handleSuccessNotification({ message: getText('account.notification.emailUpdated', 'Email Updated')})
                return closeModal()
              },
              onError: async (error) => {
                return setError(error)
              },
              onSettled: () => {
                actions.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(props) => {
          return (
            <Form>
              <Stack spacing={2}>
                <Field name="email" type="email">
                  {({ field, form }) => {
                    const code = form.errors.email?.code
                    const error = form.errors.email?.error
                    return (
                      <FormControl id="email" isInvalid={form.errors.email && form.touched.email}>
                        <FormLabel>{getText("account.formLabel.emailAddress", "Email Address")}</FormLabel>
                        <Input {...field} type="email" name="email" id="email" placeholder={getText(`account.placeholder.enterEmail`, "Enter email")} />
                        <FormErrorMessage>{getText(`Error.${code}`, error)}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>

                <Field name="password" type="password">
                  {({ field, form }) => {
                    const code = form.errors.password?.code
                    const error = form.errors.password?.error
                    return (
                      <FormControl id="password" isInvalid={form.errors.password && form.touched.password}>
                        <FormLabel>{getText("account.formLabel.confirmPassword", "Confirm Password")}</FormLabel>
                        <Input {...field} type="password" name="password" id="password" placeholder={getText(`account.placeholder.enterPassword`, "Enter password")} />
                        <FormErrorMessage>{getText(`Error.${code}`, error)}</FormErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>

                <Box layerStyle="modalActionButtonPlacement">
                  <Button id="updateEmail" size="lg" borderRadius="0" layerStyle="modalActionButton" type="submit" isLoading={props.isSubmitting}>
                    {getText(`account.button.updateEmail`, `Update Email`)}
                  </Button>
                </Box>
              </Stack>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

export default connect(mapStateToProps)(UpdateEmail)

import React from 'react'
import { Box } from '@chakra-ui/react'

// Load Components
import DocumentUpload from './DocumentUpload'
import PageTitle from '../UI/PageTitle/PageTitle'
import ContentCard from '../UI/ContentCard/ContentCard'

// Load FE lib
import { useLanguageContext } from 'component/Translations/Translations'

const ManualVerification = ({ data }) => {
    // Language context
    const {getText} = useLanguageContext()

    return (
        <Box id="verification">
            <PageTitle title={getText('account.title.ageVerification', 'ID and Age Verification')} layerStyle="pageTitleAccount"/>               
            <ContentCard title={getText('account.paragraph.ageVerification', 'Due to gambling legislation we need to verify all accounts. We need some documents to verify your ID and age before you can deposit and play.')}>
                <DocumentUpload data={data}/>
            </ContentCard>
        </Box>
    )
}

export default ManualVerification
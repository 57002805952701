import { call, put, take, all, race, select } from "redux-saga/effects"
import { eventChannel, END, buffers } from "redux-saga"
import { requestBalanceUpdate } from "../User/User.actions"

// Chakra elements
import { createStandaloneToast } from "@chakra-ui/react"

// Chakra theme
import theme from "../../theme/index"

// Load pusher
import Pusher from "react-pusher"

// Websocket actions
import { websocketFail } from "./Websocket.actions"
import { WEBSOCKET_CLOSE_USER, WEBSOCKET_OPEN_USER } from "./Websocket.constants"
import { setNextDrawId, setNextDrawTimestamp, setPreviousDraw } from "../Rapid/Rapid.actions"
import { onBetSuccess, onBetRejected } from "../Checkout/Checkout.actions"
import { requestLogout } from "../User/User.actions"

// Import our selector
import * as selectors from "../selectors.saga"
import { formatPrice } from "../../helper/currency"

// PUT messages onto the channel from the available events
export function createBetChannel(socket) {
  return eventChannel((emit) => {
    const onHandleEvent = (eventType, payload) => {
      emit({
        payload,
        type: eventType,
      })
    }

    // Some pusher error
    const socketError = (errorEvent) => {
      emit(new Error(errorEvent))
    }

    // Bet subscriptions
    socket.bind("onBetSuccessful", (event) => onHandleEvent("onBetSuccessful", event))
    socket.bind("onBetException", (event) => onHandleEvent("onBetException", event))
    //socket.bind("onBetRejected", (event) => onHandleEvent("onBetRejected", event))
    //socket.bind("onBetRefund", (event) => onHandleEvent("onBetRefund", event))
    socket.bind("onBetWin", (event) => onHandleEvent("onBetWin", event))

    // Device subscriptions
    socket.bind("onOpenSession", (event) => onHandleEvent("onOpenSession", event))
    socket.bind("onCloseSession", (event) => onHandleEvent("onCloseSession", event))
    socket.bind("onRefresh", (event) => onHandleEvent("onRefresh", event))

    // Money subscriptions
    socket.bind("onBalanceUpdate", (event) => onHandleEvent("onBalanceUpdate", event))

    // Other callbacks
    socket.bind("error", socketError)

    const unsubscribe = () => {
      Pusher.pusherClient.unsubscribe(socket.name)
      emit(END)
    }

    return unsubscribe
  }, buffers.expanding(10))
}

// PUT messages onto the channel from the available events
export function createRapidChannel(socket) {
  return eventChannel((emit) => {
    const onHandleEvent = (eventType, payload) => {
      emit({
        payload,
        type: eventType,
      })
    }

    // Some pusher error
    const socketError = (errorEvent) => {
      emit(new Error(errorEvent))
    }

    // Draw subscriptions
    socket.bind("onDrawResults", (event) => onHandleEvent("onDrawResults", event))
    socket.bind("onNewDraw", (event) => onHandleEvent("onNewDraw", event))

    // Other subscriptions
    socket.bind("error", socketError)

    const unsubscribe = () => {
      Pusher.pusherClient.unsubscribe(socket.name)
      emit(END)
    }

    return unsubscribe
  }, buffers.expanding(10))
}

// control stop / start channel
function* startUserEventChannel() {
  while (true) {
    const { payload } = yield take(WEBSOCKET_OPEN_USER)
    const { cancel } = yield race({
      task: call(userEventChannel, payload),
      cancel: take(WEBSOCKET_CLOSE_USER),
    })

    // Check if we should now close the user channel
    if (cancel) {
      yield call(userEventChannel, cancel.payload, "unsubscribe")
    }
  }
}

// Create the channel to subscribe to and wait for bet messages
export function* userEventChannel(user, event) {
  // We dont have a user
  if (!user) {
    yield put(requestLogout())
    return
  }

  // Subscribe to a channel
  const socketChannel = yield call(createBetChannel, Pusher.pusherClient.subscribe("user-" + user))

  // User has logged out so terminate tasks and unsubscribe
  if (event === "unsubscribe") {
    return socketChannel.close()
  }

  while (true) {
    try {
      // Wait for messages on the channel
      const event = yield take(socketChannel)

      if (event.type === "onBalanceUpdate") {
        yield put(requestBalanceUpdate())
      }

      if (event.type === "onBetSuccessful") {
        yield put(onBetSuccess(event.payload))
      }

      if (event.type === "onBetException") {
        yield put(onBetRejected(event.payload))
      }

      // Winning bet?
      if (event.type === "onBetWin") {
        const currencyIso = yield select(selectors.currencyIso)

        // Create a toast object
        const toast = createStandaloneToast({
          theme,
        })

        const winningAmount = formatPrice(event.payload.amount / 100, currencyIso),
          divisionName = event.payload.division.name

        // Display a toast value
        toast({
          description: `You won ${winningAmount} on ${divisionName}`,
          title: "Winning bet!",
          duration: 5000,
          isClosable: true,
          position: "top-right",
          status: "success",
        })
      }

      // Refresh the device
      if (event.type === "onRefresh") {
        window.location.reload()
      }
    } catch (error) {
      yield put(websocketFail(error))
    }
  }
}

// Create the channel to subscribe to and wait for bet messages
export function* rapidEventChannel() {
  // Subscribe to a channel
  const socketChannel = yield call(createRapidChannel, Pusher.pusherClient.subscribe("rapids"))

  while (true) {
    try {
      // Wait for messages on the channel
      const event = yield take(socketChannel)

      // We got a new draw, pass on the ID + timestamp
      if (event.type === "onNewDraw") {
        const { id: drawId, gmtDrawTime, lotteryId } = event.payload
        const nextDrawTimestamp = new Date(gmtDrawTime).getTime() / 1000

        yield put(setNextDrawId(drawId, lotteryId))
        yield put(setNextDrawTimestamp(nextDrawTimestamp, lotteryId))
      }

      // We got previous results
      if (event.type === "onDrawResults") {
        // If we get a string back, make sure we convert it to JSON
        if (typeof event.payload.numbers === "string") {
          event.payload.numbers = JSON.parse(event.payload.numbers)
        }

        yield put(setPreviousDraw(event.payload))
      }
    } catch (error) {
      yield put(websocketFail(error))
    }
  }
}

export function* websocketSaga() {
  yield all([call(startUserEventChannel), call(rapidEventChannel)])
}

import useAxios from "../useAxios"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { playerKeys } from './usePlayer'

//Load helpers
import { convertToPence } from "../../helper/currency"

const gatewayKeys = {
  all: [{ scope: "gateway" }],
  cards: ({ id }) => [{ ...gatewayKeys.all[0], entity: "cards", id }],
}

// Get cards
const fetchCards = async ({ axios }) => {
  const response = await axios.get(`player/me/cards`)
  return response.data
}
const useCardsQuery = () => {
  const axios = useAxios()
  return useQuery(gatewayKeys.cards({ id: "me" }), () => fetchCards({ axios }))
}

const activateCard = async ({ axios, body }) => {
  const response = await axios.post('gateway/api/3/activate', body)
  return response.data
}

/**
 * @todo
 * Refatored when more gateways are being used to presere the internal code
 * we should extend with some getDepositType function
 */
const postDeposit = async ({ axios, values }) => {
  let payload
  let id

  // Deposit by pin
  if (values.pin) {
    id = 4
    payload = {
      pin: values.pin,
    }
  }

  // Deposit by card
  if (values.amount) {
    id = 3
    payload = {
      amount: convertToPence(values.amount),
      cardId: Number(values.cardId),
      code: values.otp
    }
  }

  const response = await axios.post(`/gateway/api/${id}/deposit`, payload)
  return response.data
}

const useDepositMutate = () => {
  const axios = useAxios()
  const queryClient = useQueryClient()

  return useMutation(({ values }) => postDeposit({ axios, values }), {
    onSuccess: () => {
      queryClient.invalidateQueries(playerKeys.limit({ id: "me" }))

    }
  })
}

const requestOTP = async ({ axios }) => {
  const response = await axios.post('/gateway/api/requestOTP')
  return response.data
}

const useDepositOTP = () => {
  const axios = useAxios()
  return useMutation(() => requestOTP({ axios }))
}

// Delete card request
const deleteCard = async ({ axios, values }) => {
  const payload = {
    cardId: Number(values.cardId),
  }

  const response = await axios.post(`/gateway/api/3/delete`, payload)
  return response.data
}

const useDeleteCard = () => {
  const axios = useAxios()
  const queryClient = useQueryClient()

  return useMutation(({ values }) => deleteCard({ axios, values }), {
    onSuccess: () => {
      queryClient.invalidateQueries(gatewayKeys.cards({ id: "me" }))
    },
  })
}

const useActivateCardQuery = () => {
  const axios = useAxios()
  const queryClient = useQueryClient()

  return useMutation(({ values }) => activateCard({ axios, body: values }), {
    onSuccess: () => {
      queryClient.invalidateQueries(gatewayKeys.all)
    }
  })
}

const postTransferBalance = async ({ axios, values }) => {
  const payload = { amount: convertToPence(Number(values.amount)) }
  const response = await axios.post(`/gateway/api/1/balance-transfer`, payload)
  return response.data
}

const useTransferBalance = () => {
  const axios = useAxios()
  return useMutation(({ values }) => postTransferBalance({ axios, values }))
}

export { useDepositMutate, useCardsQuery, useDeleteCard, useTransferBalance, useActivateCardQuery, gatewayKeys, useDepositOTP }

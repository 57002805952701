import logger from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

// Load pusher
import { setPusherClient } from 'react-pusher'
import PusherJs from 'pusher-js'

// Load redux store
import rootReducers from '../redux/index.reducer'
import rootSaga from '../redux/index.saga'

// Saga middleware
const sageMiddleware = createSagaMiddleware()

// Set redux extension
const composeEnhancers = composeWithDevTools({
    name: 'lottery-fe'
})

// Create our MWs
const middlewares = [sageMiddleware]

// Run logger in dev mode only
if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
}

/**
 * Redux store
 * undefined => param refers to preloadedState see https://github.com/zalmoxisus/redux-devtools-extension
 */
export const store = createStore(rootReducers, undefined, composeEnhancers(
    applyMiddleware(...middlewares)
));

// Pusher config
const pusherClient = new PusherJs(process.env.REACT_APP_PUSHER_KEY, {
    cluster: process.env.REACT_APP_PUSHER_REGION || 'eu',
})

// Start pusher client
setPusherClient(pusherClient)

// Run all our sagas
sageMiddleware.run(rootSaga)

// Persistent store config
export const persistor = persistStore(store)

// Add persitor actions to store
store.persistor = persistor

const obj = { store, persistor }

export default obj

import React from 'react'
import PropTypes from 'prop-types'

// Load components
import { Text, Box, Image } from '@chakra-ui/react'
import Icon from '../Icon/Icon'

const PageTitle = ({
    title,
    icon = false,
    imgSrc = false,
    imgAlt,
    layerStyle = 'titleBottomBorder',
    ...props
}) => {
    return (
        <Box layerStyle={layerStyle} mb={4} display="flex" alignItems="center">
            {icon && (
                <Box fontSize="lg" marginRight={2} as="span">
                    <Icon icon={icon} />
                </Box>
            )}
            <Text as="h2" color="inherit" fontSize="24px" flexGrow={1} {...props}>
                {title}
            </Text>
            {imgSrc && <Image maxW="100px" src={imgSrc} alt={imgAlt} />}
        </Box>
    )
}

PageTitle.propTypes = {
    /**
     * Title text
     */
    title: PropTypes.string.isRequired,
    /**
     * Icon to render if set
     */
    icon: PropTypes.string,
    /**
     * Show right side img
     */
    imgSrc: PropTypes.string
}

export default PageTitle

import { 
    REQUEST_CREATEBET_START, 
    REQUEST_CREATEBET_SUCCESS, 
    REQUEST_CREATEBET_FAIL, 
    CHECKOUT_ON_BET_SUCCESS, 
    CHECKOUT_ON_BET_REJECTED, 
    CLEAR_BET_ERRORS 
} from './Checkout.constants'

export const requestCreateBetStart = (data) => {
    return {
        type: REQUEST_CREATEBET_START,
        payload: data
    }
}

export const requestCreateBetSuccess = (reply) => ({
    type: REQUEST_CREATEBET_SUCCESS,
    payload: reply
})

export const requestCreateBetFail = (error) => ({
    type: REQUEST_CREATEBET_FAIL,
    payload: error
})

export const clearBetErrors = () => ({
    type: CLEAR_BET_ERRORS,
})

export const onBetSuccess = (response) => ({
    type: CHECKOUT_ON_BET_SUCCESS,
    payload: response
})

export const onBetRejected = (response) => ({
    type: CHECKOUT_ON_BET_REJECTED,
    payload: response
})
import { connect } from "react-redux"
import "react-phone-number-input/style.css"
import * as yup from "yup"

import { Input, FormControl, FormLabel, FormErrorMessage, Button, Stack, Box, Tabs, TabList, TabPanel, TabPanels, Tab } from "@chakra-ui/react"
import { Formik, Form, Field } from "formik"
import PhoneInput, { parsePhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input"

//Load hooks
import { usePlayerMutate } from "../../../hooks/queries/usePlayer"
import { useState } from "react"
import InlineError from "../../Errors/InlineError/InlineError"
import { useLanguageContext } from "component/Translations/Translations"

const contact = yup.object().shape({
  contactNumber: yup
    .string()
    .required({ code: "ERR10016", error: "Required Phone Number" })
    .test("validate-phonenumber", { code: "ERR20035", error: "Invalid phone number" }, (value) => {
      const isValid = value ? isPossiblePhoneNumber(value) : false
      return isValid
    }),
  password: yup.string().required({
    code: "ERR22",
    error: "Password is required",
  }),
})

const mobile = yup.object().shape({
  mobileNumber: yup
    .string()
    .required({ code: "ERR10016", error: "Required Phone Number" })
    .test("validate-phonenumber", { code: "ERR20035", error: "Invalid phone number" }, (value) => {
      const isValid = value ? isPossiblePhoneNumber(value) : false
      return isValid
    }),
  password: yup.string().required({
    code: "ERR22",
    error: "Password is required",
  }),
})

const PhoneForm = ({ name, title, type, label, schema, mutateContact, value, closeModal, handleNotification }) => {
  const [error, setError] = useState(null)
  const { getText } = useLanguageContext()
  return (
    <Formik
      enableReinitialize
      initialValues={{
        [name]: value || "",
        password: "",
      }}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        const number = parsePhoneNumber(values[name])
        return mutateContact.mutate(
          {
            values: {
              reason: "player changed",
              [name]: number.nationalNumber,
              phoneNumberCode: `+${number.countryCallingCode}`,
              password: values.password,
            },
            type,
          },
          {
            onSuccess: () => {
              handleNotification({ message: getText(`account.notification.contactUpdated`, `Contact Updated`) })
              closeModal()
            },
            onError: (error) => {
              setError(error)
            },
            onSettled: () => {
              actions.setSubmitting(false)
            },
          }
        )
      }}
    >
      {(props) => {
        return (
          <Form>
            <InlineError error={error} />
            <Stack spacing={3}>
              <Field name={name} type="text">
                {({ form, field }) => {
                  const code = form.errors[name]?.code
                  const error = form.errors[name]?.error
                  return (
                    <FormControl id={name} isInvalid={form.errors[name] && form.touched[name]}>
                      <FormLabel htmlFor={name}>{label}</FormLabel>
                      <PhoneInput {...field} onChange={(e) => form.setFieldValue(name, typeof e === "undefined" ? "" : e)} inputComponent={Input} initialValueFormat="national" name={name} id={name} placeholder={getText(`account.placeholder.enterPhoneNumber`, "Enter phone number")} />
                      <FormErrorMessage>{getText(`Error.${code}`, error)}</FormErrorMessage>
                    </FormControl>
                  )
                }}
              </Field>

              <Field name="password" type="password">
                {({ field, form }) => {
                  const code = form.errors.password?.code
                  const error = form.errors.password?.error

                  return (
                    <FormControl id="password" isInvalid={form.errors.password && form.touched.password}>
                      <FormLabel>{getText("account.formLabel.confirmPassword", "Confirm Password")}</FormLabel>
                      <Input {...field} type="password" name="password" id="password" placeholder={getText(`account.placeholder.enterPassword`, "Enter password")} />
                      <FormErrorMessage>{getText(`Error.${code}`, error)}</FormErrorMessage>
                    </FormControl>
                  )
                }}
              </Field>
              <Box layerStyle="modalActionButtonPlacement">
                <Button id={`update${type}`} size="lg" borderRadius="0" layerStyle="modalActionButton" type="submit" isLoading={props.isSubmitting}>
                  {getText(`account.button.update${title}`, `Update ${title}`)}
                </Button>
              </Box>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

const UpdateContact = ({ closeModal, data, handleSuccessNotification }) => {
  const mutatePlayer = usePlayerMutate()
  const { getText } = useLanguageContext()
  return (
    <Tabs isLazy pos="initial">
      <TabList>
        <Tab>{getText("account.tab.phone", "Phone")}</Tab>
        <Tab>{getText("account.tab.mobile", "Mobile")}</Tab>
      </TabList>

      <TabPanels>
        <TabPanel>
          <PhoneForm handleNotification={handleSuccessNotification} title="Phone" name="contactNumber" type="contact" value={data.contactNumber} label={getText("account.label.phone", "Phone Number")} schema={contact} mutateContact={mutatePlayer} closeModal={closeModal} />
        </TabPanel>
        <TabPanel>
          <PhoneForm handleNotification={handleSuccessNotification} title="Mobile" name="mobileNumber" type="mobile" value={data.mobileNumber} label={getText("account.label.mobile", "Mobile Number")} schema={mobile} mutateContact={mutatePlayer} closeModal={closeModal} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

export default connect(mapStateToProps)(UpdateContact)

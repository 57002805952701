import React from 'react'

// Chakra imports
import { Modal as ChakraModal, StylesProvider, useMultiStyleConfig } from "@chakra-ui/react"

export const ModalStyles = {
    parts: ['dialog'],
    baseStyle: {
        dialog: {
            borderRadius: "25px",
        },
    },
    defaultProps: {
        scrollBehavior: "inside",
    }
}

export const Modal = (props) => {
    const { size, variant, children, ...rest } = props
    const styles = useMultiStyleConfig("Modal", { size, variant, ...rest })
    
    return (
        <ChakraModal {...rest}>
            <StylesProvider value={styles}>
                {children}
            </StylesProvider>
        </ChakraModal>
    )
}
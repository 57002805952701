/**
 * Notification Types
 * 
 * open: opens notification
 * close: closes notification
 * 
 * success: Operation success status 200
 * error: Operation returned an error or warnings
 */
const notificationConstants = {
  OPEN_NOTIFICATION: 'OPEN_NOTIFICATION',
  CLOSE_NOTIFICATION: 'CLOSE_NOTIFICATION',
  NOTIFICATION_SUCCESS: 'success',
  NOTIFICATION_WARNING: 'warning',
  NOTIFICATION_ERROR: 'error',
}

export default notificationConstants

import { useEffect, useState } from 'react'

// Contentful client helper
import { getContent } from '../helper/contentful'

// FE Library
import { useLanguageContext } from 'component/Translations/Translations'

function useContentful(model, field = null, value = null, order = null, params = null) {
    // Translation hook
    const { getText } = useLanguageContext()

    // Store contentful data and deal with loading
    const [data, setData] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false)

    // Get the locale value
    const locale = getText('global.Lottery.Contentful.Language', 'en-US')

    useEffect(() => {
        // Reset our error state
        setError(false)
        setLoading(true)

        // Fetch the Contentful API data
        async function fetchData() {
            const data = await getContent({ locale, model, field, value, order, params })

            // Clear the payload
            setLoading(false)

            // Did we fail to get any data?
            if (data === null) {
                setError(true)
                return
            }

            setData(data)
        }

        // Run our method above
        fetchData()
    }, [locale, model, field, value, order, params])

    return {
        isError,
        isLoading,
        data
    }
}

export default useContentful

import { switchAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  switchAnatomy.keys
)

const baseStyleThumb = defineStyle({
  bg: 'white',
  transitionProperty: 'transform',
  transitionDuration: 'normal',
  borderRadius: 'inherit',
})

const baseStyle = definePartsStyle({
  thumb: baseStyleThumb,
  track: {
    bg: 'neutral.800',
    _checked: {
      bg: 'primary.900',
    },
  },
})

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: {
    size: 'lg',
  },
})

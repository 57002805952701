import { extendTheme, withDefaultProps, theme } from '@chakra-ui/react'

// Load Theme config
import styles from './styles/global'
import components from './components/index'
import { fontSizes, radii } from './styles/sizes'
import {
    fonts,
    footerLegalText,
    sideDrawerLinks,
    heroText,
    cmsParagraph,
    tableCellHighlightColor,
    titleTextAlt,
    lotomasTotalText,
    headingFontFamily,
    semiBoldFontFamily,
    bannerSpanText,
    winCardLeftColText
} from './foundations/typography'
import { elements } from './foundations/index'
import colors from './styles/colors'

// Theme overides
import sportech from './partners/sportech/index'
import leidsa from './partners/leidsa/index'

const themeOverrides = {
    sportech,
    rapids: sportech,
    leidsa
}

const breakpoints = {
    ...theme.breakpoints,
    xs: '340px'
}

// // Look up the color palette
const themeLookUp = process.env.REACT_APP_PARTNER

/**
 * Default base setup is Leidsa style
 */
const overrides = {
    styles,
    colors,
    components,
    fonts,
    fontSizes,
    radii,
    textStyles: {
        footerLegalText,
        sideDrawerLinks,
        heroText,
        cmsParagraph,
        tableCellHighlightColor,
        lotomasTotalText,
        titleTextAlt,
        headingFontFamily,
        semiBoldFontFamily,
        bannerSpanText,
        winCardLeftColText
    },
    layerStyles: {
        ...elements,
        menuIcon: {
            width: 4,
            textAlign: 'center',
            mr: 2
        }
    },
    breakpoints
}

// Merge a `source` object to a `target` recursively
const merge = (target, source) => {
    /**
     * Return base if we dont have a source to merge
     */
    if (!source) {
        return target
    }

    // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
    for (const key of Object.keys(source)) {
        if (source[key] instanceof Object && key in target) {
            Object.assign(source[key], merge(target[key], source[key]))
        }
    }

    // Join `target` and modified `source`
    Object.assign(target || {}, source)
    return target
}

const withColorScheme = withDefaultProps({
    defaultProps: {
        colorScheme: 'card',
        size: 'sm',
        variant: 'top'
    },
    components: ['Card']
})

export default extendTheme(merge(overrides, themeOverrides[themeLookUp]), withColorScheme)

/**
 * For full infomration on availbe size and access see
 *
 * https://chakra-ui.com/theme
 *
 */

import cache from 'helper/cache'

const lazyRetry = function (componentImport) {
    return new Promise((resolve, reject) => {
        const storeKey = 'retry-lazy-refresh'
        const hasRefreshed = cache.get(storeKey)

        componentImport().then((component) => {
            cache.set(storeKey, false)
            resolve(component)
        }).catch((error) => {
            if (!hasRefreshed) {
                cache.set(storeKey, true)
                return window.location.reload()
            }
            reject(error)
        })

    })
}

export default lazyRetry
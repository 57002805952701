import { select, take } from "redux-saga/effects"
import { isValidJwt } from "./jwt"

export function* waitFor(selector, isToken = true) {
  let data = yield select(selector)

  // Are we in the store by default?
  if (data && typeof data !== "undefined" && ((isToken && isValidJwt(data)) || !isToken)) {
    return
  }

  while (true) {
    yield take("*")

    // Re-query our selector
    data = yield select(selector)

    // Make sure the data is defined
    if (data && typeof data !== "undefined" && ((isToken && isValidJwt(data)) || !isToken)) {
      return
    }
  }
}

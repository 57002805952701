import { useState } from "react"
import { connect } from "react-redux"
import * as yup from "yup"

//Load components
import { Box, Button, Text, Input, FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react"
import Icon from "../../UI/Icon/Icon"
import InlineError from "../../Errors/InlineError/InlineError"
import { Formik, Form, Field } from "formik"

//Load hooks
import { useActivateCardQuery } from "../../../hooks/queries/useGateway"
import { useLanguageContext } from "component/Translations/Translations"

const activateSchema = yup.object().shape({
    cardId: yup.number().required(),
    code: yup.string().required()
})

const ActivateCard = ({ data, closeModal }) => {
    const mutation = useActivateCardQuery()
    const [error, setError] = useState(null)
    const { getText } = useLanguageContext()

    return (
        <>
            <InlineError error={error} />
            <Formik
                initialValues={{
                    cardId: data.id,
                    code: ''
                }}
                validationSchema={activateSchema}
                onSubmit={(values, actions) => {
                    return mutation.mutate(
                        { values },
                        {
                            onSuccess: async () => {
                                return closeModal()
                            },
                            onError: async (error) => {
                                setError(error)
                            },
                            onSettled: () => {
                                actions.setSubmitting(false)
                            },
                        }
                    )
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Box display="grid" gridTemplateColumns="1fr">
                                <Box display="flex" alignItems="center" mb={4}>
                                    <Icon icon="creditCard" size="lg" />
                                    <Text ml={2}>{`${data.cardType} *${data.cardNumber || data.anonymousCard}`}</Text>
                                </Box>
                                <Field name="code">
                                    {({ form, field }) => (
                                        <FormControl id="code" isInvalid={form.errors.cardId && form.touched.cardId}>
                                            <FormLabel>
                                                {getText("account.sentence.pleaseEnterActivationCode", "Please enter your activation code below:")}
                                            </FormLabel>
                                            <Input {...field} />
                                            <FormErrorMessage justifyContent="flex-end" textAlign="right">
                                                {getText("Error.ERR20028", 'Invalid Input')}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Box layerStyle="modalActionButtonPlacement">
                                    <Button type="submit" size="lg" borderRadius="0" layerStyle="modalActionButton" color="white" isLoading={props.isSubmitting || mutation.isLoading} colorScheme="buttonError">
                                        {getText("global.button.confirm", "Confirm")}
                                    </Button>
                                </Box>
                            </Box>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

const mapStateToProps = (state) => ({
    data: state.modal.data,
})

export default connect(mapStateToProps)(ActivateCard)
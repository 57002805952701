const playCard = {
    boxShadow: 'none'
}

const searchFiltersContainer = {
    background: 'contentCard.backgroundColor',
    p: 6,
    borderRadius: 'md',
    boxShadow: 'lg'
}

const iconColor = {
    backgroundColor: 'iconPrimary',
    color: 'white'
}

export const pageTitleAccount = {
    color: 'pageTitleAccount.color'
}

/**
 * Header background and text
 */
const header = {
    background: 'headerBackground',
    color: 'primaryHeaderColor'
}

/**
 * footer links
 */
const footerLink = {
    fontWeight: 'medium',
    fontSize: 'xs',
    color: 'primaryTextColor',
    _activeLink: {
        fontWeight: 'bold',
        color: 'primaryTextColor'
    }
}

/**
 * Lottery lines board provider styles
 */
const lotteryProviderBoard = {
    boxShadow: 'none',
    borderRadius: '0',
    background: 'transparent'
}

export const modalActionButton = {
    fontSize: 'md',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'primary.900',
    '&:hover': {
        backgroundColor: 'primary.925'
    }
}

const layerStyles = {
    playCard,
    header,
    iconColor,
    footerLink,
    searchFiltersContainer,
    pageTitleAccount,
    lotteryProviderBoard,
    modalActionButton
}

export default layerStyles

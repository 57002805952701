import React, { useState } from "react"
import { useSelector } from "react-redux"

// Load components
import { Box, Text, Button, Image } from "@chakra-ui/react"
import { Formik, Form } from "formik"
import { getCdnUrl } from "../../../../helper/cdn"
import InlineError from "../../../Errors/InlineError"
import { useLanguageContext } from "component/Translations/Translations"
import { useDepositMutation } from "../../../../hooks/queries/useLotteries"

// Load helpers
import { formatPrice } from "../../../../helper/currency"

const outputPrice = (number) => {
  if (!number) {
    return 0
  }
  return (number / 100)
}

const Deposit = ({ closeModal }) => {
  const { getText } = useLanguageContext()
  const data = useSelector((state) => state.modal.data)
  const [error, setError] = useState()
  const mutateDeposit = useDepositMutation()
  const { line } = data ?? {}

  const winning = line.winning
  const returns = winning?.amount ?? 0
  const gameSettings = line?.gameComponent?.lottery?.logo //ticket?.booking?.game?.gameSettings[0]?.fileName

  return (
    <>
      <InlineError error={error} />
      <Formik
        initialValues={{ ticketLineId: line.id }}
        onSubmit={(values, actions) => {
          return mutateDeposit.mutate(
            { values },
            {
              onSuccess: async () => {
                return closeModal()
              },
              onError: async (error) => {
                setError(error)
              },
              onSettled: () => {
                actions.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(props) => {
          return (
            <Form>
              <Box display="flex" flexDirection="column" alignItems="center">
                {gameSettings && (
                  <Box mb={4}>
                    <Image maxWidth="160px" alt={line.gameComponent.lottery?.displayName} src={getCdnUrl(gameSettings)} />
                  </Box>
                )}
                <Text fontSize="md" mb={4} textAlign="center" fontWeight="bold">
                  {getText("account.text.youWon", "Congratulations you won!")} {formatPrice(outputPrice(returns))}
                </Text>
                <Text fontSize="md" textAlign="center">
                  {getText("account.sentence.depositToAccount", "Confirm to deposit your winnings and add them to your account.")}
                </Text>

                <Box layerStyle="modalActionButtonPlacement">
                  <Button size="lg" borderRadius="0" layerStyle="modalActionButton" type="submit" isLoading={props.isSubmitting}>
                    {getText("Account.DepositWin.Modal.Title", "Deposit winnings")}
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default Deposit

import modalConstants from './Modal.constants'

const initialState = {
  key: null,
  data: {},
  modalConfig: {}
}

const modal = (state = initialState, action = {}) => {
  switch (action.type) {
    case modalConstants.OPEN_MODAL:
      return {
        ...state,
        key: action.modal,
        data: action.data || {},
        modalConfig: action.modalConfig || {}
      }
    case modalConstants.CLOSE_MODAL:
      return {
        ...state,
        key: null,
        data: {},
        modalConfig: {}
      }
    default:
      return state
  }
}

export default modal

// Chakra components
import { tableTheme as Table } from "./Table/Table"
import { lotteryBallTheme as LotteryBall } from "./LotteryBall/LotteryBall"
import { inputTheme as Input } from "./Input/input"
import { selectTheme as Select } from "./Input/select"
import { buttonTheme as Button } from "./Button/Button"
import { ModalStyles as Modal } from "./Modal/Modal"
import { playCardTheme as PlayCard } from "./PlayCard/playCard"
import { switchTheme as Switch } from "./Button/switchTheme"
import { FormLabel } from "@chakra-ui/react"
import { accordionTheme as Accordion } from './Accordion/Accordion'

/**
 * Component overrides and Custom components
 */
const components = {
  Button,
  Input,
  Table,
  LotteryBall,
  Select,
  Modal,
  PlayCard,
  Switch,
  Accordion,
  FormLabel: {
    ...FormLabel,
    variants: {
      ...FormLabel.variants,
      search: {
        fontWeight: "semibold",
      },
    },
    defaultProps: {
      variant: "search",
    },
  },
}

export default components

import React from 'react'
import { connect } from 'react-redux'

// Load components
import { Select } from '@chakra-ui/react'

// Load actions
import { setLanguage } from '../../redux/User/User.actions'

// FE Library
import { useLanguageContext } from 'component/Translations/Translations'

const LanguageDisplay = ({ selected, onChangeLanguage, ...props }) => {
    // Get translations
    const { getText } = useLanguageContext()

    const options = [
        {
            isoCode: 'en',
            name: getText('global.language.en', 'English')
        },
        {
            isoCode: 'es',
            name: getText('global.language.es', 'Spanish')
        }
    ]

    return (
        <Select
            value={selected}
            variant="unstyled"
            color="white"
            opacity="0.8"
            textStyle="semiBoldFontFamily"
            fontSize="sm"
            cursor="pointer"
            onChange={(e) => onChangeLanguage(e.target.value)}
            id="countries"
            name="countries"
            {...props}
        >
            {options.map((option) => {
                return (
                    <option key={option.isoCode} value={option.isoCode} style={{ color: 'black' }}>
                        {option.name}
                    </option>
                )
            })}
        </Select>
    )
}

const mapStateToProps = (state) => {
    return {
        selected: state.user.language
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onChangeLanguage: (language) => dispatch(setLanguage(language))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDisplay)

/**
 * Page segment layer styles
 */
export const footer = {
    background: 'footerBackground',
    color: 'neutral.900'
}

/**
 * Header background and text
 */
export const header = {
    background: 'white',
    color: 'primaryLinkColor'
}

/**
 * Primary link header
 */
export const headerLink = {
    fontFamily: 'Body Semibold',
    textTransform: 'upperCase',
    color: 'primaryLinkColor',
    whiteSpace: 'nowrap',
    opacity: '0.8',
    _activeLink: {
        fontWeight: 'bold',
        color: 'primaryLinkColorActive',
        opacity: '1'
    }
}

/**
 * footer links
 */
/**
 * footer links
 */
export const footerLink = {
    fontWeight: 'medium',
    fontSize: 'xs',
    color: 'primaryTextColor',
    _activeLink: {
        fontWeight: 'bold',
        color: 'primaryTextColor'
    }
}

/**
 * Jackpot link text
 */
export const jackpotHighlight = {
    borderRadius: '3xl',
    paddingY: 1,
    paddingX: 2,
    backgroundColor: 'secondary.900',
    color: 'primaryTextColor',
    fontSize: 'sm'
}

/**
 * clip text with ellipsis. Content may need max width if it does not work
 */
export const textExclipse = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
}

export const headerCallout = {
    background: 'secondary.900',
    color: 'primaryTextColor',
    padding: 4,
    fontWeight: 'bold',
    textAlign: 'center'
}

/**
 * plus/minus icons for bet increments
 */
export const betIncrementIcons = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    h: '20px',
    w: '20px'
}

/**
 * Round box layerstyle with border
 */
export const roundBoxBorderContainer = {
    border: '1px',
    borderRadius: 'xl'
}

/**
 * Border bottom for title text
 */
export const titleBottomBorder = {
    borderBottom: '2px solid',
    paddingBottom: 2,
    borderColor: 'pageTitle.border',
    color: 'pageTitle.color'
}

/**
 * Account menu title
 */
export const subMenuHeader = {
    fontFamily: 'Body Semibold',
    fontSize: 'xs',
    color: 'accountMenu.headerTextColor',
    mb: 4,
    textTransform: 'uppercase'
}

/**
 * Lottery lines board provider styles
 */
export const lotteryProviderBoard = {
    boxShadow: 'md',
    borderRadius: 'lg',
    background: 'white'
}

/**
 * Account menu item
 */
export const submenuLink = {
    display: 'flex',
    cursor: 'point',
    fontWeight: 500,
    mb: 2,
    _activeLink: {
        color: 'secondaryLinkColorActive',
        fontFamily: 'Body Semibold'
    }
}

export const modalActionButtonPlacement = {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
}

export const modalActionButton = {
    fontSize: 'md',
    width: '100%',
    maxWidth: '100%'
}

export const playerCard = {
    boxShadow: 'lg'
}

/**
 * Choose stake quick buttons
 */
export const quickPickButtons = {
    borderRadius: '3xl'
}

/**
 * Border and Color winner tiles homepage
 */
export const winnerTileContainer = {
    p: 4,
    borderRadius: 'md',
    border: '1px solid',
    borderColor: 'borderPrimaryLight'
}

/**
 * Spacing Styles for login Card segments
 */
export const loginSegmentSpacing = {
    px: { base: 6, md: 12 },
    py: { base: 8, md: 16 },
    width: { base: '100%', md: '50%' }
}

/**
 * Round with shadow
 */
export const containerRoundShadow = {
    borderRadius: 'xl',
    boxShadow: '2xl',
    overflow: 'hidden'
}

/**
 * Create circle shape with thick border
 */
export const circleThickBorder = {
    border: '6px solid',
    borderRadius: '50%',
    overflow: 'hidden'
}

/**
 * Border right absolute
 */
export const absoluteBorderRight = {
    position: 'relative',
    _notLast: {
        mr: 4
    },
    _after: {
        position: 'absolute',
        content: "''",
        top: '50%',
        right: '-8px',
        backgroundColor: 'borderPrimary',
        width: '1px',
        height: '90%',
        transform: 'translateY(-50%)'
    }
}

import { all, call } from "redux-saga/effects"
import { tokenSaga } from "./Token/Token.saga"
import { userSaga } from "./User/User.saga"
import { websocketSaga } from "./Websocket/Websocket.saga"
import { checkoutSaga } from "./Checkout/Checkout.saga"

function* rootSaga() {
  yield all([call(checkoutSaga), call(tokenSaga), call(userSaga), call(websocketSaga)])
}

export default rootSaga

import useAxios from '../useAxios'
import { useQuery, useMutation, useQueryClient } from 'react-query'

const playerKeys = {
  all: [{ scope: 'player' }],
  details: ({ id }) => [{ ...playerKeys.all[0], entity: 'details', id }],
  limit: ({ id }) => [{ ...playerKeys.all[0], entity: 'limit', id }],
}

const fetchPlayer = async ({ axios }) => {
  const response = await axios.get(`player/me`)
  return response.data
}
const usePlayerQuery = () => {
  const axios = useAxios()

  return useQuery(playerKeys.details({ id: 'me' }), () => fetchPlayer({ axios }))
}

const putPlayer = async ({ axios, body, type }) => {
  const response = await axios.put(`player/me/${type}`, body)
  return response.data
}

const fetchDepositLimit = async ({ axios }) => {
  const response = await axios.get(`player/me/remaining-limit`)
  return response.data
}

const usePlayerMutate = () => {
  const axios = useAxios()
  const queryClient = useQueryClient()

  return useMutation(({ values, type }) => putPlayer({ axios, body: values, type }), {
    onSuccess: () => {
      queryClient.invalidateQueries(playerKeys.details({ id: 'me' }))
    },
  })
}

/**
 * email
 * sessionToken
 */
async function postOTPResend({ axios, body }) {
  const response = await axios.post('user/auth/otp/resend', body)
  return response.data

}

const usePlayerResendOTP = () => {
  const axios = useAxios()
  return useMutation((body) => postOTPResend({ axios, body }))
}

const useDepositLimit = () => {
  const axios = useAxios()

  return useQuery(playerKeys.limit({ id: 'me' }), () => fetchDepositLimit({ axios }))
}

export { playerKeys, usePlayerQuery, usePlayerMutate, useDepositLimit, usePlayerResendOTP }

import React, { useState } from "react"
import * as yup from "yup"

// Load components
import { Box, Grid, GridItem, Text, Button, FormControl, FormErrorMessage } from '@chakra-ui/react'
import { Formik, Form, Field } from "formik"
import InlineError from "../../component/Errors/InlineError/InlineError"

// Load FE lib
import { useLanguageContext } from 'component/Translations/Translations'
import FileImport from 'component/UI/FileImport/FileImport'

// Load hooks
import { useKycUploadMutation } from 'hooks/queries/useKYC'

const fileUploadSchema = yup.object().shape({
  file: yup.mixed().test("FILE_SIZE", "File size can be no larger than 15MB", value => !value || (value && value.size <= 15 * 1048576))
})

const DocumentUpload = ({ data }) => {
  const { getText } = useLanguageContext()
  const [error, setError] = useState(null)
  const mutateKycUpload = useKycUploadMutation()

  const claimId = data?.[0].id

  return (
    <Formik
      initialValues={{ files: "" }}
      validationSchema={fileUploadSchema}
      onSubmit={(values, actions) => {
        setError(null)

        //const readForm = new FormData()
        //readForm.append("files", values.file)

        return mutateKycUpload.mutate(
          { values, claimId },
          {
            onSuccess: async () => {
              return
            },
            onError: async (error) => {
              return setError(error)
            },
            onSettled: () => {
              actions.setSubmitting(false)
            },
          }
        )
      }}
    >
      {(props) => {
        return (
          <Form id="file">
            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} textAlign={{ base: "center", md: 0 }}>
              <GridItem pr={10} borderBottom={{ base: "1px solid", md: 0 }} mt={5}>
                <Text as="h3" mb={4}>
                  {getText("account.text.documentUpload", "Document Upload")}
                </Text>
                <Text mb={{ base: 8, md: 0 }}>{getText("account.text.nationalIdCard", "National ID Card")}</Text>
              </GridItem>
              <Field name="files">
                {({ form, field }) => {
                  return (
                    <GridItem borderLeft={{ base: 0, md: "1px solid" }} >
                      <Box padding={6} alignSelf="center">
                        <FormControl
                          id="files"
                          isInvalid={form.errors.file && form.touched.file}

                        >
                          <Box id="files">
                            <FileImport
                              file={field.value}
                              label={getText("account.label.photoId", "Photo ID")}
                              details={getText("account.text.checkUpToDate", "(check it's up to date)")}
                              uploadText={getText("account.button.upload", "Upload")}
                              onChangeHandler={(e) => form.setFieldValue("files", e.currentTarget.files[0])}
                              onRemoveHandler={() => form.setFieldValue("files", "")}
                            />
                          </Box>
                          <FormErrorMessage mb={{ base: 2, md: 0 }}>
                            {form.errors.file}
                          </FormErrorMessage>
                          
                          {/* If file exists show submit button  */}
                          { field.value && <Box display="flex" justifyContent={{ base: "center", md: "flex-end" }} >
                            <Button colorScheme="buttonPrimary" type="submit" isLoading={props.isSubmitting}>
                              {getText("account.button.submit", "Submit")}
                            </Button>
                          </Box> }

                          <Box textAlign={{ base: "center", md: 0 }}>
                            <InlineError error={error} mt={2} />
                          </Box>
                        </FormControl>
                      </Box>
                    </GridItem>
                  )
                }}
              </Field>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}


export default DocumentUpload
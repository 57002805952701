import { Global } from '@emotion/react'
const Fonts = () => (
    <Global
        styles={`
        /* latin */
        @font-face {
          font-family: 'Body Regular';
          font-style: normal;
          font-weight: 500;
          font-display: swap;
          src: url('/fonts/Montserrat-Medium.woff2') format('woff2'), url('./fonts/Montserrat-Medium.woff') format('woff');
        }
        /* latin */
        @font-face {
          font-family: 'Body Semibold';
          font-style: normal;
          font-weight: 600;
          font-display: swap;
          src: url('/fonts/Montserrat-SemiBold.woff2') format('woff2'), url('./fonts/Montserrat-SemiBold.woff') format('woff');
        }
        /* latin */
        @font-face {
          font-family: 'Heading Bold';
          font-style: normal;
          font-weight: 700;
          font-display: swap;
          src: url('/fonts/Montserrat-Bold.woff2') format('woff2'), url('./fonts/Montserrat-Bold.woff') format('woff');
        }
        `}
    />
)

export default Fonts

import React from "react"

// Load components
import { Alert, AlertIcon, UnorderedList, ListItem } from "@chakra-ui/react"

// Load helper
import { parseError } from "../../helper/error"
import { useLanguageContext } from "component/Translations/Translations"

const InlineError = ({ error, ...props }) => {
  const { getText } = useLanguageContext()
  if (!error) {
    return null
  }

  if (error.warnings) {
    return (
      <Alert status="error" my={4} fontSize="12px" {...props}>
        <UnorderedList style={{ columnCount: 2 }}>
          {error.warnings.map((warning) => {
            return (
              <ListItem fontSize="12px" key={warning.code}>
                {warning.field} - {getText(warning.code, warning.error)}
              </ListItem>
            )
          })}
        </UnorderedList>
      </Alert>
    )
  }

  return (
    <Alert status="error" my={4} fontSize="12px" {...props}>
      <AlertIcon />
      {error?.code ? getText(`Error.${error.code}`, error.error) : parseError(error)}
    </Alert>
  )
}

export default InlineError

import notificationConstants from './notification.constants'

const initialState = {
  status: null,
  message: null,
  navigate: null,
  allowDismiss: true
}

const notificationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case notificationConstants.OPEN_NOTIFICATION:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status,
        navigate: action.payload.navigate,
        allowDismiss: action.payload.allowDismiss
      }
    case notificationConstants.CLOSE_NOTIFICATION:
      return initialState
    default:
      return state
  }
}

export default notificationReducer

import React from 'react'
import { NavLink as ReachLink } from 'react-router-dom'

// Load components
import { Box, List, ListItem } from '@chakra-ui/react'
import Text from '../../UI/Text/Text'
import LanguageDisplay from 'component/LanguageDisplay/LanguageDisplay'
import { LayoutContain } from 'template/LayoutBase.page'
import SideDrawer from '../SideDrawer/SideDrawer'
import UserButton from 'component/UI/Buttons/UserButton/UserButton'

const MainNavigation = () => {
    return (
        <LayoutContain paddingX={{ base: 8 }} backgroundColor="navigationBar.backroundColor">
            <Box as="nav" aria-label="main" display={{ base: 'none', lg: 'flex' }} flexDir="column">
                <List
                    display="flex"
                    minHeight="40px"
                    width="100%"
                    alignItems="center"
                    justifyContent="space-between"
                    fontSize="sm"
                >
                    <ListItem textAlign="center">
                        <Text
                            as={ReachLink}
                            to="/"
                            exact
                            layerStyle="headerLink"
                            scope="global"
                            tag="link.homePage"
                            text="Home"
                        />
                    </ListItem>
                    <ListItem textAlign="center">
                        <Text
                            as={ReachLink}
                            to="/results"
                            layerStyle="headerLink"
                            scope="global"
                            tag="link.results"
                            text="Results"
                        />
                    </ListItem>
                    <ListItem textAlign="center">
                        <Text
                            as={ReachLink}
                            to="/help/how-to-play"
                            layerStyle="headerLink"
                            scope="global"
                            tag="link.howToPlay"
                            text="How to play?"
                        />
                    </ListItem>
                    <ListItem textAlign="center">
                        <Text
                            as={ReachLink}
                            to="/winners"
                            layerStyle="headerLink"
                            scope="global"
                            tag="link.winners"
                            text="Winners"
                        />
                    </ListItem>
                    <ListItem textAlign="center">
                        <Text
                            as={ReachLink}
                            to="/play"
                            layerStyle="headerLink"
                            scope="global"
                            tag="link.playLotteries"
                            text="Play Lotteries"
                        />
                    </ListItem>
                    <ListItem textAlign="center">
                        <Text
                            as={ReachLink}
                            to="/procedures"
                            layerStyle="headerLink"
                            scope="global"
                            tag="link.procedures"
                            text="Procedures"
                        />
                    </ListItem>
                    <ListItem textAlign="center">
                        <Text
                            as={ReachLink}
                            to="/scratchcards"
                            layerStyle="headerLink"
                            scope="global"
                            tag="link.scratchcards"
                            text="Scratchcards"
                        />
                    </ListItem>
                    <ListItem textAlign="center">
                        <Text
                            as={ReachLink}
                            to="/news"
                            layerStyle="headerLink"
                            scope="global"
                            tag="link.news"
                            text="News"
                        />
                    </ListItem>
                    <ListItem
                        pl={4}
                        height="40px"
                        display="flex"
                        justifyContent="center"
                        alignContent="center"
                        alignItems="center"
                        borderLeft="0.5px solid white"
                        borderRight="0.5px solid white"
                    >
                        <LanguageDisplay />
                    </ListItem>
                </List>
            </Box>
            <Box
                display={{ base: 'flex', lg: 'none' }}
                justifyContent="flex-end"
                alignItems="center"
                minHeight="40px"
            >
                <UserButton variant="outline" colorScheme="whiteAlpha" color="white" />
                <SideDrawer buttonProps={{ colorScheme: 'whiteAlpha', color: 'white' }} />
            </Box>
        </LayoutContain>
    )
}

export default MainNavigation

import axios from "axios"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

// Load actions
import { requestLogout, setUserToken } from "../redux/User/User.actions"
import { tokenInterceptorClient } from "../helper/axios"
import { useQueryClient } from "react-query"

const useAxios = ({ useAuth = true } = {}) => {
  const token = useSelector((state) => state.user.token)
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  const authedAxios = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        key: process.env.REACT_APP_API_KEY,
      },
    })

    if (useAuth) {
      instance.defaults.headers.common["Authorization"] = `Bearer ${token}`
    }

    // set interceptor
    tokenInterceptorClient(instance, {
      setTokenData: (data) => {
        /**
         * Dispatch updated Token to player Redux Store
         */
        dispatch(setUserToken(data.token))
      },
      tokenExpired: () => {
        /**
         * Token expire or could not be refresh dispatch a logout event
         */
        dispatch(requestLogout())
        queryClient.clear()
      },
      token,
    })

    return instance
  }, [token, dispatch, useAuth, queryClient])

  return authedAxios
}

export default useAxios

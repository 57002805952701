import React, { Suspense } from "react"
import Loading from "../UI/Loading/Loading"

/**
 * @todo - Probably want to accept Skeleton so we can maintain page layout shift score
 */
const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<Loading />}>
      <Component {...props} />
    </Suspense>
  )

export default Loadable

import React from "react"

// Load components
import { Box, Text } from "@chakra-ui/react"

// Load helper
import PageTitle from "../UI/PageTitle/PageTitle"

// Load FE lib
import { useLanguageContext } from "component/Translations/Translations"

// Stubs
const ConfirmationMessage = () => {

    // Language context
    const { getText } = useLanguageContext()

    return (
        <Box>
            <PageTitle title={getText('account.title.accountPending', 'Account Verification Pending')} layerStyle="pageTitleAccount" /> 
            <Text>{getText('account.sentence.accountPendingVerification', 'Your account is currently being verification processed by our customer account team.')}</Text>
        </Box>
    )
}

export default ConfirmationMessage

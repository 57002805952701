import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Load components
import {
    Box,
    FormControl,
    FormLabel,
    Select,
    Button,
    Input,
    FormErrorMessage
} from '@chakra-ui/react'
import InlineError from '../../Errors/InlineError/InlineError'
import { Formik, Form, Field } from 'formik'

// Load helpers
import { exclusionSuspensionSchema } from '../../../helper/schemas'

// Load hooks
import { useExclusionMutation } from '../../../hooks/queries/useExclusionSuspension'
import { useLanguageContext } from 'component/Translations/Translations'

const exclusionTimescales = ['6 Months', '9 Months', '12 Months', '2 Years', '5 Years']
const suspensionTimescales = [
    '24 Hours',
    '1 Week',
    '2 Weeks',
    '3 Weeks',
    '4 Weeks',
    '5 Weeks',
    '6 Weeks'
]

const ExlusionSuspensionModal = ({ data, closeModal, isSuspended = false }) => {
    const [error, setError] = useState(null)
    const { getText } = useLanguageContext()
    const mutateExclusionSuspension = useExclusionMutation()

    // Timescales available for either sus or exc
    const timescales = isSuspended ? suspensionTimescales : exclusionTimescales
    const label = isSuspended ? 'Suspension' : 'Exclusion'
    return (
        <Box>
            <InlineError error={error} mt={0} />
            <Formik
                initialValues={{
                    duration: timescales[0],
                    suspended: isSuspended,
                    excluded: !isSuspended,
                    password: ''
                }}
                validationSchema={exclusionSuspensionSchema}
                onSubmit={(values, actions) => {
                    const method = typeof data.suspended !== 'undefined' ? 'put' : 'post'
                    setError(null)

                    const body = {
                        ...values,
                        customerServiceLocked: false,
                        duration: values.duration
                    }
                    return mutateExclusionSuspension.mutate(
                        { values: body, method },
                        {
                            onSuccess: async () => {
                                return closeModal()
                            },
                            onError: async (error) => {
                                return setError(error)
                            },
                            onSettled: () => {
                                actions.setSubmitting(false)
                            }
                        }
                    )
                }}
            >
                {(props) => {
                    return (
                        <Form>
                            <Field name="duration">
                                {({ field }) => {
                                    return (
                                        <FormControl id="duration" mb={4}>
                                            <FormLabel htmlFor="duration">
                                                {isSuspended
                                                    ? getText(
                                                        'account.tempSuspensionTitle.responsibleGambling',
                                                        'Temporary Suspension'
                                                    )
                                                    : getText(
                                                        'account.selfExclusionTitle.responsibleGambling',
                                                        'Self Exclusion'
                                                    )}
                                            </FormLabel>
                                            <Select {...field} id={'duration'} name="duration">
                                                {timescales.map((timescale) => (
                                                    <option key={timescale} value={timescale}>
                                                        {getText(
                                                            `account.responsibleGamblingOption.${timescale.replace(
                                                                ' ',
                                                                '-'
                                                            )}`,
                                                            timescale
                                                        )}
                                                    </option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )
                                }}
                            </Field>
                            <Field name="password" type="password">
                                {({ field, form }) => {
                                    return (
                                        <FormControl
                                            id="password"
                                            mb={4}
                                            isInvalid={
                                                form.errors.password && form.touched.password
                                            }
                                        >
                                            <FormLabel htmlFor="password">
                                                {getText(
                                                    'account.formLabel.currentPassword',
                                                    'Password'
                                                )}
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                type="password"
                                                name="password"
                                                id="password"
                                                placeholder={getText(
                                                    `account.placeholder.enterPassword`,
                                                    'Enter password'
                                                )}
                                            />
                                            <FormErrorMessage>
                                                {getText(`Error.${form.errors?.password?.code}`, form.errors?.password?.error)}
                                            </FormErrorMessage>
                                        </FormControl>
                                    )
                                }}
                            </Field>
                            <Box layerStyle="modalActionButtonPlacement">
                                <Button
                                    id={label}
                                    size="lg"
                                    borderRadius="0"
                                    layerStyle="modalActionButton"
                                    type="submit"
                                    isLoading={props.isSubmitting}
                                >
                                    {getText('global.button.confirm', 'Confirm')}
                                </Button>
                            </Box>
                        </Form>
                    )
                }}
            </Formik>
        </Box>
    )
}

ExlusionSuspensionModal.propTypes = {
    // Toggle between sus and exlusion
    isSuspended: PropTypes.bool
}

// Redux Store
const mapStateToProps = (state) => ({
    data: state.modal.data
})

export default connect(mapStateToProps)(ExlusionSuspensionModal)

import { call, takeLatest, put, select } from "redux-saga/effects"
import * as selectors from "../selectors.saga"

// Load actions
import { requestCreateBetSuccess, requestCreateBetFail } from "./Checkout.actions"
import { requestLogout } from "../User/User.actions"

// Load constant
import { REQUEST_CREATEBET_START } from "./Checkout.constants"

// Load helper
import { axiosInstance as axios } from "../../helper/axios"

// JWT token helpers
import { validateTokenSaga } from "../Token/Token.saga"

// Async
export function* requestCreateBetAsync({ payload: { bet, gameId } }) {
  try {
    // Validate our JWT token
    yield call(validateTokenSaga)

    // Store the latest refresh token from store
    const token = yield select(selectors.token)

    const client = yield axios.post("lottery/bet", bet, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    // will need a JWT token
    const response = yield client.data

    // Token invalid
    if (response.code === "ERR20038") {
      yield put(requestLogout())
    }

    if (response.error) {
      yield put(requestCreateBetFail(response))
      return
    }

    yield put(
      requestCreateBetSuccess({
        ...response,
        lineTally: response.lineTally || bet.components.length,
      })
    )
  } catch (error) {
    yield put(requestCreateBetFail(error))

    if (error.code === 'ERR20038') {
      yield put(requestLogout())
    }
  }
}

// Export listener
export function* requestCreateBetAsyncStart() {
  yield takeLatest(REQUEST_CREATEBET_START, requestCreateBetAsync)
}

// Export our saga
export function* checkoutSaga() {
  yield call(requestCreateBetAsyncStart)
}

export const generateRandomNumbers = (min = 0, max, count, ignoreList = []) => {
    if (count === 0) {
        return []
    }
    const newNumbers = []
    while (newNumbers.length < count) {
        const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
        if (ignoreList.includes(randomNumber)) {
            continue
        }
        if (!newNumbers.includes(randomNumber)) {
            newNumbers.push(randomNumber)
        }
    }
    return newNumbers
}
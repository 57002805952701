import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from "react-redux"
// Load components
import { Box, FormControl, FormErrorMessage, Button } from '@chakra-ui/react'

// FE Library
import { useLanguageContext } from 'component/Translations/Translations'
import { useLogin } from 'hooks/useLogin'

// Form Libs
import { Formik, Form, Field } from 'formik'
import { loginOTPSchema } from '../../../helper/schemas'
import { setFormWarnings } from '../../../helper/form'
import OTPInput from "react-otp-input"
import Text from '../../UI/Text/Text'
import { userLogin } from '../../../redux/User/User.actions'
import { usePlayerResendOTP } from 'hooks/queries/usePlayer'

const LoginMFAForm = ({ modalData, onUserLogin, closeModal }) => {
    const { onSubmitLoginMFA, hasError, data, isLoading } = useLogin()
    const location = useLocation()
    const { getText } = useLanguageContext()
    const [isDisabled, setDisabled] = useState(false)
    const [sessionToken, setToken] = useState(modalData.token)
    const mutate = usePlayerResendOTP()

    let email = modalData.email

    const initialStatus = {
        otp: {
            error: false,
            code: ''
        },
    }

    async function handleResendOTP() {
        return mutate.mutate({
            email,
            sessionToken
        }, {
            onSuccess: (data) => {
                setDisabled(true)
                setToken(data.token)
            }
        })
    }

    // Sync our store with user data
    useEffect(() => {
        // Check we have token
        if (data.token) {
            // Dispatch userLogin action
            onUserLogin(data)
            closeModal()
        }
    }, [data, onUserLogin, closeModal])

    useEffect(() => {
        const timer = setTimeout(() => {
            setDisabled(false)
        }, 25000)

        return () => clearTimeout(timer)

    }, [isDisabled])

    return (
        <>
            <Box textAlign="center" mb={4}>
                <Text as="h2">

                </Text>
                <Text color="red.500" fontSize="sm" fontWeight="bold" mt={4}>
                    {getText("account.alertOTPCode", "Do not close the screen unless you intend not to continue with the top up", { email })}
                </Text>
            </Box>
            <Formik
                initialValues={{
                    otp: ''
                }}
                initialStatus={initialStatus}
                validationSchema={loginOTPSchema}
                onSubmit={async (values, actions) => {
                    return onSubmitLoginMFA({ ...values, email, sessionToken })
                        .then(() => {
                            if (hasError.warnings) {
                                actions.setStatus(setFormWarnings(initialStatus, hasError.warnings))
                            }
                            actions.setSubmitting(false)
                        })
                        .catch((error) => console.log(error, 'error'))
                }}
            >
                {(props) => (
                    <Form>
                        <Field name="otp">
                            {({ field, form }) => {
                                return (
                                    <FormControl maxWidth="250px" mx="auto" id="otp" isInvalid={form.errors.otp && form.touched.otp}>
                                        <OTPInput
                                            value={field.value}
                                            onChange={(value) => form.setFieldValue('otp', value)}
                                            numInputs={6}
                                            inputType="number"
                                            renderSeparator={<span>-</span>}
                                            inputStyle={{
                                                border: '1px solid',
                                                borderRadius: '5px',
                                                width: "40px",
                                                height: "40px"
                                            }}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                        <FormErrorMessage>{getText('form.otp.error', 'Please enter verification code')}</FormErrorMessage>
                                    </FormControl>
                                )
                            }}
                        </Field>
                        <Box textAlign="center" mt={2}>
                            <Text color="lightgray" mb="1">
                                {getText('form.otp.notReceivedCode', "Didn't receive OTP code?")}
                            </Text>
                            <Button size="sm" colorScheme="gray" color="gray" isDisabled={isDisabled} isLoading={mutate.isLoading} onClick={handleResendOTP}>
                                <Text as="span">
                                    {getText('form.otp.resendCode', 'Resend Code')}
                                </Text>
                            </Button>
                        </Box>
                        {hasError && (
                            <Box textAlign="center" mt={2}>
                                <Text color="#E53E3E" marginBottom={2}>
                                    {hasError.status === 0 ? getText(`Error.rateLimit`, 'Please wait a minute and then try again') : getText(`Error.${hasError.code}`, hasError.error)}
                                </Text>
                            </Box>
                        )}

                        <Box layerStyle="modalActionButtonPlacement">
                            <Button
                                type="submit"
                                borderRadius="0"
                                layerStyle="modalActionButton"
                                color="white"
                                size="lg"
                                colorScheme="buttonSecondary"
                                boxShadow="md"
                                isLoading={props.isSubmitting || isLoading}
                                isDisabled={isLoading}
                                width="100%"
                                state={{ from: location }}
                            >
                                <Text
                                    tag="text.login"
                                    color="inherit"
                                    fontWeight="semibold"
                                    text="Log In"
                                />
                            </Button>
                        </Box>
                    </Form>
                )}
            </Formik>
        </>
    )
}

const mapStateToProps = (state) => ({
    modalData: state.modal.data
})


const mapDispatchToProps = (dispatch) => {
    return {
        onUserLogin: (data) => dispatch(userLogin(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginMFAForm)

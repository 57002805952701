import {
  USER_BALANCE_SET,
  REQUEST_USER_FAIL,
  REQUEST_USER_START,
  REQUEST_USER_SUCCESS,
  USER_LOGIN,
  USER_LOGOUT,
  USER_LANGUAGE_SET,
  USER_TOKEN_SET,
} from './User.constants'
import { REQUEST_TOKEN_PENDING } from '../Token/Token.constants'

// Inject at build to set initial values per partner
const defaultLang = process.env.REACT_APP_LANGUAGE ? process.env.REACT_APP_LANGUAGE : 'es'
const defaultCurrency = process.env.REACT_APP_CURRENCY ? process.env.REACT_APP_CURRENCY : 'DOP'
const defaultIcon = process.env.REACT_APP_DEFAULT_CURRENCY_ICON
  ? process.env.REACT_APP_DEFAULT_CURRENCY_ICON
  : '£'

const initialState = {
  isAuthed: false,
  isRefreshing: false,
  token: null,
  id: null,
  kycStatus: 'not_verified',
  data: {
    currency: {
      symbol: defaultIcon,
    },
  },
  username: '',
  language: defaultLang,
  currency: defaultCurrency,
}

const userLogin = (state, { token, user }) => {
  // Check we have the data or set default
  const username = user.username?.username || ''

  // User Fields to store in our state
  const manualCheck = user.manualCheck

  // Check we have these values or fallback to defaults
  const currency = user.profile?.currency?.shortName ?? defaultCurrency
   // Check kyc status  or set to default
   const kyc = user?.kycStatus ?? {}

  return {
    ...state,
    isAuthed: true,
    token,
    id: user.id,
    username,
    manualCheck,
    currency,
    kycStatus: kyc.status || 'not_verified',
    data: user,
  }
}

/**
 * Reset to initial stage on logout but persist language selected
 */
const userLogout = (state) => ({
  ...initialState,
  language: state.language,
})

const userSuccess = (state, action) => {
  // User object from api
  const data = action.payload

  // User Fields to store in our state
  const manualCheck = data.manualCheck

  // Check we have the data or set default
  const username = data.username?.username || ''

  // Check we have these values or fallback to defaults
  const currency = data.profile?.currency?.shortName ?? defaultCurrency

  // Check kyc status  or set to default
  const kyc = data?.kycStatus ?? {}

  return {
    ...state,
    isAuthed: true,
    isPending: false,
    id: data.id,
    username,
    kycStatus: kyc.status || 'not_verified',
    manualCheck,
    currency,
    data,
  }
}

const userStart = (state) => {
  return {
    ...state,
    hasError: false,
    isPending: true,
  }
}

const userFail = (state, action) => {
  return {
    ...state,
    isPending: false,
    hasError: action.payload,
  }
}

const userBalanceSet = (state, { balance, winBalance }) => {
  // Nothing to change :(
  if (!state.data || !state.isAuthed) {
    return state
  }

  return {
    ...state,
    data: {
      ...state.data,
      userWallet: {
        ...state.data.userWallet,
        amount: balance,
        protectedAmount: winBalance,
      },
    },
  }
}

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_USER_START:
      return userStart(state)
    case REQUEST_USER_SUCCESS:
      return userSuccess(state, action)
    case REQUEST_USER_FAIL:
      return userFail(state, action)
    case USER_LANGUAGE_SET:
      return {
        ...state,
        language: action.payload,
      }
    case REQUEST_TOKEN_PENDING:
      return {
        ...state,
        isRefreshing: true,
      }
    case USER_TOKEN_SET:
      return {
        ...state,
        isRefreshing: false,
        token: action.payload.token,
      }
    case USER_BALANCE_SET:
      return userBalanceSet(state, action.payload)
    case USER_LOGIN:
      return userLogin(state, action.payload)
    case USER_LOGOUT:
      return userLogout(state)
    default:
      return state
  }
}

export default user

import React, { useState } from "react"
import { useSelector } from "react-redux"

// Load components
import { Box, Text, Button, Image } from "@chakra-ui/react"
import { getCdnUrl } from "../../../../helper/cdn"
import InlineError from "../../../Errors/InlineError"
import { Formik, Form } from "formik"
import { useRedeemMutation } from "../../../../hooks/queries/useLotteries"

// Load helper
import { formatPrice } from "../../../../helper/currency"

// Load FE lib
import { useLanguageContext } from "component/Translations/Translations"

const outputPrice = (number) => {
  if (!number) {
    return 0
  }
  return (number / 100)
}

const Redeem = ({ depositThreshold, closeModal }) => {
  const { getText } = useLanguageContext()
  const [error, setError] = useState(null)
  const data = useSelector((state) => state.modal.data)
  const mutateRedeem = useRedeemMutation()
  const { line } = data ?? {}

  const winning = line.winning
  const returns = winning?.amount ?? 0
  const gameSettings = line.gameComponent.lottery?.logo //ticket?.booking?.game?.gameSettings[0]?.fileName
  const headOfficeOnly = returns >= depositThreshold

  return (
    <>
      <InlineError error={error} />
      <Formik
        initialValues={{ ticketLineId: line.id }}
        onSubmit={(values, actions) => {
          return mutateRedeem.mutate(
            { values },
            {
              onSuccess: async () => {
                return closeModal()
              },
              onError: async (error) => {
                return setError(error)
              },
              onSettled: () => {
                actions.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(props) => {
          return (
            <Form>
              <Box display="flex" flexDirection="column" alignItems="center">
                {gameSettings && <Box mb={4}>{<Image maxWidth="160px" alt={line.gameComponent.lottery?.displayName} src={getCdnUrl(gameSettings)} />}</Box>}
                <Text fontSize="md" mb={4} textAlign="center" fontWeight="bold">
                  {getText("account.text.youWon", "Congratulations you won!")} {formatPrice(outputPrice(returns))}
                </Text>
                {headOfficeOnly ? (
                  <Text fontSize="md" textAlign="center">
                    {getText("account.bigWinParagraph.redeemHeadOffice", "Confirm to Redeem your winnings and claim winnings from Leidsa head office.")}
                  </Text>
                ) : (
                  <Text fontSize="md" textAlign="center">
                    {getText("account.lessThan100kParagraph.redeemWithAgent", "Confirm to redeem your ticket and take it to an agent to collect")}
                  </Text>
                )}
                <Box layerStyle="modalActionButtonPlacement">
                  <Button size="lg" borderRadius="0" layerStyle="modalActionButton" type="submit" isLoading={props.isSubmitting}>
                    {getText("Account.RedeemWin.Modal.Title", "Redeem winnings")}
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default Redeem

import React from 'react'
import PropTypes from "prop-types"

// Load components
import { Box, Text, FormLabel, Input } from '@chakra-ui/react'
import Icon from '../Icon/Icon'

const FileImport = ({ label, details, uploadText, onChangeHandler, file, onRemoveHandler }) => {

    return (
      <Box display="flex" flexDirection={{ base: "column", md: "row" }} justifyContent="space-between" alignItems="center" mb={4}>
          <Box alignSelf="center">
              <Text fontWeight="bold" align="center">{label}</Text>
              <Text fontSize="xs">{details}</Text>
          </Box>
            { file ? (
                <Box>
                  <Box  display="flex" mr={4}>
                      <FormLabel htmlFor="fileName" fontSize={{ base: "xs", md: "sm" }} mr={2} mt={{ base: 2, md: 0}}>
                        {file.name}
                      </FormLabel>
                    <Box as="span" flex="1 0 20px" backgroundColor="red.600" mt={{ base: 2, md: 0}} color="white"  width="20px" height="20px" borderRadius="100%" textAlign="center" cursor="pointer" onClick={onRemoveHandler}>
                    <Box top="-1px" position="relative">
                        <Icon icon="times" />
                    </Box>
                  </Box>
                </Box>
              </Box> 
               )
              :
            <Box mt={{ base: 4, md: 0 }}>
                <FormLabel htmlFor="file-uploader" width="100%" m={0} backgroundColor="primary.900" textAlign="center" borderRadius="buttonRadii" color="white" cursor="pointer" fontWeight="bold" minWidth="95px" lineHeight="40px" fontSize="sm">
                    {uploadText}
                </FormLabel>
                  <Input display="none" type="file" accept=".jpg,.jpeg,.png" name="file" id="file-uploader" onChange={onChangeHandler} />
            </Box> 
      }
    </Box>
  )
}

FileImport.propTypes = {
  /**
   * Text display for ID doc
   */
  label: PropTypes.string,
  /**
   * Text display for ID doc paragraph
   */
  details: PropTypes.string,
   /**
   * Object includes uploaded file name
   */
  file: PropTypes.object,
  /**
   * Text for file upload label
   */
  uploadText: PropTypes.string,
  /**
   * Handler for selecting files to upload
   */
  onChangeHandler:  PropTypes.func.isRequired,
  /**
   * Handler for removing selected files
   */
  onRemoveHandler:  PropTypes.func.isRequired,
}

export default FileImport
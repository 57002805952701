import { tableAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system'
import { mode } from '@chakra-ui/theme-tools'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
    table: {
        fontVariantNumeric: 'lining-nums tabular-nums',
        borderCollapse: 'separate',
        width: 'full',
        border: '1px',
        borderColor: 'table.border',
        borderRadius: 'tableBorderRadius',
        overflow: 'hidden',
        borderSpacing: 0,
        boxShadow: 'md'
    },
    th: {
        fontFamily: 'heading',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        letterSpacing: 'wider',
        textAlign: 'start',
        backgroundColor: 'table.headerBackground',
        color: 'table.headerTextColor'
    },
    td: {
        textAlign: 'start',
        fontWeight: '500',
        backgroundColor: 'table.bodyBackground'
    },
    caption: {
        mt: 4,
        fontFamily: 'heading',
        textAlign: 'center',
        fontWeight: 'medium'
    }
})

const numericStyles = defineStyle({
    '&[data-is-numeric=true]': {
        textAlign: 'end'
    }
})

const variantSimple = definePartsStyle((props) => {
    //const { colorScheme: c } = props

    return {
        th: {
            color: 'table.headerTextColor',
            
            borderBottom: '0px',
            borderColor: 'table.border', //mode(`${c}.100`, `${c}.700`)(props),
            ...numericStyles
        },
        td: {
            ...numericStyles,
            borderColor: 'table.border', //mode(`${c}.100`, `${c}.700`)(props),
            borderTop: '1px',
            borderBottom: 'none',
            borderTopColor: 'table.border'
        },
        caption: {
            color: mode('gray.600', 'gray.100')(props)
        },
        tbody: {
            tr: {
                '&:last-of-type': {
                    td: { borderBottomWidth: 0 }
                }
            }
        },
        tfoot: {
            tr: {
                '&:last-of-type': {
                    th: { borderBottomWidth: 0 }
                }
            }
        }
    }
})

// const variantStripe = definePartsStyle((props) => {
//     const { colorScheme: c } = props

//     return {
//         th: {
//             color: mode('gray.600', 'gray.400')(props),
//             borderBottom: '1px',
//             borderColor: mode(`${c}.100`, `${c}.700`)(props),
//             ...numericStyles
//         },
//         td: {
//             borderBottom: '1px',
//             borderColor: mode(`${c}.100`, `${c}.700`)(props),
//             ...numericStyles
//         },
//         caption: {
//             color: mode('gray.600', 'gray.100')(props)
//         },
//         tbody: {
//             tr: {
//                 '&:nth-of-type(odd)': {
//                     'th, td': {
//                         borderBottomWidth: '1px',
//                         borderColor: mode(`${c}.100`, `${c}.700`)(props)
//                     },
//                     td: {
//                         background: mode(`${c}.100`, `${c}.700`)(props)
//                     }
//                 }
//             }
//         },
//         tfoot: {
//             tr: {
//                 '&:last-of-type': {
//                     th: { borderBottomWidth: 0 }
//                 }
//             }
//         }
//     }
// })

const variantColumns = definePartsStyle((props) => {
    return {
        table: {
            borderCollapse: 'collapse',
            border: '1px solid',
            borderColor: 'table.border'
        },
        th: {
            color: 'table.headerTextColor',
            borderBottom: '1px',
            borderColor: 'table.border',
            ...numericStyles
        },
        td: {
            borderColor: 'table.border', //mode(`${c}.100`, `${c}.700`)(props),
            borderTop: '1px',
            borderBottom: 'none',
            borderTopColor: 'table.border',
            ...numericStyles
        },
        caption: {
            color: mode('gray.600', 'gray.100')(props)
        },
        tbody: {
            tr: {
                '&:last-of-type': {
                    td: { borderBottomWidth: 0 }
                }
            }
        },
        tfoot: {
            tr: {
                '&:last-of-type': {
                    th: { borderBottomWidth: 0 }
                }
            }
        }
    }
})

const variants = {
    simple: variantSimple,
    //striped: variantStripe,
    columns: variantColumns,
    unstyled: defineStyle({})
}

const sizes = {
    sm: definePartsStyle({
        th: {
            px: '4',
            py: '1',
            lineHeight: '4',
            fontSize: 'xxs'
        },
        td: {
            px: '4',
            py: '2',
            fontSize: 'xs',
            lineHeight: '4'
        },
        caption: {
            px: '4',
            py: '2',
            fontSize: 'xs'
        }
    }),
    md: definePartsStyle({
        th: {
            px: {base: '3', md:'6'},
            py: '4',
            lineHeight: '4',
            fontSize: 'sm'
        },
        td: {
            px: {base: '3', md:'6'},
            py: '3',
            fontSize: {base: 'sm', md:'md'},
            lineHeight: '5'
        },
        caption: {
            px: '6',
            py: '2',
            fontSize: 'sm'
        }
    }),
    lg: definePartsStyle({
        th: {
            px: '8',
            py: '4',
            lineHeight: '5',
            fontSize: 'sm'
        },
        td: {
            px: '8',
            py: '5',
            lineHeight: '6'
        },
        caption: {
            px: '6',
            py: '2',
            fontSize: 'md'
        }
    })
}

export const tableTheme = defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes,
    defaultProps: {
        variant: 'simple',
        size: 'md'
    }
})

export const BETS_UPDATE_BET = 'BETS_UPDATE_BET'
export const BETS_REMOVE_BET = 'BETS_REMOVE_BET'
export const BETS_SYNC_NUMBERS = 'BETS_SYNC_NUMBERS'
export const BETS_CLEAR_ALL = 'BETS_CLEAR_ALL'

export const BETS_OPEN_MODAL = 'BETS_OPEN_MODAL'
export const BETS_LOAD_SELELECTIONS = 'BETS_LOAD_SELECTIONS'
export const BETS_CHANGE_STAKE = 'BETS_CHANGE_STAKE'
export const BETS_CLOSE_MODAL = 'BETS_CLOSE_MODAL'
export const BETS_UPDATE_MODAL_SELECTION = 'BETS_UPDATE_MODAL_SELECTION'
export const BETS_UPDATE_MODAL_BONUS_SELECTION = 'BETS_UPDATE_MODAL_BONUS_SELECTION'
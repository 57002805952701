import { createClient } from 'contentful'

// Load the cache helper
import cache from '../helper/cache'

// We can pass in multiple environments
export const client = () => {
    return createClient({
        accessToken: 'NvifYAXAvCqAOh__D3WXQxbM3b8tdVbP1aT_XBxu-gg',
        space: 'l0qkpmzamf53',
        environment: 'master'
    })
}

export const parseResponse = (items) => {
    return items
        .map((item) => ({
            name: item.fields.title,
            href: item.fields.link,
            order: item.fields.order,
            children: item.fields.children ? parseResponse(item.fields.children) : []
        }))
        .sort((a, b) => a.order - b.order)
}

export const getContent = async ({
    locale,
    model,
    field = null,
    value = null,
    order = null,
    params = null
}) => {
    // Do we have a field, if so, check the storage
    if (field) {
        const cacheData = cache.get(`content/${locale}/${model}/${field}/${value}`)

        // We read it from the localStorage
        if (cacheData) {
            return cacheData
        }
    }

    const queryData = {
        content_type: model,
        include: 2,
        locale
    }

    // Do we want to filter by anything?
    if (field) {
        queryData[`fields.${field}[in]`] = value
    }

    if (order) {
        queryData.order = order
    }

    /**
     * Have any search params
     */
    if (params) {
        for (const key in params) {
            queryData[key] = params[key]
        }
    }

    // Make the API call
    const response = await client().getEntries(queryData)

    // We got no content back
    if (response.items.length < 1) {
        return null
    }

    if (typeof params?.skip !== 'undefined') {
        return response
    }

    // We have content to load
    if (field) {
        // Set the cache data for 5m expiration
        cache.set(
            `content/${model}/${field}/${value}`,
            response.items[0],
            new Date().getTime() + 60 * 5 * 1000
        )

        // Return our data
        return response.items[0]
    }

    return response.items
}

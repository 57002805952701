import React from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

// Load components
import Header from '../component/Header/Header'
import Footer from '../component/Footer/Footer'
import Modal from '../component/Modal/Modal'
import { LanguageProvider } from 'component/Translations/Translations'
import EventBusListener from '../component/EventBus/EventBus.subscriber'
import Notifcation from '../component/Notification/Notification'
import { Box } from '@chakra-ui/react'

// Load hooks
import { useGamesQuery } from '../hooks/queries/useGames'
import GlobalLoginNotifcation from 'component/Notification/GlobalNotification'

const MainTemplate = () => {
    const { data: games } = useGamesQuery()
    const language = useSelector((state) => state.user.language)
    const isAuthed = useSelector((state) => state.user.isAuthed)

    return (
        <LanguageProvider
            scope="global.,game.,betslip.,registration.,meta.,error.,countries.,form.,Country.,account.,Error."
            language={language}
        >
            <Box as="header" background="headerBackground">
                <Header />
                <Notifcation />
            </Box>
            <Box
                as="main"
                display="flex"
                flexGrow={1}
                flexDirection="column"
                justifyContent="space-between"
                position="relative"
            >
                <Outlet />
            </Box>
            <Box as="footer">
                <Footer games={games?.all} />
            </Box>
            <Modal />
            <EventBusListener />
            {isAuthed && <GlobalLoginNotifcation />}
        </LanguageProvider>
    )
}

export default MainTemplate

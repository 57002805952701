import React from "react"
import { Helmet } from 'react-helmet'

const metaTitle = process.env.REACT_APP_META_TITLE || 'LEIDSA'

const WrappedHelmet = ({ children }) => {

   return (
       <Helmet
           defaultTitle={metaTitle}
           titleTemplate={`%s | ${metaTitle}`}
       >
           {children}
       </Helmet>
   )
}

export default WrappedHelmet
import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useLanguageContext } from 'component/Translations/Translations'

// Load modals
import UpdateContact from './UpdateContact/UpdateContact'
import ExlusionSuspensionModal from '../Modal/ExclusionSuspension/ExclusionSuspension'
import SelectCard from './SelectCard/SelectCard'
import DeleteCard from './DeleteCard/DeleteCard'
import Redeem from './Lottery/Redeem/Redeem'
import Deposit from './Lottery/Deposit/Deposit'
import ViewTicket from './Lottery/ViewTicket/ViewTicket'
import ViewMessage from './ViewMessage'
import UpdateEmail from './UpdateEmail/UpdateEmail'
import UpdatePassword from './UpdatePassword/UpdatePassword'
import ActivateCard from './ActivateCard/ActivateCard'
import MaxLinesHelp from './MaxLinesHelp/MaxLinesHelp'
import LoginOTP from './LoginOTP/LoginOTP'

// Load constants
import modalConstants from '../../redux/modal/Modal.constants'

// Load actions
import { closeModal } from '../../redux/modal/Modal.actions'
import { openNotification } from '../../redux/notification/notification.actions'
import notificationConstants from '../../redux/notification/notification.constants'

// Load components
import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalBody,
    Heading,
    Text
} from '@chakra-ui/react'

export const ModalBase = ({ modal, closeModal, modalConfig }) => {
    const dispatch = useDispatch()
    const { getText } = useLanguageContext()
    // if modal is null dont show anything
    if (!modal) {
        return null
    }

    function handleSuccessNotification({ message }) {
        dispatch(openNotification({ message, status: notificationConstants.NOTIFICATION_SUCCESS }))
    }

    const attributes = {
        closeModal,
        handleSuccessNotification
    }



    let content = null
    let title = null

    switch (modal) {
        case modalConstants.MODAL_LOGIN_OTP:
            content = <LoginOTP {...attributes} />
            title = getText('account.otpVerification', 'OTP Verification')
            break
        case modalConstants.MODAL_VIEW_MESSAGE:
            content = <ViewMessage {...attributes} />
            title = getText('global.text.inbox', 'Inbox')
            break
        case modalConstants.MODAL_UPDATE_EMAIL:
            content = <UpdateEmail {...attributes} />
            title = getText('global.text.updateEmail', 'Update Email')
            break
        case modalConstants.MODAL_UPDATE_CONTACT:
            content = <UpdateContact {...attributes} />
            title = getText('global.text.contact', 'Contact')
            break
        case modalConstants.MODAL_UPDATE_PASSWORD:
            content = <UpdatePassword {...attributes} />
            title = getText('account.detailsPageTitle.updatePassword', 'Update Password')
            break
        case modalConstants.MODAL_SUSPENSION:
            content = <ExlusionSuspensionModal {...attributes} isSuspended />
            title = getText(
                'account.tempSuspensionTitle.responsibleGambling',
                'Temporary Suspension'
            )
            break
        case modalConstants.MODAL_EXCLUSION:
            content = <ExlusionSuspensionModal {...attributes} />
            title = getText('account.selfExclusionTitle.responsibleGambling', 'Self Exclusion')
            break
        case modalConstants.MODAL_SELECT_CARDS:
            content = <SelectCard {...attributes} />
            title = getText('account.addFundsPage.depositByCard', 'Deposit by Card')
            break
        case modalConstants.MODAL_DELETE_CARDS:
            content = <DeleteCard {...attributes} />
            title = getText('global.text.deleteCard', 'Delete Card')
            break
        case modalConstants.MODAL_REDEEM:
            content = <Redeem {...attributes} />
            title = getText('Account.RedeemWin.Modal.Title', 'Redeem Win')
            break
        case modalConstants.MODAL_DEPOSIT:
            content = <Deposit {...attributes} />
            title = getText('Account.DepositWin.Modal.Title', 'Deposit Win')
            break
        case modalConstants.MODAL_VIEW_TICKET:
            content = <ViewTicket {...attributes} />
            title = getText('Account.LotteryTicket.Modal.Title', 'Lottery Ticket')
            break
        case modalConstants.MODAL_ACTIVATE_CARD:
            content = <ActivateCard {...attributes} />
            title = getText('account.title.activateCard', 'Activate Card')
            break
        case modalConstants.MODAL_HELP_MAX_LINES:
            content = <MaxLinesHelp {...attributes} />
            title = getText('global.text.help', 'Help')
            break
        default:
            content = <Text>Modal is empty, not configured!</Text>
            break
    }
    return (
        <Modal
            onClose={closeModal}
            isOpen={modal}
            closeOnEsc
            closeOnOverlayClick
            isCentered
            size="2xl"
            {...modalConfig}

        >
            <ModalOverlay />
            <ModalContent overflow="hidden">
                <ModalHeader>
                    {title && (
                        <Heading fontSize="lg" layerStyle="titleBottomBorder" color="texBody">
                            {title}
                        </Heading>
                    )}
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody pb={20}>{content}</ModalBody>
            </ModalContent>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    modal: state.modal.key,
    modalConfig: state.modal.modalConfig,
    data: state.modal.data,
    error: state.modal.error
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalBase)

import { useState } from "react"
import { connect } from "react-redux"
import * as yup from "yup"

//Load components
import { Box, Button, Text, Checkbox, FormControl, FormErrorMessage } from "@chakra-ui/react"
import Icon from "../../UI/Icon/Icon"
import InlineError from "../../Errors/InlineError/InlineError"
import { Formik, Form, Field } from "formik"

//Load hooks
import { useDeleteCard } from "../../../hooks/queries/useGateway"
import { useLanguageContext } from "component/Translations/Translations"

const deleteSchema = yup.object().shape({
  cardId: yup.number().required(),
  confirmed: yup.boolean().isTrue("Please confirm to remove"),
})

const DeleteCard = ({ data, closeModal }) => {
  const mutation = useDeleteCard()
  const [error, setError] = useState(null)
  const { getText } = useLanguageContext()

  return (
    <>
      <InlineError error={error} />
      <Formik
        initialValues={{
          cardId: data.card.id,
          confirmed: false,
        }}
        validationSchema={deleteSchema}
        onSubmit={(values, actions) => {
          return mutation.mutate(
            { values },
            {
              onSuccess: async () => {
                return closeModal()
              },
              onError: async (error) => {
                setError(error)
              },
              onSettled: () => {
                actions.setSubmitting(false)
              },
            }
          )
        }}
      >
        {(props) => {
          return (
            <Form>
              <Box py={4} display="grid" gridTemplateColumns="repeat(2, 1fr)">
                <Field name="cardId">
                  {() => {
                    return (
                      <Box display="flex" alignItems="center">
                        <Icon icon="creditCard" size="lg" />
                        <Text ml={2}>{`${data.card.cardType} *${data.card.cardNumber || data.card.anonymousCard}`}</Text>
                      </Box>
                    )
                  }}
                </Field>
                <Field name="confirmed" type="checkbox">
                  {({ form, field }) => (
                    <FormControl textAlign="right" isInvalid={form.errors.confirmed && form.touched.confirmed}>
                      <Checkbox {...field} id="confirmed" size="md">
                        {getText("account.formLabel.selectCard", "Select card")}
                      </Checkbox>
                      <FormErrorMessage justifyContent="flex-end" textAlign="right">
                        {getText("account.removeCard.pleaseConfirmRemove", form.errors.confirmed)}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Box layerStyle="modalActionButtonPlacement">
                  <Button type="submit" size="lg" borderRadius="0" layerStyle="modalActionButton" color="white" isLoading={props.isSubmitting || mutation.isLoading} colorScheme="buttonError">
                    {getText("global.button.confirm", "Confirm")}
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

const mapStateToProps = (state) => ({
  data: state.modal.data,
})

export default connect(mapStateToProps)(DeleteCard)

import notificationConstants from './notification.constants'

export const openNotification = ({ message, status, navigate, allowDismiss = true }) => {
  return {
    type: notificationConstants.OPEN_NOTIFICATION,
    payload: { message, status, navigate, allowDismiss },
  }
}

export const closeNotification = () => ({
  type: notificationConstants.CLOSE_NOTIFICATION,
})

import {
    REQUEST_TOKEN_FAILED,
    REQUEST_TOKEN_SUCCESS,
    REQUEST_TOKEN_PENDING,
    CHECK_TOKEN_VALID
} from "./Token.constants.js"

export const requestTokenStart = () => {
    return {
        type: REQUEST_TOKEN_PENDING
    }
}

export const requestTokenSuccess = () => {
    return {
        type: REQUEST_TOKEN_SUCCESS
    }
}

export const requestTokenFailed = (error) => {
    return {
        type: REQUEST_TOKEN_FAILED,
        payload: error
    }
}

export const checkTokenValid = () => {
    return {
        type: CHECK_TOKEN_VALID
    }
}
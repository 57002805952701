import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, NavLink as ReachLink } from 'react-router-dom'

// Load components
import { Button, Box, IconButton } from '@chakra-ui/react'
import Text from '../../Text/Text'
import Icon from 'component/UI/Icon/Icon'

// Load helper
import { formatPrice } from 'helper/currency'

const AuthedButton = ({ user, accountLoginLink, location, ...props }) => {
    const amount = (user?.userWallet?.amount ?? 0) / 100
    const playAmount = (user?.userWallet?.protectedAmount ?? 0) / 100
    return (
        <Button
            as={ReachLink}
            mx={2}
            to={{ pathname: accountLoginLink, state: location.pathname }}
            borderRadius="md"
            size="xs"
            fontSize="sm"
            {...props}
            leftIcon={<Icon icon="user" />}
        >
            <Text
                scope="global"
                color="inherit"
                tag="headerTitle.welcomeName"
                text={'Welcome, {firstName}!'}
                data={{ firstName: user.firstName }}
                fontSize="xs"
                marginRight={{ base: 0, md: 2 }}
            />
            <Box display={{ base: 'none', md: 'flex' }} justifyContent="center">
                <Box display="flex" alignItems="center" fontSize="xs" position="relative" mr={4}>
                    <Icon icon="coins" />
                    <Text
                        scope="global"
                        color="inherit"
                        fontSize="xs"
                        tag="player.playBalance"
                        text="Play Balance: {formattedAmount}"
                        data={{
                            currency: user.currency.symbol,
                            amount: amount.toFixed(2),
                            formattedAmount: formatPrice(
                                amount,
                                user.currency.shortName
                            )
                        }}
                        marginLeft={1}
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <Icon icon="trophy" />
                    <Text
                        scope="global"
                        fontSize="xs"
                        color="inherit"
                        tag="player.winsBalance"
                        text="Wins Balance: {formattedAmount}"
                        data={{
                            currency: user.currency.symbol,
                            amount: playAmount.toFixed(2),
                            formattedAmount: formatPrice(
                                playAmount,
                                user.currency.shortName
                            )
                        }}
                        ml={1}
                    />
                </Box>
            </Box>
        </Button>
    )
}

/**
 * Display user information when logged in
 */
const UserButton = ({ ...props }) => {
    const isAuthed = useSelector((state) => state.user.isAuthed)
    const user = useSelector((state) => state.user.data)
    const location = useLocation()

    // Navigation anchor
    const accountLoginLink = isAuthed ? '/account' : '/login'

    return isAuthed ? (
        <AuthedButton
            user={user}
            accountLoginLink={accountLoginLink}
            location={location}
            {...props}
        />
    ) : (
        <IconButton
            as={ReachLink}
            mx={2}
            to={{ pathname: accountLoginLink }}
            state={location.pathname}
            borderRadius="md"
            size="xs"
            fontSize="sm"
            {...props}
            icon={<Icon icon="user" />}
        />
    )
}

export default UserButton

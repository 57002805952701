import { parsePhoneNumber } from "react-phone-number-input"

/**
 * Handle map warnings to form field
 * @param {Array} warnings
 * @returns {Object}
 */
export function setFormWarnings(status = {}, warnings) {
  const fields = {
    ...status,
  }

  warnings.forEach((warning) => {
    if (warning.field) {
      fields[warning.field] = {
        code: warning.code,
        error: warning.error,
      }
    }
  })
  return fields
}

export function validateForm(form) {
  let newForm = {}

  // Loop through our fields and get the value
  for (let field in form) {
    // Exclude these fields
    if (["token", "day", "year", "month", "confirmPassword", "phoneNumber", "phoneNumberCode"].includes(field)) {
      continue
    }

    newForm[field] = form[field]
  }

  // Add DOB
  newForm["dob"] = `${form.year}-${form.month}-${form.day}`

  // Utility to extract meta phone number info
  const phoneNumber = parsePhoneNumber(form.phoneNumber)

  // Add tel number
  newForm["phoneNumber"] = phoneNumber.nationalNumber
  newForm["phoneNumberCode"] = `+${phoneNumber.countryCallingCode}`

  return newForm
}

// Build year options
export function buildYear() {
  const min = new Date().getFullYear()
  const max = min - 120
  const options = []

  for (let i = min; i >= max; i--) {
    options.push(i)
  }

  return options
}

/**
 * Build days base on month and year
 * @param {String} year
 * @param {String} month
 */
function days() {
  const options = {}

  // Clousre to cache days when changing month/year
  return function (year, month) {
    const days = new Date(year, month, 0).getDate()

    if (days in options) {
      return options[days]
    }

    options[days] = []

    for (let i = days; i >= 1; i--) {
      options[days].push(("0" + i).slice(-2))
    }

    return options[days]
  }
}

// Export our closure to get days
export const buildDays = days()
export const buildMonths = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]

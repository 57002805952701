import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers([
    'card',
    'main',
    'header',
    'footer'
])

const baseStyle = definePartsStyle({
    card: {
        border: '1px solid',
        borderColor: 'playCard.cardBorder',
        overflow: 'hidden',
        background: 'playCard.cardBackground',
        borderRadius: 'playCardRadii',
        height: '100%',
        textAlign: 'center',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08)'
    },
    main: {
        minH: 'max',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDir: 'column',
        position: 'relative',
        fontSize: '16px'
    },
    header: {
        height: '100px',
        padding: 4,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    footer: {
        // padding: 4,
        textAlign: 'center',
        fontWeight: 'bold',
        height: '100%',
        minH: '36px'
    }
})

const variantPlay = definePartsStyle(() => {
    return {
        main: {
            _after: {
                backgroundColor: 'playCard.headerBackgroundColor',
                width: '100%',
                position: 'absolute',
                top: '-150px',
                left: 0,
                height: '115px',
                zIndex: 0,
                content: "''"
            }
        },
        footer: {
            borderTop: '1px solid',
            borderColor: 'playCard.footerBorder',
            backgroundColor: 'playCard.footerBackgroundColor'
        }
    }
})

const variantResult = definePartsStyle(() => {
    return {
        main: {
            pb: 6,
            _after: {
                backgroundColor: 'playCard.headerBackgroundColor',
                width: '100%',
                position: 'absolute',
                top: '-130px',
                left: 0,
                height: '120px',
                zIndex: 0,
                content: "''"
            }
        },
        footer: {
            borderTop: '1px solid',
            borderColor: 'playCard.footerBorder',
            backgroundColor: 'playCard.footerBackgroundColor'
        }
    }
})

const variants = {
    play: variantPlay,
    result: variantResult
}

export const playCardTheme = defineMultiStyleConfig({
    baseStyle,
    variants,
    sizes: {},
    defaultProps: {
        variant: 'play'
    }
})

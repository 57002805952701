export function getPreviousDraws(data) {
    let recentDraws = []

    // looks at the data for each lottery
    data.forEach((lottery) => {
        let lotteryDraws = []

        // Looks at each previous draw for that lottery
        lottery.previousDraws &&
            lottery.previousDraws.forEach((draw) => {
                // Makes sure we only use a previous draw if it contains the result data
                // Adds the data we use in our results tile to a new array

                if (draw.numbers) {
                    lotteryDraws.push({
                        numbers: [],
                        extraNumbers: {},
                        name: lottery.displayName,
                        logo: lottery.logo,
                        hasLeadZero: lottery.hasLeadZero,
                        ...draw
                    })
                }

            })

        // If this lottery had some draws
        if (lotteryDraws.length > 0) {

            // Pull out the most recent draw
            recentDraws.push(lotteryDraws[0])
        }
    })
    return recentDraws
}

/**
 * 315: Loto Pool
 * 316: Super Kino TV
 * 317: Pega3mas
 * 318: Leidsa QuinPale
 * 
 * Home page to show featured lotteries
 */
export const ALLOWED_LOTTERIES = new Set([315, 316, 317, 318])
export const ALLOWED_GAMES = new Set([463, 465, 466, 467])
const hasLeadZero = new Set([467])
export function getPreviousDrawsByLottery(data) {
    let recentDraws = []

    // looks at the data for each lottery
    data.forEach((lottery) => {
        let lotteryDraws = []

        if (ALLOWED_GAMES.has(lottery.id)) {
            // Looks at each previous draw for that lottery
            lottery.previousDraws &&
                lottery.previousDraws.forEach((draw) => {
                    // Makes sure we only use a previous draw if it contains the result data
                    // Adds the data we use in our results tile to a new array

                    if (draw.numbers) {
                        lotteryDraws.push({
                            numbers: [],
                            extraNumbers: {},
                            name: lottery.displayName,
                            logo: lottery?.settings?.gameIcon ?? '',
                            url: lottery.url,
                            hasLeadZero: hasLeadZero.has(lottery.id),
                            ...draw
                        })
                    }

                })

            // If this lottery had some draws
            if (lotteryDraws.length > 0) {
                recentDraws.push(lotteryDraws[0])
            }
        }
    })
    return recentDraws
}

export function getBonusComponents({ activeGameComponent, game, lotteryById }) {
    let bonusComponents = []

    // Get the component key e.g. complex_2 => {2}
    const activeComponentKey = parseInt(activeGameComponent.ruleType.key.replace('complex_', ''))

    // Find what components to output
    game.gameComponents.forEach((component) => {
        // Get the complex_X key
        const componentKey = parseInt(component.ruleType.key.replace('complex_', ''))

        // Include this component if it is less than or equal to the active one
        if (componentKey > 0 && componentKey <= activeComponentKey) {
            bonusComponents.push(lotteryById[component.lotteryId])
        }

        // Everything else should not be included
        return false
    })

    return bonusComponents
}

export const getStatus = ({ getText, status, processed, resulted, winningTotal }) => {
    if (!status) {
        return getText('account.result.rejected', 'Rejected')
    } else if (!processed) {
        return getText('account.result.pending', 'Pending')
    } else if (winningTotal > 0) {
        return getText('account.result.win', 'Win')
    } else if (processed && resulted) {
        return getText('account.result.lost', 'Lost')
    } else if (processed && !resulted) {
        return getText('account.result.pending', 'Pending')
    }
    return '-'
}

export const hasNextDraw = (game) => {
    let isAvailable = true
    if (typeof game.nextDraw === 'undefined' || typeof game.nextDraw?.gmtDrawTime === 'undefined') {
        isAvailable = false
    }

    return isAvailable
}

export const isMatchedNumber = ({ drawnNumbers, playerNumber, numberIndex, gameComponent = {} }) => {
    if (
        gameComponent.ruleType.group === 'number_normal' ||
        gameComponent.ruleType.group === 'complex'
    ) {
        if (!drawnNumbers) {
            return false
        }

        if (
            typeof drawnNumbers[`p${numberIndex}`] !== 'undefined' &&
            drawnNumbers[`p${numberIndex}`] === playerNumber
        ) {
            return true
        }
    }

    return false
}

// Sort ballasc for super kino
export function sortOrderBalls({ lotteryId, balls }) {
    if (lotteryId === 316 && balls?.length) {
        balls = balls.sort((a, b) => a - b)
    }
    return balls
} 

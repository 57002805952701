const playCard = {
  boxShadow: "lg",
}

const searchFiltersContainer = {
  background: "contentCard.backgroundColor",
  p: 6,
  borderRadius: "md",
  boxShadow: "lg",
}

const layerStyles = {
  playCard,
  searchFiltersContainer,
}

export default layerStyles

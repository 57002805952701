import { useQuery } from 'react-query'
import useAxios from '../useAxios'
import { useDispatch } from 'react-redux'
import {
    setNextDrawId,
    setNextDrawTimestamp,
    setPreviousDraw
} from '../../redux/Rapid/Rapid.actions'
import { getPreviousDrawsByLottery } from '../../helper/lotteries'

/**
 * Get the game image from settings
 * @param {Aray} data setting array
 * @returns
 */
function getSettings(data) {
    const oldSettings = data.gameSettings || [],
        filters = data.gameFilters || [],
        newSettings = {}

    if (Array.isArray(oldSettings)) {
        for (const setting of oldSettings) {
            if (
                typeof setting.fileName !== 'undefined' &&
                setting.fileName.length > 0 &&
                setting.fileName.length !== '0'
            ) {
                newSettings[setting.key] = setting.fileName
                continue
            }
            newSettings[setting.key] = setting.value
        }

        // Persist the object version
        data.settings = newSettings
    }

    // Look to add to most popular menu
    if (Array.isArray(filters)) {
        for (const filter of filters) {
            if (filter.name === 'Most popular') {
                newSettings.mostPopular = true
            }
        }
    }

    return data
}

/**
 * Define Query keys for our Game
 */
const gamesKeys = {
    all: ['games'],
    game: (slug) => [...gamesKeys.all, 'game', slug],
    gameLotteries: (gameId) => [...gamesKeys.all, 'lottery', gameId],
    gameDraws: (id) => [...gamesKeys.all, 'draws', id],
    gameOdds: (slug) => [...gamesKeys.all, 'odds', slug]
}

/**
 * Transform game settings returned on data
 */
function transformGamesSettings({ data }) {
    const games = []

    data.forEach((game) => {
        /**
         * Check the game is active
         */
        if (game.status) {
            /**
             * transform the settings
             *
             * Add game to our list
             */
            games.push(getSettings(game))
        }
    })
    const previous = getPreviousDrawsByLottery(data)
    return { all: games, previous }
}

/**
 * @todo - Rapids is still WIP
 * onSuccess - set the next draw ettc for rapids
 * @param {*} data
 * @returns
 */
function transformGameSettings(data) {
    // deal with rapids data once in reducer
    let rapidsData = null
    if (data.category === 'Rapids') {
        const groupIdList = []
        const groupObject = {}
        let orderedComponents = data?.gameComponents.sort(
            (a, b) => a.gameGroup.order - b.gameGroup.order
        )
        orderedComponents?.forEach((component) => {
            if (!groupIdList.includes(component.gameGroupId)) {
                groupIdList.push(component.gameGroupId)
            }
            if (!groupObject[component.gameGroupId]) {
                groupObject[component.gameGroupId] = {}
            }
            if (!groupObject[component.gameGroupId][component.ruleType.group]) {
                groupObject[component.gameGroupId][component.ruleType.group] = []
            }
            groupObject[component.gameGroupId][component.ruleType.group].push(component)
        })
        rapidsData = { groupIdList, groupObject }
    }

    const formattedData = getSettings(data)
    formattedData.rapidsData = rapidsData
    return formattedData
}

function transformGameLotteries({ data, currencies }) {
    let lotteryById = {}

    data.forEach((lottery) => (lotteryById[lottery.id] = lottery))

    return {
        lotteryById,
        currencies
    }
}

const fetchGames = async (axios) => {
    const response = await axios.get('lottery/games')
    return response.data
}

const fetchGame = async ({ slug, axios }) => {
    const response = await axios.get(`lottery/game/${slug}`)
    return response.data
}

/**
 * Extra data needed for a game
 * @param {Number} gameId
 * @param {String} type lotteries || odds || draws
 */
const fetchGameByType = async ({ gameId, type, axios }) => {
    const response = await axios.get(`lottery/game/${gameId}/${type}`)
    return response.data
}

/**
 * Fetch all our games
 * @todo - Revcive the websocket event SET_NEXT_DRAW_X so it can update the draw on play lobby
 * 
 * // Copy what we currently have
    const newGames = [...state.data]
    
    for (const index in newGames) {
        
        const game = newGames[index]
        
        // We only want to look for rapid games
        if (game.category !== "Rapids") {
            continue
        }
        
        // No next draw loaded yet?
        if (!game.nextDraw) {
            continue
        }
        
        // Set the updated draw timestamp
        if (game.nextDraw.lotteryId === action.payload.lotteryId) {
            game.nextDraw.gmtDrawTime = (new Date(action.payload.nextDrawTimestamp * 1000)).toISOString()
        }
        
        // Update what changed
        newGames[index] = game
 */
const useGamesQuery = () => {
    const axios = useAxios({ useAuth: false })
    return useQuery(gamesKeys.all, () => fetchGames(axios), {
        select: transformGamesSettings
    })
}

/**
 * Fetch game by slug
 * @param {String} slug
 */
const useGameQuery = (slug) => {
    const axios = useAxios({ useAuth: false })
    const dispatch = useDispatch()

    return useQuery(gamesKeys.game(slug), () => fetchGame({ axios, slug }), {
        enabled: !!slug,
        select: transformGameSettings,
        onSuccess: (response) => {
            // Set the next draw data
            if (response.nextDraw) {
                dispatch(setNextDrawId(response.nextDraw.id, response.nextDraw.lotteryId))
                dispatch(
                    setNextDrawTimestamp(
                        new Date(response.nextDraw.gmtDrawTime).valueOf() / 1000,
                        response.nextDraw.lotteryId
                    )
                )
            }

            // Did we get previous draw data?
            if (response.previousDraws) {
                dispatch(setPreviousDraw(response.previousDraws[0]))
            }
        }
    })
}

/**
 * Fetch all lotteries associated with a game id
 * @param {Number} id
 */
const useGameLotteriesQuery = (id) => {
    const axios = useAxios({ useAuth: false })
    return useQuery(
        gamesKeys.gameLotteries(id),
        () => fetchGameByType({ axios, gameId: id, type: 'lotteries' }),
        {
            enabled: !!id,
            select: transformGameLotteries
        }
    )
}

/**
 * Fetch all game draws associated with a game id
 * @param {Number} id
 */
const useGameDrawsQuery = (id) => {
    const axios = useAxios({ useAuth: false })
    return useQuery(
        gamesKeys.gameDraws(id),
        () => fetchGameByType({ gameId: id, type: 'draws', axios }),
        { enabled: !!id }
    )
}

/**
 * Fetch odds for Rapids game category by slug
 * @param {String} slug
 */
const useGameOddsQuery = (slug) => {
    const axios = useAxios({ useAuth: false })
    return useQuery(
        gamesKeys.gameOdds(slug),
        () => fetchGameByType({ axios, gameId: slug, type: 'odds' }),
        { enabled: !!slug }
    )
}

/**
 * Export our hooks
 */
export {
    useGamesQuery,
    useGameQuery,
    useGameLotteriesQuery,
    useGameDrawsQuery,
    useGameOddsQuery,
    gamesKeys
}
